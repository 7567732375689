import * as yup from 'yup';
import moment from 'moment';

export const answersValidationsSchema = yup.object({
  product: yup.string().required(),
  source: yup.string().required(),
  contractor_is_owner: yup.number().required(),
  contractor_is_driver: yup.number().required(),
  name: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio. Inserisci il nome!')
        .matches(
          /^[a-zA-Z ]+$/,
          'Non sono ammessi numeri o caratteri speciali!',
        )
      : schema)),
  surname: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio. Inserisci il cognome!')
        .matches(
          /^[a-zA-Z ]+$/,
          'Non sono ammessi numeri o caratteri speciali!',
        )
      : schema)),
  gender: yup.string().required('Il campo è obbligatorio. Seleziona genere!'),
  fiscal_code: yup.string().nullable(),
  phone: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci numero di telefono!')
    .matches(/^[3]{1}[0-9]{8,9}$/, 'Il numero non e corretto'),
  email: yup
    .string()
    .matches(/^[a-zA-Z0-9.@]+$/, 'Sono ammessi solo lettere (a-z), numeri (0-9), punti (.) e (@).')
    .email('Deve essere un email valido! Rimouvi spazi vuoti!')
    .required('Il campo è obbligatorio. Inserisci Email!'),
  date_of_birth: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio. Seleziona data di nascita!')
        .test('valid-date', 'La data di nascita non è valida', (value) => {
          if (!value) return true;
          const date = moment(value, 'YYYY-MM-DD', true);
          const year = date.year();
          const currentYear = new Date().getFullYear();

          if (year > currentYear - 18 || year < 1925) {
            return false;
          }
          return date.isValid();
        })
      : schema)),
  country_of_birth_code: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio. Seleziona il stato estero!')
      : schema)),
  province_of_birth_code: yup
    .string()
    .nullable()
    .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
      ? !born_abroad
        ? schema.required(
          'Il campo è obbligatorio. Inserisci luogo di nascita del propietario!',
        )
        : schema
      : schema)),
  commune_of_birth_code: yup
    .string()
    .nullable()
    .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
      ? !born_abroad
        ? schema.required(
          'Il campo è obbligatorio. Inserisci luogo di nascita del propietario!',
        )
        : schema
      : schema)),
  born_abroad: yup
    .boolean()
    .required('Il campo è obbligatorio. Seleziona se nato al estero!'),
  residence_province_code: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required(
        'Il campo è obbligatorio. Seleziona provincia di residenza!',
      )
      : schema)),
  residence_commune_code: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required(
        'Il campo è obbligatorio. Seleziona provincia di residenza!',
      )
      : schema)),
  postal_code: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci codice postale!')
    .matches(/^[0-9]{5}$/, 'Il codice postale non e coretto'),
  address: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci indirizzo!'),
  house_number: yup.string().required('Il campo è obbligatorio.'),
  civil_status_id: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio. Seleziona il stato civile!')
      : schema)),
  education_level_id: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required(
        'Il campo è obbligatorio. Seleziona Titolo di studio!',
      )
      : schema)),
  profession_id: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G' ? schema.required('Il campo è obbligatorio') : schema)),
  driving_license_year: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G' ? schema.required('Il campo è obbligatorio') : schema)),
  vehicle_plate: yup
    .string()
    .required('Il campo è obbligatorio.')
    .matches(/^[A-Z]{2}[0-9]{3}[A-Z]{2} *$/, 'La targa non e coretta'),
  vehicle_brand_code: yup.string().required('Il campo è obbligatorio.'),
  vehicle_model_code: yup.string().required('Il campo è obbligatorio.'),
  vehicle_version_code: yup.string().required('Il campo è obbligatorio.'),
  vehicle_am_code: yup.string().required(),
  imm_vehicle_year: yup.string().required('Il campo è obbligatorio.'),
  imm_vehicle_month: yup.string().required('Il campo è obbligatorio.'),
  inherit_merit_class: yup
    .string()
    .nullable()
    .when('insurance_type', ([insurance_type], schema) => (insurance_type === 'N'
      ? schema.required('Il campo è obbligatorio.')
      : schema)),
  merit_class: yup.string().required('Il campo è obbligatorio.'),
  vehicle_purchased_month: yup
    .string()
    .required('Il campo è obbligatorio.')
    .when(
      ['vehicle_purchased_year', 'imm_vehicle_month', 'imm_vehicle_year'],
      ([vehicle_purchased_year, imm_vehicle_month, imm_vehicle_year], schema) => (vehicle_purchased_year !== ''
        ? schema.test(
          'purchase-month-custom-validation',
          'Data di aquisto non puo essere prima della data di immatricolazione',
          (value) => `${imm_vehicle_year}-${imm_vehicle_month}`
            <= `${vehicle_purchased_year}-${value}`,
        )
        : schema),
    ),
  vehicle_purchased_year: yup.string().required('Il campo è obbligatorio.'),
  theft_protection_code: yup.string().required('Il campo è obbligatorio.'),
  tow_hook: yup.string().required('Il campo è obbligatorio.'),
  children: yup.number().nullable(),
  vehicle_parking: yup.string().required(),
  other_power_supply: yup.string().required(),
  vehicle_usage: yup.string().required(),
  predicted_km: yup.number().required(),
  vehicles_owned: yup.number().required('Il campo è obbligatorio.'),
  policy_effective_date: yup
    .date()
    .typeError('Il campo è obbligatorio. Seleziona data decorrenza!')
    .required('Il campo è obbligatorio. Seleziona data decorrenza!')
    .min(
      new Date(moment().subtract(1, 'day').format('YYYY-MM-DD')),
      'La data è nel passato',
    )
    .max(
      new Date(moment().add(30, 'day').format('YYYY-MM-DD')),
      'La data è nel futuro',
    ),
  other_drivers: yup.number().required('Il campo è obbligatorio.'),
  youngest_age_driver: yup
    .number()
    .typeError('Deve essere un numero!')
    .nullable()
    .max(100, 'Il campo non può essere maggiore di 100')
    .min(18, 'Il campo non può essere minore di 18')
    .when(['gender', 'other_drivers'], ([gender, other_drivers], schema) => (gender !== 'G' && other_drivers === '1'
      ? schema.required('Il campo è obbligatorio.')
      : schema)),
  youngest_age_family_member: yup
    .number()
    .typeError('Deve essere un numero!')
    .max(100, 'Il campo non può essere maggiore di 100')
    .min(18, 'Il campo non può essere minore di 18')
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G' ? schema.required('Il campo è obbligatorio') : schema)),
  modified_vehicle: yup.number(),
  valid_driving_license: yup.number(),
  first_insuranced_year: yup.string().required('Il campo è obbligatorio.'),
  guide_type: yup.string().required(),
  insurance_type: yup.string().required('Il campo è obbligatorio.'),
  mofified_vehicle: yup.number().required('Il campo è obbligatorio.'),

  business_name: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup
        .string()
        .required("Il campo è obbligatorio. Inserisci il nome dell'azienda!"),
    }),
  vat_number: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup
        .string()
        .max(11, 'La partiva iva non puo essere piu di 11 numeri!')
        .matches(/^[0-9]*$/, 'Solo numeri sono ammessi!')
        .required('Il campo è obbligatorio. Inserisci la tua partita iva!'),
    }),
  company_type: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup.string().required('Il campo è obbligatorio'),
    }),

  privacies: yup.array(yup.number()),

  existing_customer: yup.boolean(),
  active_customer_id: yup.number().nullable(),
  weight: yup.number(),
  power: yup.number(),
  vehicle_activity: yup.string().required('Il campo è obbligatorio.'),
  is_heavy: yup.string().required('Il campo è obbligatorio.'),
  massa_com: yup.number().required('Il campo è obbligatorio.').typeError('Massa Complesiva deve essere un numero da 0-440'),
  provenienza: yup.string().required('Il campo è obbligatorio.'),
  adr: yup.string().required('Il campo è obbligatorio.'),
  uso_autocarro: yup.string().required('Il campo è obbligatorio.'),
  violations_data: yup
    .object()
    .nullable()
    .when(['violations'], ([violations], schema) => (violations === 1
      ? schema.test('Is Invalid', 'I sinistri non sono validi', (value) => {
        const toValidate = [
          ...value.fullTotal.map((v) => v.value),
          ...value.partialTotal.map((v) => v.value),
        ];

        return !toValidate.includes('NaN');
      })
      : schema)),
  payment_frequency: yup
    .number()
    .required('Frequenza Pagamento e obligatorio!'),
});

export const answersFieldsBySteps = [
  [
    'vehicle_plate',
    'imm_vehicle_month',
    'imm_vehicle_year',
    'vehicle_brand_code',
    'vehicle_model_code',
    'vehicle_version_code',
    'other_power_supply',
    'vehicle_parking',
    'theft_protection_code',
    'vehicle_usage',
    'predicted_km',
    'vehicle_purchased_month',
    'vehicle_purchased_year',
    'vehicles_owned',
    'tow_hook',
    'weight',
    'power',
    'is_heavy',
    'vehicle_activity',
  ],
  [
    'gender',
    'business_name',
    'vat_number',
    'company_type',
    'date_of_birth',
    'commune_of_birth_code',
    'province_of_birth_code',
    'born_abroad',
    'residence_commune_code',
    'residence_province_code',
    'postal_code',
    'house_number',
    'children',
    'civil_status_id',
    'education_level_id',
    'profession_id',
    'address',
    'contractor_is_driver',
    'contractor_is_owner',
    'driving_license_year',
  ],
  [
    'policy_effective_date',
    'other_drivers',
    'youngest_age_driver',
    'youngest_age_family_member',
    'violations',
    'violations_data',
    'merit_class',
    'mofified_vehicle',
    'valid_driving_license',
    'guide_type',
    'inherit_merit_class',
    'inherited_vehicle_plate',
    'insurance_type',
    'first_insuranced_year',
  ],
  ['name', 'surname', 'email', 'phone'],
];
