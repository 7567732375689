import React, { useState, useEffect } from 'react';
import { questionarioCommercial } from '../questions';
import { FormInputRadio } from '../../../../common/formInputs/FormInputRadio';
import { flagResponse } from '../../../../common/constants';
import { Accordion } from '../../../../common/components/Accordion';

function LogisticaTrasporti({
  register, errorMessages, errors, questions,
}) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (
      Object.keys(errors).some((key) => key.includes('security_and_logistics'))
    ) {
      setHasError(true);
    }
  }, [errors]);
  return (
    <Accordion
      title={questionarioCommercial[5].title}
      defaultOpen={false}
      hasError={hasError}
      setHasError={setHasError}
    >
      <ul className="list-unstyled">
        {questions.find((el) => el.title === 'Logistica e Trasporti')
          !== undefined
          && questions
            .find((el) => el.title === 'Logistica e Trasporti')
            .questions?.map((question, index) => (
              <li className="mb-2" key={index}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="mb-0 text-center">{question.question}</p>
                  <FormInputRadio
                    registration={register(question.input_name)}
                    options={flagResponse}
                    error={errorMessages(question.input_name)}
                  />
                </div>
              </li>
            ))}
        {/* {questionarioCommercial[5].questions.map((question, index) => (
          <li className='mb-2' key={index}>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <p className='mb-0 text-center'>{question.question}</p>
              <FormInputRadio
                registration={register(`${question.input_name}`)}
                options={flagResponse}
                error={errorMessages(question.input_name)}
              />
            </div>
          </li>
        ))} */}
      </ul>
    </Accordion>
  );
}

export default LogisticaTrasporti;
