import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLoading from '../common/components/elements/PageLoading';
import {
  addQuoteToCart,
  removeQuoteFromCart,
  resetTutelaLegaleQuotes,
  tutelaLegaleQuotesGetQuotes,
} from '../features/insuranceRequests/insuranceRequestsActions';
import { confirmAlert, generalAlertError } from '../common/utils/alerts';
import { TutelaLegaleQuotesStyles } from './RcTutelaLegaleQuotes.styles';
import ErrorComponent from '../common/components/ErrorComponent';
import {
  RespCivileModal,
  RiskButton,
  RiskButtonRemove,
  RiskCard,
  RiskContainer,
} from '../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import { GuaranteesDefaultStateStyles } from '../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { quotationTypes } from '../common/constants';
import TutelaLegaleInfoCard from '../common/components/redesignComponents/Cards/TutelaLegaleInfoCard';
import ProcessingQuoteSelectedTutelaLegale from '../common/components/redesignComponents/ResultPageComponents/ProcessingQuoteSelectedTutelaLegale';
import QuotationCard from '../common/reusable/QuotationCard';

export default function RcTutelaLegaleQuotes() {
  const navigate = useNavigate();
  const { requestToken } = useParams();

  const state = useSelector(
    (store) => store.insuranceRequests.tutela_legale_quotes,
  );
  const {
    loading,
    userData,
    customer,
    quotations,
    addingQuote,
    processingQuote,
    riskQuotations,
    error,
  } = state;

  const refresh = () => tutelaLegaleQuotesGetQuotes(requestToken);

  const fetchQuotes = () => {
    tutelaLegaleQuotesGetQuotes(requestToken);
  };

  const modifyData = () => {
    navigate('/quote/tutela-legale', {
      state: { requestToken },
    });
  };

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteToCart(quoteId, fetchQuotes, modifyData));
  };

  useEffect(() => {
    tutelaLegaleQuotesGetQuotes(requestToken);

    return () => {
      resetTutelaLegaleQuotes();
    };
  }, []);

  useEffect(() => {
    if (userData && userData.is_expired === true) {
      navigate('/quote/tutela-legale', {
        state: { requestToken },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    }
  }, [userData]);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeQuoteFromCart(quoteId, fetchQuotes));
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null && processingQuote.amount !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (riskQuotations && riskQuotations[type.id] !== undefined) {
        for (let i = 0; i < riskQuotations[type.id].length; i++) {
          const item = riskQuotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    const total = quoteInProccessAmount + risksTotalAmount;

    return total.toFixed(2);
  };

  const handleRequestPurchase = () => {
    if (processingQuote === null) {
      generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
      return;
    }

    navigate(`/quotes/tutela-legale/purchase/${requestToken}`);
  };

  return (
    <>
      <TutelaLegaleQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-xxl-0 result-profession-first-step">
              <TutelaLegaleInfoCard
                modificaDati={modifyData}
                customer={customer}
                insuranceRequest={userData}
              />
            </div>
            <div className="col-12 col-xxl-8 result-profession-second-step">
              <GuaranteesDefaultStateStyles>
                <div className="container mt-4">
                  <ul className="list-unstyled">
                    {processingQuote !== null && (
                      <li>
                        <div className="d-flex justify-content-between align-items-center">
                          <h5>
                            {processingQuote.company.name}
                            {' '}
                            <span className="text-success">
                              (

                              {
                                quotationTypes.find(
                                  (item) => item.id === processingQuote.quote_type,
                                ).name
                              }
                              )
                            </span>
                          </h5>
                          <h5 className="fw-bold">
                            {parseFloat(
                              processingQuote.amount
                                ? processingQuote.amount
                                : 0,
                            ).toFixed(2)}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
                {processingQuote === null ? (
                  <h5 className="fw-semibold pt-2 ps-lg-2">
                    Seleziona Le Quotazioni Che Vuoi Aquistare
                  </h5>
                ) : (
                  <div className="container mt-4">
                    <ul className="list-unstyled">
                      {quotationTypes
                        .filter((t) => t.id !== 'res_civile')
                        .map((type, index) => (
                          <React.Fragment key={index}>
                            {riskQuotations && riskQuotations[type.id]
                              ? riskQuotations[type.id].map((quote, i) => (
                                <li key={i}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h5>
                                      {quote.company !== null
                                        ? quote.company.name
                                        : quote.preventivas_company
                                          .company_name}
                                      {' '}
                                      <span className="text-success">
                                        (
                                        {
                                            quotationTypes.find(
                                              (item) => item.id === quote.quote_type,
                                            ).name
                                          }
                                        )
                                      </span>
                                    </h5>
                                    <h5 className="fw-bold">
                                      {parseFloat(quote.amount).toFixed(2)}
                                      {' '}
                                      €
                                    </h5>
                                  </div>
                                </li>
                              ))
                              : ''}
                          </React.Fragment>
                        ))}
                      <hr />
                      <li>
                        <div className="d-flex justify-content-end align-items-center">
                          <h5 className="me-5 fw-bold">Totale:</h5>
                          <h5 className="fw-bold">
                            {' '}
                            {getTotal()}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                    </ul>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        className="btn btn-success btn-lg rounded-4 px-5 fw-bold"
                        onClick={() => handleRequestPurchase()}
                      >
                        Procedi
                      </button>
                    </div>
                  </div>
                )}
              </GuaranteesDefaultStateStyles>
            </div>
          </div>
          <RiskContainer className="mb-3 pb-3 result-profession-third-step">
            {quotationTypes.map((type, index) => (
              <React.Fragment key={index}>
                {quotations && quotations[type.id] && (
                  <RiskCard
                    className={`mt-3 purchase-quote-fourth-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3 ${
                      userData.processing_quotation_id === null
                      && type.id !== 'res_civile'
                        ? 'bg-secondary bg-opacity-25'
                        : ''
                    } ${
                      userData.processing_quotation_id !== null
                      && type.id === 'res_civile'
                        ? 'bg-success bg-opacity-25'
                        : ''
                    } ${
                      riskQuotations && riskQuotations[type.id] !== undefined
                        ? 'bg-success bg-opacity-25'
                        : ''
                    }`}
                  >
                    <div className="p-3 me-auto">
                      <h5>{type.name}</h5>
                    </div>
                    {processingQuote !== null && (
                      <ProcessingQuoteSelectedTutelaLegale
                        processingQuote={processingQuote}
                        requestInfo={userData}
                        refresh={refresh}
                      />
                    )}

                    <div className="d-flex justify-content-end ms-auto gap-2">
                      {type.id === 'res_civile' && processingQuote !== null && (
                        <RiskButtonRemove
                          className="btn btn-danger"
                          onClick={() => removeItemFromCart(processingQuote.id)}
                        >
                          Rimuovi
                        </RiskButtonRemove>
                      )}
                      {type.id === 'res_civile' && (
                        <RiskButton
                          className="btn btn-success"
                          data-bs-toggle="modal"
                          data-bs-target={`#${type.id}`}
                        >
                          Aggiungi
                        </RiskButton>
                      )}
                    </div>
                  </RiskCard>
                )}
              </React.Fragment>
            ))}
          </RiskContainer>
        </div>
        {quotationTypes.map((type, index) => (
          <RespCivileModal
            className="modal fade"
            id={type.id}
            tabIndex="-1"
            aria-labelledby={type.name}
            aria-hidden="true"
            key={index}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id={type.name}>
                    <h5>
                      {type.id == 'res_civile' ? 'Tutela Legale' : type.name}
                    </h5>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="buttonClose"
                  />
                </div>
                <div className="modal-body">
                  {addingQuote && (
                    <div className="d-flex align-items-center justify-content-between flex-column">
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">
                            Aggiungendo...
                          </span>
                        </div>
                      </div>
                      <div className="ms-2">Aggiungendo...</div>
                    </div>
                  )}
                  {addingQuote === false && quotations[type.id] ? (
                    [...quotations[type.id]] // Create a copy of the array
                      .sort((a, b) => a.amount - b.amount) // Sort the copy
                      .map((quote, index) => (
                        <div className="mt-3" key={index}>
                          <QuotationCard
                            quote={quote}
                            requestInfo={userData}
                            type={type}
                            selectQuotation={selectQuotation}
                          />
                        </div>
                      ))
                  ) : addingQuote === false ? (
                    <p>
                      Nessuna Quotazione
                      {type.name}
                      {' '}
                      Trovata.
                    </p>
                  ) : null}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger px-4"
                    data-bs-dismiss="modal"
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            </div>
          </RespCivileModal>
        ))}
      </TutelaLegaleQuotesStyles>
    </>
  );
}
