import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import { generalAlertError } from '../../common/utils/alerts';
import { PurchasedQuotationsStyles } from '../PurchasedQuotations/PurchasedQuotations.styles';
import { listCancellations, resetCancellations } from '../../features/afterSale/afterSaleActions';
import Pagination from '../../common/components/elements/Pagination';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackCancellations } from '../../common/utils/joyrideCallbacks';
// import { cancellationsSteps } from '../../common/utils/joyrideSteps';

export function CancellationsPage() {
  const navigate = useNavigate();
  const state = useSelector((store) => store.afterSale.cancellations_page);

  const {
    loading, cancellations, total_pages, page_number, error,
  } = state;

  const [search, setSearch] = useState({
    search: '',
    date_from: '',
    date_to: '',
  });
  // const [steps, setSteps] = useState([]);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('cancellations') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('cancellations')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('cancellations');
  //   setState({ run: true, tourActive: true });
  // };

  // useEffect(() => {
  //   cancellationsSteps(cancellations, setSteps);
  // }, [cancellations]);

  useEffect(() => {
    (async () => {
      listCancellations(1, search);
    })();

    return () => {
      resetCancellations();
    };
  }, []);

  const handleChange = ({ target: { value, name } }) => {
    setSearch({ ...search, [name]: value });
  };

  const searchHandler = async (e) => {
    e.preventDefault();

    if (search.date_from !== '' && search.date_to === '') {
      generalAlertError('Devi selezionare anche data al');
      return;
    }

    if (search.date_from === '' && search.date_to !== '') {
      generalAlertError('Devi selezionare anche data da');
      return;
    }

    const trimmedSearch = {
      ...search,
      search: search.search.trim(),
    };

    listCancellations(1, trimmedSearch);
  };

  return error === null
    ? (
      <PurchasedQuotationsStyles>
        {/* <Joyride
          steps={steps}
          continuous
          showBeacon={showBeacon}
          locale={{
            back: 'Indietro',
            close: 'Chiudi',
            last: 'Fine',
            next: 'Prossima',
            open: 'Apri il modale',
            restart: 'Riavvia il tour ',
            skip: 'Salta',
          }}
          styles={{
            options: joyrideOptions,
            buttonNext: {
              backgroundColor: '#00a651',
              outline: 'none',
            },
            buttonBack: {
              color: '#00a651',
              outline: 'none',
            },
            beaconInner: {
              backgroundColor: '#00a651',
            },
            beaconOuter: {
              borderColor: '#00a651',
              backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
            },
            buttonSkip: {
              outline: 'none',
            },
            buttonClose: {
              outline: 'none',
            },
          }}
          run={run}
          callback={(data) => handleCallbackCancellations(data, steps, setState)}
          showSkipButton
          showProgress
        /> */}
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <h5>Cessazioni</h5>
          <div className="d-flex">
            <div className="btn btn-success text-white px-5 m-2" onClick={() => navigate('/after-sale/select')}>Vai a Post Vendita</div>
            {/* {localStorage.getItem('cancellations') === 'done' && (
              <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
                <button className="btn btn-success my-2 mx-0 px-3">Tutorial</button>
              </div>
            )} */}
          </div>
        </div>
        <div className="d-flex flex-md-row flex-column-reverse">
          <div className="col-xl-8 col-md-7 col-lg-8">
            <div className="mt-3 cancellations-one">
              {loading
                ? (
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <div className="spinner-border" role="status" />
                    <span>Attendere prego...</span>
                  </div>
                )
                : (
                  <ul className="list-group">
                    {cancellations.length > 0
                      ? cancellations.map((cancellation) => (
                        <li key={cancellation.id} className="cancellations-two list-group-item d-flex flex-xl-row flex-column justify-content-between align-items-start align-items-xl-center">
                          <div className="me-auto mb-0">
                            <div className="fw-bold">
                              Cliente:
                              {' '}
                              {cancellation.quotation.insurance_request.customer.gender !== 'G' ? `${cancellation.quotation.insurance_request.customer.name} ${cancellation.quotation.insurance_request.customer.surname}` : cancellation.quotation.insurance_request.customer.business_name}
                            </div>
                            {cancellation.quotation.insurance_request.product
                              && (
                                <>
                                  Prodotto:
                                  {' '}
                                  {cancellation.quotation.insurance_request.product.name}
                                  <br />
                                </>
                              )}
                            {cancellation.quotation.company
                              && (
                                <>
                                  Compagnia:
                                  {' '}
                                  {cancellation.quotation.company.name}
                                  <br />
                                </>
                              )}
                            {cancellation.quotation.insurance_request.vehicle
                              && (
                                <>
                                  Targa:
                                  {' '}
                                  {cancellation.quotation.insurance_request.vehicle.vehicle_plate}
                                  <br />
                                </>
                              )}

                            <small>
                              Data:
                              {' '}
                              {new Date(cancellation.date).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                            </small>
                            <br />

                            <small>
                              <strong>
                                Creato:
                                {new Date(cancellation.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                              </strong>
                            </small>
                          </div>

                          <div className="me-4 my-2">
                            {cancellation.status === 1
                              && <div className="badge bg-danger">Aperto</div>}

                            {cancellation.status === 0
                              && <div className="badge bg-success">Chiuso</div>}
                          </div>

                          <Link to={`/cancellations/${cancellation.id}`}>
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Vai a cessazione
                            </span>
                          </Link>
                        </li>
                      ))
                      : (
                        <li className="list-group-item d-flex justify-content-between align-items-start cancellations-no-result">
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">
                              Nessuno cessazione trovato!
                            </div>
                          </div>
                        </li>
                      )}
                  </ul>
                )}
              <div className="mt-2">
                <Pagination total_pages={total_pages} current_page={page_number} onClick={listCancellations} search={search} />
              </div>
            </div>

          </div>
        </div>
        <div className="col-lg-3 col-md-5 col-xl-3 p-3 quotation-filters cancellations-three">
          <form onSubmit={searchHandler} className="d-flex align-items-end justify-content-center flex-column">
            <input type="text" name="search" className="search-input form-control mb-2" value={search.search} onChange={handleChange} placeholder="Ricerca cliente..." />
            <div className="w-100">
              <label htmlFor="date_from">Data creazione da</label>
              <input type="date" name="date_from" onChange={handleChange} value={search.date_from} className="date-input form-control mb-2" placeholder="Data creazione da" />
            </div>
            <div className="w-100">
              <label htmlFor="date_to">Data creazione al</label>
              <input type="date" name="date_to" className="date-input form-control mb-0" onChange={handleChange} value={search.date_to} min={search.date_from} placeholder="Data creazione al" />
            </div>
            <button type="submit" className="search-btn mt-2">
              Cerca
            </button>
          </form>
        </div>
      </PurchasedQuotationsStyles>
    ) : <ErrorComponent error={error} />;
}
