import { FormInputAddress } from './FormInputAddress';
import { FormInputText } from './FormInputText';
import FormReactSelectResidenceInput from './FormReactSelectResidenceInput';

export function FormInputAddressCompleteManual({
  municipalities,
  formData,
  isValid,
  errors,
  register,
  updateFormData,
}) {
  return (
    <>
      <FormInputAddress
        valueAddress={formData.address}
        valueHouse={formData.house_number}
        label="Indirizzo"
        onAddressChange={(value) => updateFormData({ address: value })}
        onHouseNumberChange={(value) => updateFormData({ house_number: value })}
        addressError={errors.address?.message}
        houseNumberError={errors.house_number?.message}
        validAddress={isValid('address')}
        validHouseNumber={isValid('house_number')}
      />
      <FormReactSelectResidenceInput
        label="Residenza"
        options={municipalities}
        values={{
          residence_commune_code: formData.residence_commune_code,
          residence_province_code: formData.residence_province_code,
          postal_code: formData.postal_code,
        }}
        onChange={(values) => updateFormData({
          residence_commune_code: values.residence_commune_code,
          residence_province_code: values.residence_province_code,
          postal_code: values.postal_code,
        })}
        valid={isValid('residence_commune_code')}
      >
        <FormInputText
          label="Codice Postale"
          registration={register('postal_code')}
          error={errors.postal_code?.message}
          valid={isValid('postal_code')}
        />
      </FormReactSelectResidenceInput>
    </>
  );
}
