import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Layout } from '../components/Layout/Layout';
import { RcQuotesStyles } from './BackOfficeVehicleQuotes.styles';
import AncillariAutoInfoCard from '../../../common/components/redesignComponents/Cards/AncillariAutoInfoCard';
import { GuaranteesDefaultStateStyles } from '../components/common/Modal/GuaranteesDefaultStateTravel.styles';
import { quotationTypes } from '../../../common/constants';
import { confirmAlert } from '../../../common/utils/alerts';
import {
  addQuoteToCartAncillariAutoBackOffice,
  loadAutoQuotes, removeBackOfficeQuoteFromCart, resetAutoQuotes, updateAutoQuotesData,
} from '../../../features/backofficeResults/backofficeResultsActions';
import PageLoading from '../../../common/components/elements/PageLoading';
import { RiskContainer } from '../../AncillariAutoProduct/styles/AncillariAutoResultPage.styles';
import AncillariAutoModalButtons from '../../AncillariAutoProduct/components/AncillariAutoModalButtons';
import AncillariAutoModal from '../../AncillariAutoProduct/components/AncillariAutoModal';
import { EstimateContainer } from '../../../common/components/redesignComponents/common/Redesign.styles';

function BackOfficeAncillariAutoQuotes() {
  const { requestToken } = useParams();

  const state = useSelector((store) => store.backofficeResults.auto_quotes);
  const {
    loading,
    requestInfo,
    processingQuote,
    quotations,
    addingQuote,
    riskQuotes,
  } = state;

  useEffect(() => {
    loadAutoQuotes(requestToken);

    return () => {
      resetAutoQuotes();
    };
  }, []);

  useEffect(() => {
    if (requestInfo !== null && requestInfo.quotations) {
      updateAutoQuotesData(requestInfo);
    }
  }, [requestInfo]);

  //   const modifyData = () => {
  //     navigate(`/backoffice/quote/${requestToken}/ancillari-auto`);
  //   };

  if (loading) {
    return <PageLoading />;
  }

  const fetchQuotes = () => {
    loadAutoQuotes(requestToken);
  };

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteToCartAncillariAutoBackOffice(quoteId, fetchQuotes));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeBackOfficeQuoteFromCart(quoteId, fetchQuotes));
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null && processingQuote.amount !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (riskQuotes && riskQuotes[type.id] !== undefined) {
        for (let i = 0; i < riskQuotes[type.id].length; i++) {
          const item = riskQuotes[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    return (quoteInProccessAmount + risksTotalAmount).toFixed(2);
  };
  return (
    <Layout>
      <RcQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-xxl-0 result-home-first-step">
              <AncillariAutoInfoCard
                customer={requestInfo.customer}
                insuranceRequest={requestInfo}
                vehicle={requestInfo.vehicle}
              />
            </div>
            <div className="col-12 col-xxl-8 result-home-second-step">
              <GuaranteesDefaultStateStyles>
                {processingQuote === null ? (
                  <h5 className="fw-semibold pt-2 ps-lg-2">
                    Seleziona Le Quotazioni Che Vuoi Aquistare
                  </h5>
                ) : (
                  <div className="container mt-4">
                    <ul className="list-unstyled">
                      {processingQuote !== null && (
                      <li>
                        <div className="d-flex justify-content-between align-items-center">
                          <h5>
                            {processingQuote.company.name}
                            {' '}
                            <span className="text-success">
                              (
                              {processingQuote.product_name}
                              )
                            </span>
                          </h5>
                          <h5 className="fw-bold">
                            {parseFloat(processingQuote.amount ? processingQuote.amount : 0).toFixed(2)}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                      )}
                      {quotationTypes
                        .filter((t) => t.id !== 'res_civile')
                        .map((type, index) => (
                          <React.Fragment key={index}>
                            {riskQuotes && riskQuotes[type.id]
                              ? riskQuotes[type.id].map((quote, i) => (
                                <li key={i}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h5>
                                      {quote.company !== null
                                        ? quote.company.name
                                        : quote.preventivas_company
                                          .company_name}
                                      {' '}
                                      <span className="text-success">
                                        (
                                        {
                                          quotationTypes.find(
                                            (item) => item.id === quote.quote_type,
                                          ).name
                                        }
                                        )
                                      </span>
                                    </h5>
                                    <h5 className="fw-bold">
                                      {parseFloat(quote.amount).toFixed(2)}
                                      {' '}
                                      €
                                    </h5>
                                  </div>
                                </li>
                              ))
                              : ''}
                          </React.Fragment>
                        ))}
                      <hr />
                      <li>
                        <div className="d-flex justify-content-end align-items-center">
                          <h5 className="me-5 fw-bold">Totale:</h5>
                          <h5 className="fw-bold">
                            {' '}
                            {getTotal()}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                    </ul>

                  </div>
                )}
              </GuaranteesDefaultStateStyles>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <EstimateContainer>
                <div className="d-flex gap-2 justify-content-end ">
                  <div>
                    {/* {localStorage.getItem('quoteNewResult') === 'done' && (
                    <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                      <button className="btn btn-success px-2 py-1">Tutorial</button>
                    </div>
                  )} */}
                  </div>
                </div>
              </EstimateContainer>
            </div>
          </div>
          <RiskContainer className="mb-3 pb-3 result-home-third-step">
            {quotationTypes.map((type, index) => (
              <React.Fragment key={index}>
                {quotations && quotations[type.id] && (
                <AncillariAutoModalButtons
                  insuranceRequest={requestInfo}
                  processingQuote={processingQuote}
                  type={type}
                  removeItemFromCart={removeItemFromCart}
                  riskQuotations={riskQuotes}
                />
                )}
              </React.Fragment>
            ))}
          </RiskContainer>
        </div>
        {quotationTypes.map((type, index) => (
          <AncillariAutoModal
            insuranceRequest={requestInfo}
            type={type}
            key={index}
            addingQuote={addingQuote}
            quotations={quotations}
            selectQuotation={selectQuotation}
          />
        ))}
      </RcQuotesStyles>
    </Layout>
  );
}

export default BackOfficeAncillariAutoQuotes;
