import React, { useEffect, useState } from 'react';
// import Joyride from 'react-joyride';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AnagraficaBtn, ListActiveCustomersStyles, SearchBtn } from './ListActiveCustomers.styles';
import PageLoading from '../../common/components/elements/PageLoading';
import Pagination from '../../common/components/elements/Pagination';
import { getBrokerActiveCustomersNetwork, resetActiveCustomers } from '../../features/activeCustomer/activeCustomerActions';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackClientSezE } from '../../common/utils/joyrideCallbacks';
// import { clientListSezESteps } from '../../common/utils/joyrideSteps';

function ClientsListOfSezE() {
  const {
    network_active_customers: {
      loading, activeCustomers, total_pages, page_number, error,
    },
  } = useSelector((store) => store.activeCustomers);
  const [search, setSearch] = useState({
    search: '',
    search_by: 'active_customer',
  });

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('clientiRete') === 'done') {
  //     setState({ run: false });
  //   } else if (loading === false) {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 3000);
  //   }
  // }, [localStorage.getItem('clientiRete'), loading]);
  // const [steps, setSteps] = useState([]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('clientiRete');
  //   setState({ run: true, tourActive: true });
  // };

  // useEffect(() => {
  //   clientListSezESteps(activeCustomers, setSteps);
  // }, [activeCustomers]);

  const searchClients = (e) => {
    const trimmedSearch = {
      ...search,
      search: search.search.trim(),
    };
    e.preventDefault();
    getBrokerActiveCustomersNetwork(1, trimmedSearch.search, trimmedSearch.search_by);
  };

  const handleSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getBrokerActiveCustomersNetwork();

    return () => {
      resetActiveCustomers();
    };
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return error === null ? (
    <ListActiveCustomersStyles>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackClientSezE(data, steps, setState)}
        showSkipButton
        showProgress
      /> */}
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="mb-0 h3">Clienti Della Rete</h5>
        <div className="d-flex">
          <div>
            <Link to="/clients" className="btn btn-success fw-light m-1">Clienti Diretti</Link>
            <div className="btn btn-success m-1">Clienti Della Rete</div>
          </div>
          {/* {localStorage.getItem('clientiRete') === 'done' && (
            <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()} className="">
              <button className="btn btn-success fw-light m-1 ">Tutorial</button>
            </div>
          )} */}
        </div>
      </div>
      <hr />
      <form
        className="w-100 d-flex align-items-center justify-content-start flex-column flex-md-row clienti-rete-one"
      >
        <input
          type="text"
          name="search"
          value={search.search}
          onChange={handleSearch}
          className="search-input form-control mb-2 mb-md-0 me-0 me-md-2"
          placeholder="cerca con nome, cognome o codice fiscale..."
        />
        <select name="search_by" value={search.search_by} id="" className="form-select mb-2 mb-md-0 me-0 me-md-2 custom-select-search" onChange={handleSearch}>
          <option value=""> - Tipo di cliente- </option>
          <option value="active_customer">Attivo</option>
          <option value="customer">Non Attivo</option>
        </select>
        <SearchBtn onClick={searchClients}>
          Cerca
        </SearchBtn>
        <div className="w-100 d-none d-md-block" />
      </form>
      <div className="mt-5 table-responsive clienti-rete-two">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Nome / Ragione Sociale</th>
              <th scope="col">Cognome</th>
              <th scope="col">Codice Fiscale</th>
              <th scope="col">Numero Polizze Emesse</th>
              <th scope="col">Intermediario</th>
              <th scope="col">Tipo Cliente</th>
              <th scope="col">Data Di Nascita</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {activeCustomers.length !== 0
              ? activeCustomers.map((customer, index) => (
                <tr key={index}>
                  <td>{customer.gender !== 'G' ? customer.name : customer.business_name}</td>
                  <td>{customer.surname}</td>
                  <td>{customer.fiscal_code}</td>
                  <td>{customer.quotations_count}</td>
                  <td>{customer.agent ?? ''}</td>
                  <td>{customer.type === 'active_customer' ? 'Attivo' : 'Non Attivo'}</td>
                  <td>
                    <AnagraficaBtn className="clienti-rete-three">
                      {customer.type === 'active_customer' ? (
                        <Link to={`/clients/${customer.id}`}>
                          Anagrafica
                        </Link>
                      ) : <Link to={`/clients-not-active/${customer.id}`}>Anagrafica</Link>}
                    </AnagraficaBtn>
                  </td>
                </tr>
              ))
              : (
                <tr>
                  <td colSpan="5">Nessun cliente attivo!</td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <Pagination onClick={getBrokerActiveCustomersNetwork} total_pages={total_pages} current_page={page_number} search={search.search} search_by={search.search_by} />
    </ListActiveCustomersStyles>
  ) : <ErrorComponent error={error} />;
}

export default ClientsListOfSezE;
