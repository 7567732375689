import React from 'react';
import { FormInputText } from '../../../common/formInputs/FormInputText';
import { FormInputSelect } from '../../../common/formInputs/FormInputSelect';
import { creditRatingCommonValue, flagResponse } from '../../../common/constants';
import { FormInputDate } from '../../../common/formInputs/FormInputDate';
import { FormInputRadio } from '../../../common/formInputs/FormInputRadio';
import { FormInputPrice } from '../../../common/formInputs/FormInputPrice';

function CommercialCompany({
  register, isValid, errors, formData, setValue,
}) {
  return (
    <>
      <h4 className="text-center mt-3">Azienda Commerciale</h4>
      <div className="row mt-4">
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Nome dell'Azienda"
            label="Nome dell'Azienda"
            registration={register('business_name')}
            error={errors.business_name?.message}
            valid={isValid('business_name')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Numero Aziendale"
            label="Numero Aziendale"
            registration={register('company_number')}
            error={errors.company_number?.message}
            valid={isValid('company_number')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Numero di Iscrizione della Società"
            label="Numero di Iscrizione della Società"
            registration={register('company_registration_number')}
            error={errors.company_registration_number?.message}
            valid={isValid('company_registration_number')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Codice Attività"
            label="Codice Attività"
            registration={register('activity_code')}
            error={errors.activity_code?.message}
            valid={isValid('activity_code')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Descrizione dell'Attività"
            label="Descrizione dell'Attività"
            registration={register('activity_description')}
            error={errors.activity_description?.message}
            valid={isValid('activity_description')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Settore di Attività Industriale"
            label="Settore di Attività Industriale"
            registration={register('activity_industry_sector')}
            error={errors.activity_industry_sector?.message}
            valid={isValid('activity_industry_sector')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Stato della Società"
            label="Stato della Società"
            registration={register('company_status')}
            error={errors.company_status?.message}
            valid={isValid('company_status')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputPrice
            placeholder="Valore del Fatturato"
            label="Valore del Fatturato"
            value={formData.turnover_value}
            name="turnover_value"
            onChange={(value) => setValue('turnover_value', value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            })}
            error={errors.turnover_value?.message}
            valid={isValid('turnover_value')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputSelect
            label="Valore Comune del Rating di Credito"
            placeholder="-Seleziona-"
            options={creditRatingCommonValue}
            registration={register('credit_rating_common_value')}
            error={errors.credit_rating_common_value?.message}
            valid={isValid('credit_rating_common_value')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Descrizione Comune del Rating di Credito"
            label="Descrizione Comune del Rating di Credito"
            registration={register('credit_rating_common_desc')}
            error={errors.credit_rating_common_desc?.message}
            valid={isValid('credit_rating_common_desc')}
          />
        </div>
        <div className="col-12 col-md-6">

          <FormInputPrice
            placeholder="Valore Limite del Rating di Credito"
            label="Valore Limite del Rating di Credito"
            value={formData.credit_rating_limit_value}
            name="credit_rating_limit_value"
            onChange={(value) => setValue('credit_rating_limit_value', value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            })}
            error={errors.credit_rating_limit_value?.message}
            valid={isValid('credit_rating_limit_value')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Provincia"
            label="Provincia"
            registration={register('alter_summary_province')}
            error={errors.alter_summary_province?.message}
            valid={isValid('alter_summary_province')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Indirizzo"
            label="Indirizzo"
            registration={register('alter_summary_address')}
            error={errors.alter_summary_address?.message}
            valid={isValid('alter_summary_address')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputDate
            minDate={new Date()}
            label="Data di Costituzione"
            registration={register('alter_summary_incorporation_date')}
            error={errors.alter_summary_incorporation_date?.message}
            valid={isValid('alter_summary_incorporation_date')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Numero di Dipendenti"
            label="Numero di Dipendenti"
            registration={register('alter_summary_employees_nr')}
            error={errors.alter_summary_employees_nr?.message}
            valid={isValid('alter_summary_employees_nr')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputPrice
            placeholder="Capitale Sociale"
            label="Capitale Sociale"
            value={formData.alter_summary_share_capital.toString()}
            name="alter_summary_share_capital"
            onChange={(value) => setValue('alter_summary_share_capital', value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            })}
            error={errors.alter_summary_share_capital?.message}
            valid={isValid('alter_summary_share_capital')}
          />
        </div>

        <div className="col-12 col-md-6">
          <div className="d-flex align-align-items-center justify-content-center gap-5">
            <FormInputRadio
              label="Extra Ha Insolvenza Aziendale"
              options={flagResponse}
              name="summary_extra_has_company_insolvency"
              registration={register('summary_extra_has_company_insolvency')}
              error={errors.summary_extra_has_company_insolvency?.message}
            />
            <FormInputRadio
              label="Extra Ha Insolvenza"
              options={flagResponse}
              name="summary_extra_has_insolvency"
              registration={register('summary_extra_has_insolvency')}
              error={errors.summary_extra_has_insolvency?.message}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex align-align-items-center justify-content-center gap-5">
            <FormInputRadio
              label="Ha Protesti"
              options={flagResponse}
              name="summary_extra_has_protesti"
              registration={register('summary_extra_has_protesti')}
              error={errors.summary_extra_has_protesti?.message}
            />
            <FormInputRadio
              label="Ha Protesti Gravi"
              options={flagResponse}
              name="summary_extra_has_severe_protesti"
              registration={register('summary_extra_has_severe_protesti')}
              error={errors.summary_extra_has_severe_protesti?.message}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex align-align-items-center justify-content-center gap-5">
            <FormInputRadio
              label="Ha Pregiudiziali"
              options={flagResponse}
              name="summary_extra_has_prejudicials"
              registration={register('summary_extra_has_prejudicials')}
              error={errors.summary_extra_has_prejudicials?.message}
            />
            <FormInputRadio
              label="Ha Pregiudiziali Gravi"
              options={flagResponse}
              name="summary_extra_has_severe_prejudicials"
              registration={register('summary_extra_has_severe_prejudicials')}
              error={errors.summary_extra_has_severe_prejudicials?.message}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-center align-align-items-center">
          <FormInputRadio
            label="Ha Eventi CIGS"
            options={flagResponse}
            name="summary_extra_has_cigs_events"
            registration={register('summary_extra_has_cigs_events')}
            error={errors.summary_extra_has_cigs_events?.message}
          />
        </div>
      </div>
    </>
  );
}

export default CommercialCompany;
