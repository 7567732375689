import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { modifyRequestDataBackoffice, greeniaPrivacyPolicy } from '../../services/axios-client/axeCommons';
import InputSelectTable from '../../common/components/inputs/InputTable';
import LoadingGif from '../../assets/images/loading.gif';
import InputPrivacy from '../../common/components/inputs/InputPrivacy';
import ProgressBar from '../../common/components/ProgressBar';
import {
  validateItalianPostCode,
} from '../../common/validators';
import {
  genders,
  flagResponse,
  months,
  years,
  km_during_one_year,
  vehiclesAmountInFamily,
  meritClass,
  guideType,
  lastSixYears,
  insuranceTypes,
  inheritMeritClass,
  provenienza,
  adr,
  usoAutocarro,
} from '../../common/constants';
import convertToItalianDate from '../../common/helpers/convertToItalianDate';
import PageLoading from '../../common/components/elements/PageLoading';
import getMaxAllowedBirthday from '../../common/helpers/getMaxAllowedBirthday';
import getAllowedPolicyEffectDate from '../../common/helpers/getAllowedPolicyEffectDate';
import generateFiscalCode from '../../common/helpers/generateFiscalCode';
import getAllowedLicenseYears from '../../common/helpers/getAllowedLicenseYears';
import getAllowedPurchaseYears from '../../common/helpers/getAllowedPurchaseYears';
import InputViolations, {
  calculateEmptyViolationsData,
} from '../../common/components/inputs/InputViolations';
import OffcanvasForm from '../../common/components/elements/OffcanvasForm';
import BersaniInfo from '../../common/components/elements/BersaniInfo';
import { generalAlertError } from '../../common/utils/alerts';
import {
  autocarroChangeIndex, autocarroGetInitialData, autocarroGetVehicleModels, autocarroGetVehicleVersions, autocarroSubmitAnswers, autocarroUpdateFormData, resetAutocarroForm,
} from '../../features/formsBackoffice/formsBackofficeActions';

import { answersFieldsBySteps, answersValidationsSchema } from './BackOfficeRcVan.validations';
import PaymentTypePeriod from '../../common/components/inputs/PaymentTypePeriod';
import QuoteGenerateLoading from '../../common/components/elements/QuoteGenerateLoading';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { Label } from '../../common/formInputs/styles/common';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { FormInputNumber } from '../../common/formInputs/FormInputNumber';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import { Layout } from './components/Layout/Layout';
import FormReactSelectBrand from '../../common/formInputs/FormReactSelectBrand';
import FormReactSelectModel from '../../common/formInputs/FormReactSelectModel';
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace';
import { FormInputAddressComplete } from '../../common/formInputs/FormInputAddressComplete';
import getMinAllowedBirthday from '../../common/helpers/getMinAllowedBirthday';

const titles = {
  1: 'Dati del Veicolo',
  2: 'Dati Personali',
  3: 'Dati Assicurativi',
  4: 'Calcolare',
};

function BackOfficeRcVan() {
  const navigate = useNavigate();

  const skipCheckGenderRef = useRef(false);
  const skipCheckInsuranceTypeRef = useRef(false);

  const { requestToken } = useParams();

  // Constants
  const steps = [
    'Dati del Veicolo',
    'Dati Personali',
    'Dati Assicurativi',
    'Calcolare',
  ];

  const state = useSelector((store) => store.formsBackoffice.autocarro);
  const {
    loading, isLoadingVehicleModels, isLoadingVehicleVersions, isSubmitting,
    index, states, municipalities,
    vehicleModels, dataPrivacy, brands, formData,
    vehicleVersions, driverForm, ownerForm, vehicleActivities, loadingQuotes,
  } = state;

  const title = titles[index];

  // STATE
  const bersaniComponentRef = useRef();
  const driverFormComponentRef = useRef();
  const ownerFormComponentRef = useRef();

  const {
    register,
    formState: { errors, touchedFields },
    setValue,
    getValues,
    trigger,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(answersValidationsSchema),
    shouldFocusError: true,
    defaultValues: {
      product: 'autocarro',
      source: 'greenia.it',
      contractor_is_owner: '1',
      contractor_is_driver: '1',
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      phone: '',
      email: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: '2',
      education_level_id: '3',
      profession_id: '1',
      driving_license_year: '',
      vehicle_plate: '',
      vehicle_brand_code: '',
      vehicle_model_code: '',
      vehicle_version_code: '',
      vehicle_am_code: '',
      imm_vehicle_year: '',
      imm_vehicle_month: '',
      inherit_merit_class: '',
      merit_class: '1',
      vehicle_purchased_month: '',
      vehicle_purchased_year: '',
      theft_protection_code: '4',
      tow_hook: '0',
      privacies: [],
      children: '0',
      vehicle_parking: '1',
      other_power_supply: '9',
      vehicle_usage: '1',
      predicted_km: 10000,
      vehicles_owned: 2,
      policy_effective_date: '',
      other_drivers: '1',
      youngest_age_driver: 30,
      youngest_age_family_member: 30,
      mofified_vehicle: 1,
      valid_driving_license: 1,
      first_insuranced_year: '',
      violations: 0,
      violations_data: calculateEmptyViolationsData(),
      violations_number: 0,
      business_name: '',
      vat_number: '',
      company_type: null,
      guide_type: 'free',
      insurance_type: '',
      complete_manually: false,
      renew_check: false,
      new_risks_check: false,
      existing_customer: false,
      active_customer_id: null,
      power: 0,
      weight: 0,
      vehicle_activity: '',
      is_heavy: '',
      payment_frequency: 1,
      massa_com: null,
      provenienza: '',
      adr: '',
      uso_autocarro: '',
    },
  });

  const answers = watch();

  useEffect(() => {
    const values = getValues();

    if (values !== undefined) {
      const objectKeys = Object.keys(values);

      objectKeys.forEach((key) => {
        if (values[key] !== '' && values[key] !== null) {
          setValue(key, values[key], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      });
    }
  }, []);

  const updateAnswers = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const [skipBrandCodeEffect, setSkipBrandCodeEffect] = useState(false);
  const vehicleBrandCodeEffectDependencies = useRef();
  const vehicleBrandCodeDeps = [answers.vehicle_brand_code, answers.imm_vehicle_year];
  useEffect(() => {
    if (skipBrandCodeEffect) {
      setSkipBrandCodeEffect(false);
      return;
    }

    if (vehicleBrandCodeEffectDependencies.current === undefined) {
      vehicleBrandCodeEffectDependencies.current = JSON.stringify(vehicleBrandCodeDeps);
      return;
    }

    if (vehicleBrandCodeEffectDependencies.current === JSON.stringify(vehicleBrandCodeDeps)) {
      return;
    }

    vehicleBrandCodeEffectDependencies.current = JSON.stringify(vehicleBrandCodeDeps);
    setValue('vehicle_model_code', '');
  }, vehicleBrandCodeDeps);

  const [skipModelCodeEffect, setSkipModelCodeEffect] = useState(false);
  const vehicleModelCodeEffectDependencies = useRef();
  const vehicleModelCodeDeps = [answers.vehicle_model_code];
  useEffect(() => {
    if (skipModelCodeEffect) {
      setSkipModelCodeEffect(false);
      return;
    }

    if (vehicleModelCodeEffectDependencies.current === undefined) {
      vehicleModelCodeEffectDependencies.current = JSON.stringify(vehicleModelCodeDeps);
      return;
    }

    if (vehicleModelCodeEffectDependencies.current === JSON.stringify(vehicleModelCodeDeps)) {
      return;
    }

    vehicleModelCodeEffectDependencies.current = JSON.stringify(vehicleModelCodeDeps);

    setValue('vehicle_version_code', null);
    setValue('vehicle_am_code', '');
  }, vehicleModelCodeDeps);

  useEffect(() => {
    if (skipCheckInsuranceTypeRef.current === true) {
      skipCheckInsuranceTypeRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (answers.insurance_type === 'N') {
      setValue('first_insuranced_year', new Date().getFullYear().toString());
      setValue('violations', '0');
    } else if (answers.insurance_type === 'B' && answers.first_insuranced_year === '') {
      setValue('first_insuranced_year', '', triggerValidation);
      setValue('violations', '0', triggerValidation);
    }
  }, [answers.insurance_type]);

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
      skipCheckGenderRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (answers.gender === 'G') {
      setValue('contractor_is_driver', '0');
      setValue('date_of_birth', null);
      setValue('country_of_birth_code', null);
      setValue('province_of_birth_code', null);
      setValue('commune_of_birth_code', null);
      setValue('born_abroad', false);
      setValue('civil_status_id', null);
      setValue('education_level_id', null);
      setValue('profession_id', null);
      setValue('driving_license_year', null);
      setValue('children', null);
      setValue('name', null);
      setValue('surname', null);
    } else if (['M', 'F'].includes(answers.gender) && answers.date_of_birth === null) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('country_of_birth_code', 'Z000', triggerValidation);
      setValue('province_of_birth_code', null, triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('civil_status_id', '2', triggerValidation);
      setValue('education_level_id', '3', triggerValidation);
      setValue('profession_id', '1', triggerValidation);
      setValue('driving_license_year', '', triggerValidation);
      setValue('children', '0', triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [answers.gender, answers.date_of_birth, answers.contractor_is_driver]);

  // Collect Owner Data
  const [owner, setOwner] = useState(
    {
      isValid: false,
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: '2',
      education_level_id: '3',
      profession_id: '',
      valid_driving_license: '1',
      driving_license_year: '',
      business_name: '',
      vat_number: '',
      company_type: null,
    },
  );
  // Collect Driver Data
  const [driver, setDriver] = useState(
    {
      isValid: false,
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: '2',
      education_level_id: '3',
      profession_id: '',
      valid_driving_license: '1',
      driving_license_year: '',
    },
  );
  const [bersani, setBersani] = useState(
    {
      isValid: false,
      name: '',
      surname: '',
      date_of_birth: '',
      gender: '',
      commune_of_birth_code: '',
      vehicle_type: '',
      vehicle_plate: '',
    },
  );

  const getFiscalCode = (birthDate) => {
    const d = birthDate.split('-');
    const fData = {
      name: answers.name,
      surname: answers.surname,
      gender: answers.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };

    if (answers.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === answers.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === answers.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = answers.province_of_birth_code;
    }

    return generateFiscalCode(fData);
  };

  const getFiscalCodeOD = (data) => {
    const d = convertToItalianDate(data.date_of_birth).split('-');
    const fData = {
      name: data.name,
      surname: data.surname,
      gender: data.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };

    if (data.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === data.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === data.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = data.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const submitAnswers = () => {
    const answersData = {
      ...answers,
      owner: answers.contractor_is_owner === '0' ? owner : null,
      driver: answers.contractor_is_driver === '0' ? driver : null,
      bersani:
        answers.insurance_type === 'N' && answers.inherit_merit_class != 'N'
          ? bersani
          : null,
    };

    answersData.date_of_birth = answers.date_of_birth !== null ? convertToItalianDate(answers.date_of_birth) : null;
    answersData.policy_effective_date = convertToItalianDate(
      answers.policy_effective_date,
    );
    answersData.fiscal_code = (answers.gender !== 'G') ? getFiscalCode(answers.date_of_birth) : null;
    if (answers.contractor_is_owner === '0') {
      answersData.owner.date_of_birth = owner.gender !== 'G' ? convertToItalianDate(
        owner.date_of_birth,
      ) : null;
      answersData.owner.fiscal_code = (owner.gender !== 'G') ? getFiscalCodeOD(owner) : null;
    }
    if (answers.contractor_is_driver === '0') {
      answersData.driver.date_of_birth = convertToItalianDate(
        driver.date_of_birth,
      );
      answersData.driver.fiscal_code = getFiscalCodeOD(driver);
    }

    autocarroSubmitAnswers(answersData, navigate, requestToken);
  };

  const updateFormData = (answer, doNotRunVehicleInfoChangeChecks) => {
    if (doNotRunVehicleInfoChangeChecks !== true) {
      if ((answer.vehicle_brand_code
        && answer.vehicle_brand_code != answers.vehicle_brand_code)) {
        answer.vehicle_model_code = '';
        answer.vehicle_version_code = null;
      } else if (
        (answer.vehicle_brand_code
          && answer.vehicle_brand_code != answers.vehicle_brand_code)
        || (answer.vehicle_model_code
          && answer.vehicle_model_code != answers.vehicle_model_code)
        || (answer.imm_vehicle_year
          && answer.imm_vehicle_year != answers.imm_vehicle_year)
      ) {
        answer.vehicle_version_code = null;
      }
    }

    updateAnswers({ ...answer });
  };

  const updateBersaniData = (data) => {
    setBersani({ ...bersani, ...data });
  };

  const updateOwnerFormData = (data) => {
    setOwner({ ...owner, ...data });
  };

  const updateDriverFormData = (data) => {
    setDriver({ ...driver, ...data });
  };

  const prevButton = () => {
    if (index > 1) {
      autocarroChangeIndex(index - 1);
    }
  };
  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const userDataArray = { ...answers }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const nextButton = async () => {
    const res = await trigger(answersFieldsBySteps[index - 1], { shouldFocus: true });

    if (res !== true) {
      return;
    }

    if (index === 2) {
      if (answers.contractor_is_driver === '0') {
        if (!driver.isValid) {
          driverFormComponentRef.current.triggerErrors();
          return;
        }
      }

      if (answers.contractor_is_owner === '0') {
        if (!owner.isValid) {
          ownerFormComponentRef.current.triggerErrors();
          return;
        }
      }
    }

    if (index === 3) {
      if (
        answers.inherit_merit_class === 'S'
        || (
          answers.inherit_merit_class == 'A'
          && answers.insurance_type === 'N'
        )
      ) {
        if (!bersani.isValid) {
          bersaniComponentRef.current.triggerErrors();
          return;
        }
      }
    }

    if (index === 4) {
      const validation = validateDataPrivacy();
      if (validation.error) {
        generalAlertError(validation.message);
        return;
      }
    }

    if (index === 4) {
      submitAnswers();
      return;
    }

    autocarroChangeIndex(index + 1);
    setTimeout(() => window.scrollTo(0, 0), 500);
  };

  let typeGuide;

  if (answers.other_drivers === '1') {
    typeGuide = guideType.filter((guide) => {
      if (
        answers.youngest_age_driver < 26
        || answers.youngest_age_family_member < 26
      ) {
        return guide.id === 'free';
      }
      return guide.id !== 'exclusive';
    });
  } else {
    typeGuide = guideType.filter((guide) => {
      if (answers.youngest_age_family_member < 26) {
        return guide.id !== 'expert';
      } if (answers.youngest_age_family_member >= 26) {
        return guide;
      }

      return false;
    });
  }

  useEffect(() => {
    autocarroGetInitialData();

    return () => {
      resetAutocarroForm();
    };
  }, []);

  const setDriverForm = (value) => {
    autocarroUpdateFormData({
      driverForm: value,
    });
  };

  const setOwnerForm = (value) => {
    autocarroUpdateFormData({
      ownerForm: value,
    });
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (answers.vehicle_brand_code) {
      autocarroGetVehicleModels(answers.vehicle_brand_code, answers.imm_vehicle_year);
    }
  }, [answers.vehicle_brand_code, answers.imm_vehicle_year]);

  useEffect(() => {
    if (answers.vehicle_brand_code && answers.vehicle_model_code && answers.imm_vehicle_year) {
      autocarroGetVehicleVersions(answers.vehicle_model_code, answers.imm_vehicle_year);
    }
  }, [answers.vehicle_brand_code, answers.vehicle_model_code, answers.imm_vehicle_year]);

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...answers.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateAnswers({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...answers.privacies];
    answersNewArray.push(item.id);
    updateAnswers({
      privacies: answersNewArray,
    });
  };

  useEffect(() => {
    if (!answers.driving_license_year) {
      if (answers.date_of_birth) {
        const birthYear = answers.date_of_birth.split('-')[0];
        const regex = /^\d{4}$/;
        if (regex.test(parseInt(birthYear))) {
          const drivingLicenseYear = parseInt(birthYear) + 18;
          updateAnswers({
            driving_license_year: drivingLicenseYear.toString(),
          });
        }
      }
    }
  }, [answers.date_of_birth]);

  useEffect(() => {
    const callAsync = async () => {
      skipCheckGenderRef.current = true;
      const {
        data: {
          data: {
            answers, owner, driver, bersani,
          },
        },
      } = await modifyRequestDataBackoffice(requestToken);

      setSkipBrandCodeEffect(true);
      setSkipModelCodeEffect(true);
      updateAnswers(answers);

      setOwner(owner);
      setDriver(driver);
      setBersani(bersani);
    };

    callAsync();
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  if (loadingQuotes) {
    return <QuoteGenerateLoading />;
  }

  return (
    <Layout>
      <div className="d-flex align-content-center justify-content-center">
        <ProgressBar activeStep={index} steps={steps} />
      </div>
      <div>
        {isSubmitting && (
          <div className="stopInteraction">
            <div className="black-cover" />
            <span>
              <div className="text-center">
                <img
                  className="loading-gif"
                  src={LoadingGif}
                  alt=":aoding indicator"
                />
                <br />
                Attendere prego
              </div>
            </span>
          </div>
        )}
        <div className=" row title-questionnaire">
          <div className="quote-h2 divider">
            <h2>{title}</h2>
          </div>
        </div>
        {index === 1 && (
          <>
            <div className="form-container">
              <FormInputText
                label="Targa dell' autocarro"
                error={errors.vehicle_plate?.message}
                onChange={(value) => updateFormData({ vehicle_plate: value.toUpperCase() })}
                value={answers.vehicle_plate}
                valid={isValid('vehicle_plate')}
              />
            </div>

            {answers.vehicle_plate !== '' && (!errors.vehicle_plate?.message)
              && (answers.imm_vehicle_month !== '' || answers.complete_manually === true)
              && (
                <div className="form-container">
                  <div className="row mt-2">
                    <Label>Data di prima immatricolazione</Label>
                    <div className="col-12 col-md-8">
                      <FormInputSelect
                        registration={register('imm_vehicle_month')}
                        error={errors.imm_vehicle_month?.message}
                        valid={isValid('imm_vehicle_month')}
                        placeholder="-Mese-"
                        options={months}
                      />
                    </div>

                    <div className="col-12 col-md-4">
                      <FormInputSelect
                        registration={register('imm_vehicle_year')}
                        error={errors.imm_vehicle_year?.message}
                        valid={isValid('imm_vehicle_year')}
                        placeholder="-Anno-"
                        options={years}
                      />
                    </div>
                  </div>

                  <FormReactSelectBrand
                    label="Marca"
                    values={{
                      vehicle_brand_code: answers.vehicle_brand_code,
                    }}
                    error={errors.vehicle_brand_code?.message}
                    valid={isValid('vehicle_brand_code')}
                    onChange={(item) => updateFormData({
                      vehicle_brand_code: item.vehicle_brand_code,
                    })}
                    options={brands}
                  />
                </div>
              )}

            {answers.imm_vehicle_year ? (
              <>
                {answers.vehicle_brand_code && (
                  <div className="form-container">
                    {isLoadingVehicleModels ? (
                      <p className="text-center">Attendere prego...</p>
                    ) : (
                      <FormReactSelectModel
                        label="Modello"
                        values={{
                          vehicle_model_code: answers.vehicle_model_code,
                        }}
                        error={errors.vehicle_model_code?.message}
                        valid={isValid('vehicle_model_code')}
                        onChange={(item) => updateFormData({
                          vehicle_model_code: item.vehicle_model_code,
                        })}
                        options={vehicleModels}
                      />
                    )}
                  </div>
                )}
                {answers.vehicle_brand_code && answers.vehicle_model_code ? (
                  isLoadingVehicleVersions ? (
                    <p className="text-center">Attendere prego...</p>
                  ) : answers.vehicle_model_code
                    && vehicleVersions.length > 0 ? (
                      <InputSelectTable
                        TableHeader={(
                          <div className="d-flex">
                            <div className="col-7">Descrizione</div>
                            <div className="col-1">CC</div>
                            <div className="col-4">Data Veicolo</div>
                          </div>
                      )}
                        label="Versione"
                        name="vehicle_version_code"
                        id="vehicle_version_code"
                        options={vehicleVersions}
                        selected={answers.vehicle_am_code}
                        onSelectOption={(value) => updateFormData({
                          vehicle_version_code: value.id,
                          vehicle_am_code: value.am_code,
                        })}
                      />
                    ) : (
                      <div className="alert-general-error">
                        Nessuna versione trovata per questo modello!
                      </div>
                    )
                ) : (
                  ''
                )}
                {answers.vehicle_version_code && (
                  <div className="form-container">
                    <FormInputNumber
                      registration={register('weight')}
                      error={errors.weight?.message}
                      valid={isValid('weight')}
                      label="Peso dell'auto"
                    />
                    <FormInputNumber
                      registration={register('power')}
                      error={errors.power?.message}
                      valid={isValid('power')}
                      label="Potenza in kW"
                    />
                    <FormInputSelect
                      label="Altra alimentazione"
                      registration={register('other_power_supply')}
                      error={errors.other_power_supply?.message}
                      valid={isValid('other_power_supply')}
                      placeholder="-Seleziona-"
                      options={formData.vehicle_fuels}
                    />
                    <FormInputSelect
                      label="Parcheggio auto"
                      registration={register('vehicle_parking')}
                      error={errors.vehicle_parking?.message}
                      valid={isValid('vehicle_parking')}
                      placeholder="-Seleziona-"
                      options={formData.parking_types}
                    />
                    <FormInputSelect
                      label="Antifurto"
                      registration={register('theft_protection_code')}
                      error={errors.theft_protection_code?.message}
                      valid={isValid('theft_protection_code')}
                      placeholder="-Seleziona-"
                      options={formData.theft_protections}
                    />
                    <FormInputSelect
                      label="Utilizzo"
                      registration={register('vehicle_usage')}
                      error={errors.vehicle_usage?.message}
                      valid={isValid('vehicle_usage')}
                      placeholder="-Seleziona-"
                      options={formData.vehicle_usage_types.filter((type) => type.vehicle_type === 'auto')}
                    />
                    <FormInputSelect
                      label="Settore di attività"
                      placeholder="-Seleziona-"
                      options={vehicleActivities}
                      registration={register('vehicle_activity')}
                      valid={isValid('vehicle_activity')}
                      error={errors.vehicle_activity?.message}
                    />
                    <FormInputSelect
                      label="Km percorsi in un anno"
                      registration={register('predicted_km')}
                      error={errors.predicted_km?.message}
                      valid={isValid('predicted_km')}
                      placeholder="-Seleziona-"
                      options={km_during_one_year}
                    />
                    <FormInputNumber
                      registration={register('massa_com')}
                      error={errors.massa_com?.message}
                      valid={isValid('massa_com')}
                      min={0}
                      max={440}
                      label="Massa Complesiva (0-440)"
                    />
                    <FormInputSelect
                      label="Provenienza"
                      placeholder="-Seleziona-"
                      options={provenienza}
                      registration={register('provenienza')}
                      valid={isValid('provenienza')}
                      error={errors.provenienza?.message}
                    />
                    <FormInputSelect
                      label="ADR"
                      placeholder="-Seleziona-"
                      options={adr}
                      registration={register('adr')}
                      valid={isValid('adr')}
                      error={errors.adr?.message}
                    />
                    <FormInputSelect
                      label="Uso Veicolo"
                      placeholder="-Seleziona-"
                      options={usoAutocarro}
                      registration={register('uso_autocarro')}
                      valid={isValid('uso_autocarro')}
                      error={errors.uso_autocarro?.message}
                    />
                    {answers.imm_vehicle_year !== '' && (
                      <div className="row mt-2">
                        <Label>Mese e anno in cui hai comprato l'auto</Label>
                        <div className="col-12 col-md-8">
                          <FormInputSelect
                            registration={register('vehicle_purchased_month')}
                            error={errors.vehicle_purchased_month?.message}
                            valid={isValid('vehicle_purchased_month')}
                            placeholder="-Mese-"
                            options={months}
                          />
                        </div>

                        <div className="col-12 col-md-4">
                          <FormInputSelect
                            registration={register('vehicle_purchased_year')}
                            error={errors.vehicle_purchased_year?.message}
                            valid={isValid('vehicle_purchased_year')}
                            placeholder="-Anno-"
                            options={getAllowedPurchaseYears(
                              answers.imm_vehicle_year,
                            )}
                          />
                        </div>
                      </div>
                    )}

                    <FormInputSelect
                      label="Auto nel nucleo familiare"
                      registration={register('vehicles_owned')}
                      error={errors.vehicles_owned?.message}
                      valid={isValid('vehicles_owned')}
                      placeholder="-Seleziona-"
                      options={vehiclesAmountInFamily}
                    />
                    <FormInputRadio
                      name="tow_hook"
                      label="Hai montato il gancio di traino?"
                      paragraph="(iscritta a rimorchiatori, caravan, ecc.)"
                      options={flagResponse}
                      registration={register('tow_hook')}
                      error={errors.tow_hook?.message}
                    />
                    <FormInputRadio
                      label="Peso a carico del veicolo supera i 35 Quintali?"
                      options={flagResponse}
                      name="is_heavy"
                      registration={register('is_heavy')}
                      error={errors.is_heavy?.message}
                    />
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </>
        )}
        {index === 2 && (
          <div className="form-container">
            <FormInputRadio
              label="Il contraente è"
              options={genders}
              name="gender"
              registration={register('gender')}
              error={errors.gender?.message}
            />
            {answers.gender === 'G' && (
              <>
                <FormInputText
                  registration={register('business_name')}
                  error={errors.business_name?.message}
                  valid={isValid('business_name')}
                  label="Ragione sociale"
                />
                <FormInputText
                  registration={register('vat_number')}
                  error={errors.vat_number?.message}
                  valid={isValid('vat_number')}
                  label="Partita Iva"
                />
                <FormInputSelect
                  label="Tipologia azienda"
                  registration={register('company_type')}
                  error={errors.company_type?.message}
                  valid={isValid('company_type')}
                  placeholder="-Seleziona-"
                  options={formData.company_types}
                />
              </>
            )}
            {answers.gender !== 'G'
              && (
                <>
                  <FormInputDate
                    label="Data di nascita"
                    registration={register('date_of_birth')}
                    minDate={getMinAllowedBirthday()}
                    maxDate={getMaxAllowedBirthday()}
                    error={errors.date_of_birth?.message}
                    valid={isValid('date_of_birth')}
                  />
                  {answers.date_of_birth !== '' && (
                    <FormInputSelect
                      label="Anno patente"
                      registration={register('driving_license_year')}
                      error={errors.driving_license_year?.message}
                      valid={isValid('driving_license_year')}
                      placeholder="-Seleziona-"
                      options={getAllowedLicenseYears(answers.date_of_birth)}
                    />
                  )}
                  <FormReactSelectInputBirthPlace
                    states={states}
                    options={municipalities}
                    label="Luogo di nascita"
                    name="commune_of_birth_code"
                    values={{
                      commune_of_birth_code: answers.commune_of_birth_code,
                      province_of_birth_code: answers.province_of_birth_code,
                      born_abroad: answers.born_abroad,
                      country_of_birth_code: answers.country_of_birth_code,
                    }}
                    onChange={(value) => updateFormData({
                      commune_of_birth_code: value.commune_of_birth_code,
                      province_of_birth_code: value.province_of_birth_code,
                      born_abroad: value.born_abroad,
                      country_of_birth_code: value.country_of_birth_code,
                    })}
                    valid={isValid('commune_of_birth_code')}
                    error={errors.commune_of_birth_code?.message}
                  />
                </>
              )}

            <FormInputAddressComplete
              municipalities={municipalities}
              formData={answers}
              isValid={isValid}
              errors={errors}
              register={register}
              updateFormData={updateFormData}
              addressId="autocomplete-address-contractor"
            />

            {answers.gender !== 'G'
              && (
                <>
                  <FormInputRadio
                    label="Figli conviventi?"
                    options={flagResponse}
                    name="children"
                    registration={register('children')}
                    error={errors.children?.message}
                  />
                  <FormInputSelect
                    placeholder="Stato civile"
                    label="Stato civile"
                    registration={register('civil_status_id')}
                    error={errors.civil_status_id?.message}
                    valid={isValid('civil_status_id')}
                    options={formData.marital_statuses}
                  />
                  <FormInputSelect
                    placeholder="Titolo di studio"
                    label="Titolo di studio"
                    registration={register('education_level_id')}
                    error={errors.education_level_id?.message}
                    valid={isValid('education_level_id')}
                    options={formData.qualifications}
                  />
                  <FormInputSelect
                    placeholder="Professione"
                    label="Professione"
                    registration={register('profession_id')}
                    error={errors.profession_id?.message}
                    valid={isValid('profession_id')}
                    options={formData.professions}
                  />
                </>
              )}

            {answers.gender !== 'G'
              && (
                <FormInputRadio
                  label="Il contraente è anche il conducente abituale del veicolo?"
                  options={flagResponse}
                  name="contractor_is_driver"
                  registration={register('contractor_is_driver')}
                  error={errors.contractor_is_driver?.message}
                />
              )}

            {answers.contractor_is_driver === '0' ? (
              <OffcanvasForm
                ref={driverFormComponentRef}
                filled={driverForm}
                setFilled={setDriverForm}
                id="dati-driver"
                title="Dati Del Conduttore"
                data={driver}
                onChange={updateDriverFormData}
                genders={genders}
                getMaxAllowedBirthday={getMaxAllowedBirthday}
                municipalities={municipalities}
                validateItalianPostCode={validateItalianPostCode}
                marital_statuses={formData.marital_statuses}
                qualifications={formData.qualifications}
                professions={formData.professions}
                company_types={formData.company_types}
                isOwnerForm={false}
                states={states}
              />
            ) : (
              ''
            )}

            {answers.gender !== 'G'
              && (
                <FormInputRadio
                  label="Il contraente è anche proprietario dell auto?"
                  options={flagResponse}
                  name="contractor_is_owner"
                  registration={register('contractor_is_owner')}
                  error={errors.contractor_is_owner?.message}
                />
              )}

            {answers.contractor_is_owner === '0' ? (
              <OffcanvasForm
                ref={ownerFormComponentRef}
                filled={ownerForm}
                states={states}
                setFilled={setOwnerForm}
                id="dati-proprietario"
                title="Dati Del Proprietario"
                data={owner}
                onChange={updateOwnerFormData}
                genders={genders}
                getMaxAllowedBirthday={getMaxAllowedBirthday}
                municipalities={municipalities}
                validateItalianPostCode={validateItalianPostCode}
                marital_statuses={formData.marital_statuses}
                qualifications={formData.qualifications}
                professions={formData.professions}
                company_types={formData.company_types}
                isOwnerForm
              />
            ) : (
              ''
            )}
          </div>
        )}
        {index === 3 && (
          <>
            <div className="form-container">
              {answers.renew_check && (
                <FormInputSelect
                  label="Seleziona il tipo di assicurazione."
                  registration={register('insurance_type')}
                  error={errors.insurance_type?.message}
                  valid={isValid('insurance_type')}
                  placeholder="-Seleziona-"
                  options={insuranceTypes}
                  disabled
                />
              )}
              {!answers.renew_check && (
                <FormInputSelect
                  label="Seleziona il tipo di assicurazione."
                  registration={register('insurance_type')}
                  error={errors.insurance_type?.message}
                  valid={isValid('insurance_type')}
                  placeholder="-Seleziona-"
                  options={insuranceTypes}
                />
              )}

              <FormInputDate
                minDate={getAllowedPolicyEffectDate('min')}
                maxDate={getAllowedPolicyEffectDate('max')}
                registration={register('policy_effective_date')}
                error={errors.policy_effective_date?.message}
                valid={isValid('policy_effective_date')}
                label={
                  answers.insurance_type === 'N'
                    ? 'Data di inizio della copertura della polizza'
                    : 'Data di scadenza della tua attuale polizza'
                }
                paragraph="Date valide: da oggi a 30 giorni da oggi"
              />
              <FormInputRadio
                label="Ci sono altri conducenti?"
                options={flagResponse}
                name="other_drivers"
                registration={register('other_drivers')}
                error={errors.other_drivers?.message}
              />
              {answers.gender !== 'G'
                && (
                  <>
                    {answers.other_drivers === '1' && (
                      <FormInputText
                        placeholder="(18 - 100)"
                        type="number"
                        min={18}
                        max={100}
                        label="Età del conducente più giovane"
                        registration={register('youngest_age_driver')}
                        error={errors.youngest_age_driver?.message}
                        valid={isValid('youngest_age_driver')}
                      />
                    )}
                    <FormInputText
                      placeholder="(18 - 100)"
                      type="number"
                      min={18}
                      max={100}
                      label="Età del membro più giovane della famiglia con la patente di guida"
                      registration={register('youngest_age_family_member')}
                      error={errors.youngest_age_family_member?.message}
                      valid={isValid('youngest_age_family_member')}
                    />
                  </>
                )}
              {answers.insurance_type !== 'N'
                && (
                  <>
                    <FormInputSelect
                      label="Prima assicurazione in 6 anni"
                      registration={register('first_insuranced_year')}
                      error={errors.first_insuranced_year?.message}
                      valid={isValid('first_insuranced_year')}
                      placeholder="-Seleziona-"
                      options={lastSixYears}
                    />
                    <FormInputRadio
                      label="Hai fatto sinistri negli ultimi 6 anni?"
                      name="violations"
                      placeholder="-Seleziona-"
                      options={flagResponse}
                      onChange={(value) => {
                        const update_data = {
                          violations: value,
                        };

                        if (value === 0) {
                          update_data.violations_number = 0;
                          update_data.violations_data = calculateEmptyViolationsData();
                        }

                        updateFormData(update_data);
                      }}
                      value={answers.violations}
                    />
                  </>
                )}
            </div>
            {answers.violations === 1 && (
              <InputViolations
                title="Violations"
                onChange={(data) => {
                  updateFormData({
                    violations_data: data.data,
                    violations_number: data.violations_number,
                  });
                }}
                value={answers.violations_data}
              />
            )}

            <div className="form-container">
              {answers.insurance_type === 'N' && (
                <FormInputSelect
                  label="Puoi utilizzare la classe di merito di un altro veicolo già assicurato?"
                  registration={register('inherit_merit_class')}
                  error={errors.inherit_merit_class?.message}
                  valid={isValid('inherit_merit_class')}
                  placeholder="-Seleziona-"
                  options={inheritMeritClass}
                />
              )}

              {((answers.inherit_merit_class == 'A'
                && answers.insurance_type === 'N') || answers.inherit_merit_class == 'S') && (
                  <BersaniInfo
                    ref={bersaniComponentRef}
                    inheritMeritClass={answers.inherit_merit_class}
                    bersani={bersani}
                    updateBersaniData={updateBersaniData}
                    municipalities={municipalities}
                  />
              )}

              <FormInputSelect
                label={
                  answers.inherit_merit_class == 'S'
                    || (answers.inherit_merit_class == 'A'
                      && answers.insurance_type === 'N')
                    ? 'Classe di merito del veicolo già  assicurato'
                    : 'Classe di merito.'
                }
                registration={register('merit_class')}
                error={errors.merit_class?.message}
                valid={isValid('merit_class')}
                placeholder="-Seleziona-"
                options={meritClass}
              />

              <FormInputRadio
                label="Il conducente dichiara: di essere in possesso di patente italiana mai sospesa da 5 anni e con almeno 20 punti, di non aver mai ricevuto sanzioni per ubriachezza, di non aver modificato il veicolo."
                options={flagResponse}
                name="mofified_vehicle_and_valid_driving_license"
                onChange={(value) => updateFormData({
                  mofified_vehicle: value,
                  valid_driving_license: value,
                })}
                value={answers.mofified_vehicle}
                error={errors.mofified_vehicle?.message}
              />
              <FormInputSelect
                label="Tipo di guida"
                registration={register('guide_type')}
                error={errors.guide_type?.message}
                valid={isValid('guide_type')}
                placeholder="-Seleziona-"
                options={typeGuide}
              />
            </div>
          </>
        )}
        {index === 4 && (
          <>
            <div className="form-container">
              {answers.gender !== 'G' && (
                <>
                  <FormInputText
                    registration={register('name')}
                    error={errors.name?.message}
                    valid={isValid('name')}
                    label="Nome"
                  />
                  <FormInputText
                    registration={register('surname')}
                    error={errors.surname?.message}
                    valid={isValid('surname')}
                    label="Cognome"
                  />
                </>
              )}
              <FormInputText
                registration={register('email')}
                error={errors.email?.message}
                valid={isValid('email')}
                label="E-mail"
              />
              <FormInputText
                registration={register('phone')}
                error={errors.phone?.message}
                valid={isValid('phone')}
                label="Telefono"
                paragraph="Numeri di rete fissa non accettati dalle aziende"
              />

              <PaymentTypePeriod selectedType={answers.payment_frequency} updateAnswers={updateAnswers} />

              <h5 className="text-center">Informativa Privacy e IVASS</h5>

              <div className="text-center text-decoration-underline mt-2">
                <a onClick={() => greeniaPrivacyPolicy()} style={{ cursor: 'pointer' }}>
                  <small className="text-center">Greenia Privacy Policy</small>
                </a>
              </div>
            </div>

            {dataPrivacy.map((item, i) => (
              <InputPrivacy
                required={item.required}
                label={item.content}
                name={item.type}
                id={item.type}
                key={i}
                checked={answers.privacies.includes(item.id)}
                onChange={(value) => privacyItemChangeHandler(value, item)}
              />
            ))}
          </>
        )}
      </div>
      {index >= 1 && index <= 4 && (
        <div className="footer-buttons p-5">
          <div className="d-flex justify-content-between">
            {index !== 1 && (
              <button
                className="btn btn-questionnaire back"
                onClick={prevButton}
              >
                Indietro
              </button>
            )}
            <button className="btn btn-questionnaire" onClick={nextButton}>
              {index === 4 ? 'Vai ai preventivi' : 'Continua'}
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default BackOfficeRcVan;
