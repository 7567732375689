import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { addAccidentNote, getAccident, resetShowAccident } from '../../features/accidents/accidentsActions';
import downloadIMG from '../../assets/images/purchase/download.png';
import pdfIMG from '../../assets/images/purchase/pdf.png';
import PageLoading from '../../common/components/elements/PageLoading';
import axe from '../../services/axios-client/axiosClient';

import ProvidersMap from '../../common/components/ProvidersMap';
import FileUpload from '../../common/components/inputs/FileUpload';
import { apiAfterSaleOptionalDocumentMethodManualUpload, apiDownloadAfterSaleOptionalDocument } from '../../services/axios-client/axeCommons';
import { generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackAccident } from '../../common/utils/joyrideCallbacks';
// import { accidentSteps } from '../../common/utils/joyrideSteps';

const schema = yup.object({
  note: yup.string().required('Questo campo e obligatorio'),
});

function AccidentPage() {
  const navigate = useNavigate();
  const { accidentId } = useParams();
  const {
    register, formState: { errors }, handleSubmit, reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      note: '',
    },
  });
  const state = useSelector((store) => store.accidents.accident_show);
  const {
    loading, accident, accidentFiles, error,
  } = state;

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('singleAccident') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('singleAccident')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('singleAccident');
  //   setState({ run: true, tourActive: true });
  // };

  const addNote = (data) => {
    addAccidentNote(accidentId, data);
    getAccident(accidentId);
    reset({ note: '' });
  };

  useEffect(() => {
    getAccident(accidentId);
    return () => {
      resetShowAccident();
    };
  }, []);

  const downloadDocument = async (documentId) => {
    const response = await axe.get(`/document/${documentId}/download`);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadAfterSaleOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const showAfterSaleUploadedDocuments = () => {
    const docs = [
      ...accident.after_sale_optional_documents,
    ];

    return (
      <>

        {docs.length === 0 && (
          <p>Nessun documento caricato.</p>
        )}

        {docs.map((doc) => (
          <li className="list-group-item p-1 mb-2" key={doc.id}>
            <div className="d-flex align-items-center justify-content-start">
              <div>
                {
                  doc.is_signed_valid === 1 ? (
                    <div
                      className="badge rounded-circle text-bg-success d-flex align-items-center justify-content-center"
                      style={{ width: '30px', height: '30px' }}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  ) : (
                    ''
                  )
                }

                {doc.is_signed_valid === 0 && (
                  <div
                    className="badge rounded-circle text-bg-danger d-flex align-items-center justify-content-center"
                    style={{ width: '30px', height: '30px' }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                )}
              </div>

              <div className="d-flex align-items-center justify-content-start ms-3">
                <img src={pdfIMG} className="img-fluid me-2" width="30" alt="PDF" />
                <h5 className="h6 mb-0">
                  {doc.original_filename}
                  {doc.is_signed_valid === 0 && (
                    <>
                      <br />
                      <div
                        className="badge text-bg-danger text-wrap"
                        style={{ textAlign: 'left' }}
                      >
                        {doc.optional_document_name}
                      </div>
                    </>
                  )}
                </h5>
              </div>
              <div className="d-flex align-items-center justify-content-center ms-auto">
                <button
                  className="doc-btn border-0 bg-transparent"
                  onClick={() => downloadOptionalDocument(doc.id)}
                >
                  <img src={downloadIMG} width="30" alt="" />
                </button>
              </div>
              {doc.is_uploaded_valid === null && doc.signable_document === 0 ? (
                <span className="badge text-bg-warning rounded-pill p-2 ms-auto">
                  Documento in verifica
                </span>
              ) : (
                ''
              )}
              {doc.is_uploaded_valid === 1 && doc.signable_document === 0 ? (
                <span className="badge text-bg-success rounded-pill p-2 ms-auto">
                  Valido
                </span>
              ) : (
                ''
              )}
              {doc.signable_document === 1 && doc.signed_document_filename === null && doc.is_signed_valid === null ? (
                <span className="badge text-bg-warning rounded-pill p-2 ms-auto">
                  In Attesa Firma
                </span>
              ) : (
                ''
              )}
              {doc.signable_document === 1 && doc.signed_document_filename !== null && doc.is_signed_valid === null ? (
                <span className="badge text-bg-warning rounded-pill p-2 ms-auto">
                  Firma in verifica
                </span>
              ) : (
                ''
              )}
              {doc.signable_document === 1 && doc.is_signed_valid === 1 ? (
                <span className="badge text-bg-success rounded-pill p-2 ms-auto">
                  Firmato
                </span>
              ) : (
                ''
              )}
              {doc.signable_document === 1 && doc.is_signed_valid === 0 ? (
                <span className="badge text-bg-danger rounded-pill p-2 ms-auto">
                  Firma non valida
                </span>
              ) : (
                ''
              )}
            </div>
          </li>
        ))}

      </>
    );
  };

  const uploadSignedOptionalDocument = async (documentId, file) => {
    const uploadedFile = file[0];
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      apiAfterSaleOptionalDocumentMethodManualUpload(documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        getAccident(accidentId);
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  if (loading) {
    return <PageLoading />;
  }

  return error === null
    ? (
      <>
        {/* <Joyride
          steps={accidentSteps}
          continuous
          showBeacon={showBeacon}
          locale={{
            back: 'Indietro',
            close: 'Chiudi',
            last: 'Fine',
            next: 'Prossima',
            open: 'Apri il modale',
            restart: 'Riavvia il tour ',
            skip: 'Salta',
          }}
          styles={{
            options: joyrideOptions,

            buttonNext: {
              backgroundColor: '#00a651',
              outline: 'none',
            },
            buttonBack: {
              color: '#00a651',
              outline: 'none',
            },
            beaconInner: {
              backgroundColor: '#00a651',
            },
            beaconOuter: {
              borderColor: '#00a651',
              backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
            },
            buttonSkip: {
              outline: 'none',
            },
            buttonClose: {
              outline: 'none',
            },
          }}
          run={run}
          callback={(data) => handleCallbackAccident(data, accidentSteps, setState)}
          showSkipButton
          showProgress
        /> */}
        <div className="d-flex gap-2">
          <div className="btn btn-success btn-sm text-white px-4 mb-4" onClick={() => navigate('/accidents')}>Torna indietro</div>
          {/* {localStorage.getItem('singleAccident') === 'done' && (
            <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
              <button className="btn btn-success btn-sm text-white px-4 mb-4">Tutorial</button>
            </div>
          )} */}
        </div>
        <h5>
          Sinistro #
          {accident.id}
        </h5>
        <div className="single-accident-one">

          {accident.quotation.active_customer && (
            <div className="fw-bold">
              Cliente:
              {' '}
              {accident.quotation.active_customer.name}
              {' '}
              {accident.quotation.active_customer.surname}
            </div>
          )}
          {accident.quotation.insurance_request.product
            && (
              <>
                Prodotto:
                {' '}
                {accident.quotation.insurance_request.product.name}
                {' '}
                {accident.quotation.insurance_request.product_id == 5 ? `(${accident.quotation.insurance_request.infortuni && accident.quotation.insurance_request.infortuni.company})` : ''}
              </>
            )}
          {accident.quotation.insurance_request.vehicle
            && (
              <>
                <br />
                Targa:
                {' '}
                {accident.quotation.insurance_request.vehicle.vehicle_plate}
              </>
            )}
          {accident.quotation.insurance_request.professional
            && (
              <>
                <br />
                Professione:
                {' '}
                {accident.quotation.insurance_request.professional.profession_desc}
              </>
            )}
          {accident.quotation.insurance_request.infortuni
            && (
              <>
                <br />
                Infortuni:
                {' '}
                {accident.quotation.insurance_request.infortuni.pack_type}
                {' '}
                -
                {' '}
                {accident.quotation.insurance_request.infortuni.name}
              </>
            )}
          <br />
          <small>
            <strong>
              Creato:
              {new Date(accident.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
            </strong>
          </small>
        </div>

        <br />
        <br />
        <div className="single-accident-two">
          <h3>Note</h3>
          {accident.notes.length
            ? accident.notes.map((note, index) => (
              <p key={index} className="p-3 bg-light shadow-sm">{note.note}</p>
            ))
            : <p>Nessuna nota trovata!</p>}

        </div>
        <form onSubmit={handleSubmit(addNote)} className="single-accident-three">
          <div className="mb-3 ">
            <label className="form-label" htmlFor="note">Aggiungi nuova nota</label>
            <textarea name="note" className={`form-control ${errors.note?.message ? 'is-invalid' : ''}`} rows="5" {...register('note')} />
            <div className="invalid-feedback">
              {errors.note?.message}
            </div>
            <button type="submit" className="btn btn-success px-4">Invia</button>
          </div>
        </form>
        {
          accident.amount !== null
          && (
            <>
              <h3 className="mt-4">Ammontare</h3>
              €
              {' '}
              {Number(accident.amount).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
            </>
          )
        }

        <div className="single-accident-four">
          <h3 className="mt-4 mb-3">Documenti</h3>
          {
            accidentFiles.map((accidentFile) => (
              <div key={accidentFile.id} className="d-flex border border-success rounded py-3 m-1" style={{ maxWidth: 600 }}>
                <div className="d-flex align-items-center justify-content-start ms-3">
                  <img src={pdfIMG} className="img-fluid me-2" width="30" alt="PDF" />
                  <h5 className="h6 mb-0">
                    {accidentFile.document.original_filename}
                  </h5>
                </div>
                <div className="d-flex align-items-center justify-content-center ms-auto me-2">
                  <button
                    className="doc-btn border-0 bg-transparent"
                    onClick={() => downloadDocument(accidentFile.document.id)}
                  >
                    <img src={downloadIMG} width="30" alt="" />
                  </button>
                </div>
              </div>
            ))
          }
        </div>

        <div className="row mt-3 mt-xl-0 d-flex single-accident-five">
          <div className="col-12 col-md-6 mt-3 mt-xl-0">
            <div className="card">
              <div className="card-body px-auto px-md-0">
                {accident.after_sale_optional_documents.filter((document) => document.signable_document === 1 && document.is_uploaded_valid === 1 && ((document.is_signed_valid === 0 && document.signed_document_filename !== null) || (document.is_signed_valid === null && document.signed_document_filename === null))).map((file) => (
                  <FileUpload
                    key={file.id}
                    multiple={false}
                    label={`CARICA ${file.optional_document_name.toUpperCase()}`}
                    handleFile={(f) => uploadSignedOptionalDocument(file.id, f)}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mt-3 mt-xl-0">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Documenti Da Firmare</h4>
              </div>
              <div className="card-body px-0">
                {showAfterSaleUploadedDocuments()}
              </div>
            </div>
          </div>
        </div>
        <div className="row single-accident-six">
          <h3 className="my-3">Detagli</h3>
          <div>
            <label className="custom-label mb-1">Indirizzo</label>
            <input
              name="address"
              id="autocomplete"
              placeholder="Inserisci Indirizzo..."
              className={`form-control ${errors.address?.message ? 'is-invalid' : ''} `}
              value={accident?.address}
              disabled
            />
          </div>
          <div className="invalid-feedback">
            {errors.address?.message}
          </div>
          <ProvidersMap mainLatitude={accident.latitude} mainLongitude={accident.longitude} providers={accident.providers} />
        </div>

      </>
    )
    : <ErrorComponent error={error} />;
}

export default AccidentPage;
