import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import { generalAlertError } from '../../common/utils/alerts';
import { getAccidents, resetAccidentsList } from '../../features/accidents/accidentsActions';
import { PurchasedQuotationsStyles } from '../PurchasedQuotations/PurchasedQuotations.styles';
import Pagination from '../../common/components/elements/Pagination';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackAccidents } from '../../common/utils/joyrideCallbacks';
// import { accidentsSteps } from '../../common/utils/joyrideSteps';

function AccidentsPage() {
  const navigate = useNavigate();
  const state = useSelector((store) => store.accidents.accidents_list);

  const {
    loading, accidents, page_number, total_pages, error,
  } = state;
  // const [steps, setSteps] = useState([]);

  const [search, setSearch] = useState({
    search: '',
    plate: '',
    date_from: '',
    date_to: '',
  });

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('accidents') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('accidents')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('accidents');
  //   setState({ run: true, tourActive: true });
  // };

  // useEffect(() => {
  //   accidentsSteps(accidents, setSteps);
  // }, [accidents]);

  useEffect(() => {
    (async () => {
      getAccidents(1, search);
    })();

    return () => {
      resetAccidentsList();
    };
  }, []);

  const handleChange = ({ target: { value, name } }) => {
    setSearch({ ...search, [name]: value });
  };

  const searchHandler = async (e) => {
    e.preventDefault();

    if (search.date_from !== '' && search.date_to === '') {
      generalAlertError('Devi selezionare anche data al');
      return;
    }

    if (search.date_from === '' && search.date_to !== '') {
      generalAlertError('Devi selezionare anche data da');
      return;
    }

    const trimmedSearch = {
      ...search,
      search: search.search.trim(),
      plate: search.plate.trim(),
    };

    getAccidents(1, trimmedSearch);
  };

  return error === null
    ? (
      <PurchasedQuotationsStyles>
        {/* <Joyride
          steps={steps}
          continuous
          showBeacon={showBeacon}
          locale={{
            back: 'Indietro',
            close: 'Chiudi',
            last: 'Fine',
            next: 'Prossima',
            open: 'Apri il modale',
            restart: 'Riavvia il tour ',
            skip: 'Salta',
          }}
          styles={{
            options: joyrideOptions,

            buttonNext: {
              backgroundColor: '#00a651',
              outline: 'none',
            },
            buttonBack: {
              color: '#00a651',
              outline: 'none',
            },
            beaconInner: {
              backgroundColor: '#00a651',
            },
            beaconOuter: {
              borderColor: '#00a651',
              backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
            },
            buttonSkip: {
              outline: 'none',
            },
            buttonClose: {
              outline: 'none',
            },
          }}
          run={run}
          callback={(data) => handleCallbackAccidents(data, steps, setState)}
          showSkipButton
          showProgress
        /> */}
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <h5>Sinistri</h5>
          <div className="d-flex">
            <div className="btn btn-success text-white px-5 m-2" onClick={() => navigate('/after-sale/select')}>Vai a Post Vendita</div>
            {/* {localStorage.getItem('accidents') === 'done' && (
              <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
                <button className="btn btn-success my-2 mx-0 px-3">Tutorial</button>
              </div>
            )} */}
          </div>
        </div>
        <div className="d-flex flex-md-row flex-column-reverse">
          <div className="col-xl-8 col-md-7 col-lg-8">
            <div className="mt-3 accidents-one">
              {loading
                ? (
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <div className="spinner-border" role="status" />
                    <span>Attendere prego...</span>
                  </div>
                )
                : (
                  <ul className="list-group">
                    {accidents.length > 0
                      ? accidents.map((accident) => (
                        <li key={accident.id} className="accidents-two list-group-item px-2 px-md-3 d-flex flex-sm-row flex-column justify-content-between align-items-start align-items-sm-center">
                          <div className=" me-auto mb-2 mb-sm-0">
                            {accident.quotation.active_customer && (
                              <div className="fw-bold">
                                Cliente:
                                {' '}
                                {accident.quotation.active_customer.name}
                                {' '}
                                {accident.quotation.active_customer.surname}
                              </div>
                            )}
                            {accident.quotation.insurance_request.product
                              && (
                                <>
                                  Prodotto:
                                  {' '}
                                  {accident.quotation.insurance_request.product.name}
                                  {' '}
                                  {accident.quotation.insurance_request.product_id == 5 ? `(${accident.quotation.insurance_request.infortuni && accident.quotation.insurance_request.infortuni.company})` : ''}
                                </>
                              )}
                            {accident.quotation.insurance_request.vehicle
                              && (
                                <>
                                  <br />
                                  Targa:
                                  {' '}
                                  {accident.quotation.insurance_request.vehicle.vehicle_plate}
                                </>
                              )}
                            {accident.quotation.insurance_request.professional
                              && (
                                <>
                                  <br />
                                  Professione:
                                  {' '}
                                  {accident.quotation.insurance_request.professional.profession_desc}
                                </>
                              )}
                            {accident.quotation.insurance_request.infortuni
                              && (
                                <>
                                  <br />
                                  Infortuni:
                                  {' '}
                                  {accident.quotation.insurance_request.infortuni.pack_type}
                                  {' '}
                                  -
                                  {accident.quotation.insurance_request.infortuni.name}
                                </>
                              )}
                            <br />
                            <small>
                              Address:
                              {' '}
                              {accident.address}
                            </small>
                            <br />
                            <small>
                              <strong>
                                Creato:
                                {new Date(accident.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                              </strong>
                            </small>
                          </div>

                          <Link to={`/accidents/${accident.id}`}>
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Vai al sinistro
                            </span>
                          </Link>
                        </li>
                      ))
                      : (
                        <li className="list-group-item d-flex justify-content-between align-items-start accidents-no-result">
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">
                              Nessuno sinistro trovato!
                            </div>
                          </div>
                        </li>
                      )}
                  </ul>
                )}

              <div className="mt-2">
                <Pagination total_pages={total_pages} current_page={page_number} onClick={getAccidents} search={search} />
              </div>
            </div>

          </div>

        </div>
        <div className="col-lg-3 col-md-5 col-xl-3 p-3 quotation-filters accidents-three">
          <form onSubmit={searchHandler} className="d-flex align-items-end justify-content-center flex-column">

            <input type="text" name="search" className="search-input form-control mb-2" value={search.search} onChange={handleChange} placeholder="Ricerca cliente..." />
            <input type="text" name="plate" className="search-plate form-control mb-2" value={search.plate} onChange={handleChange} placeholder="Ricerca targa..." />
            <div className="w-100">
              <label htmlFor="date_from">Data creazione da</label>
              <input type="date" name="date_from" onChange={handleChange} value={search.date_from} className="date-input form-control mb-2" placeholder="Data creazione da" />
            </div>
            <div className="w-100">
              <label htmlFor="date_to">Data creazione al</label>
              <input type="date" name="date_to" className="date-input form-control mb-0" onChange={handleChange} value={search.date_to} min={search.date_from} placeholder="Data creazione al" />
            </div>
            <button type="submit" className="search-btn mt-2">
              Cerca
            </button>
          </form>
        </div>
      </PurchasedQuotationsStyles>
    ) : <ErrorComponent error={error} />;
}

export default AccidentsPage;
