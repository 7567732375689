import * as yup from 'yup';
import moment from 'moment';

export const schemaInfoDrive = yup.object({
  source: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci il source!'),
  product: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci il prodotto!'),
  name: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio. Inserisci il nome!')
        .matches(
          /^[a-zA-Z ]+$/,
          'Non sono ammessi numeri o caratteri speciali!',
        )
      : schema)),
  surname: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio. Inserisci il cognome!')
        .matches(
          /^[a-zA-Z ]+$/,
          'Non sono ammessi numeri o caratteri speciali!',
        )
      : schema)),
  date_of_birth: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio. Seleziona data di nascita!')
        .test('valid-date', 'La data di nascita non è valida', (value) => {
          if (!value) return true;
          const date = moment(value, 'YYYY-MM-DD', true);
          const year = date.year();
          const currentYear = new Date().getFullYear();

          if (year > currentYear - 18 || year < 1925) {
            return false;
          }
          return date.isValid();
        })
      : schema)),
  commune_of_birth_code: yup
    .string()
    .nullable()
    .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
      ? !born_abroad
        ? schema.required(
          'Il campo è obbligatorio. Inserisci luogo di nascita del propietario!',
        )
        : schema
      : schema)),
  gender: yup.string().required('Il campo è obbligatorio. Seleziona genere!'),
  phone: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci numero di telefono!')
    .matches(/^[3]{1}[0-9]{8,9}$/, 'Il numero non e corretto'),
  email: yup.string()
    .matches(/^[a-zA-Z0-9.@]+$/, 'Sono ammessi solo lettere (a-z), numeri (0-9), punti (.) e (@).')
    .required('Il campo è obbligatorio. Inserisci Email!'),
  residence_commune_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona provincia di residenza!'),
  province_of_birth_code: yup
    .string()
    .nullable()
    .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
      ? !born_abroad
        ? schema.required(
          'Il campo è obbligatorio. Inserisci luogo di nascita del propietario!',
        )
        : schema
      : schema)),
  born_abroad: yup
    .boolean()
    .required('Il campo è obbligatorio. Seleziona se nato al estero!'),
  country_of_birth_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona il stato estero!'),
  address: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci indirizzo!'),
  house_number: yup.string().required('Il campo è obbligatorio.'),
  postal_code: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci codice postale!')
    .matches(/^[0-9]{5}$/, 'Il codice postale non e coretto'),
  fiscal_code: yup.string().nullable(),
  residence_province_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona provincia di residenza!'),
  existing_customer: yup.boolean(),
  active_customer_id: yup.number().nullable(),
  policy_effective_date: yup
    .date()
    .typeError('Il campo è obbligatorio. Seleziona data decorrenza!')
    .required('Il campo è obbligatorio. Seleziona data decorrenza!')
    .min(
      new Date(moment().subtract(1, 'day').format('YYYY-MM-DD')),
      'La data è nel passato',
    )
    .max(
      new Date(moment().add(30, 'day').format('YYYY-MM-DD')),
      'La data è nel futuro',
    ),
  business_name: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup
        .string()
        .required("Il campo è obbligatorio. Inserisci il nome dell'azienda!"),
    }),
  vat_number: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup
        .string()
        .matches(/^[0-9]{11}$/, 'Il codice fiscale non è valido')
        .required('Il campo è obbligatorio. Inserisci la tua partita iva!'),
    }),
  company_type: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup.string().required('Il campo è obbligatorio'),
    }),
  vehicle_plate: yup
    .string()
    .required('Il campo è obbligatorio.')
    .matches(/^[A-Z]{2}[0-9]{3}[A-Z]{2} *$/, 'La targa non e coretta'),
  vehicle_brand_code: yup.string().required('Il campo è obbligatorio.'),
  vehicle_model_code: yup.string().required('Il campo è obbligatorio.'),
});

export const infodriveFieldsBySteps = [
  ['vehicle_plate', 'vehicle_brand_code', 'vehicle_model_code'],
  [
    'business_name',
    'vat_number',
    'company_type',
    'name',
    'surname',
    'date_of_birth',
    'gender',
    'phone',
    'email',
    'residence_commune_code',
    'province_of_birth_code',
    'address',
    'postal_code',
    'fiscal_code',
    'country_of_birth_code',
    'residence_province_code',
    'born_abroad',
    'source',
    'product',
    'policy_effective_date',
    'privacies',
  ],
];
