import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Chart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageLoading from '../../../common/components/elements/PageLoading';
import { BACKEND_BASE_URL } from '../../../config';
import {
  RcQuotesStyles,
} from './BackOfficeVehicleQuotes.styles';
import {
  addBackOfficeQuoteToCart,
  loadBackOfficeQuotes,
  removeBackOfficeQuoteFromCart,
  resetBackOfficeQuotes,
} from '../../../features/backofficeResults/backofficeResultsActions';
import { Layout } from '../components/Layout/Layout';
import {
  ConsulenzaReportButton,
  FailedQuoteTitle,
  FailedQuotesContainer,
} from '../../RcQuotes.styles';
import {
  commercialExtraQuoteTypes,
  quotationTypes,
  commercialChartKeys,
} from '../../../common/constants';
import { GuaranteesDefaultStateStyles } from '../../../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { confirmAlert } from '../../../common/utils/alerts';
import {
  RiskCard,
  RiskContainer,
} from '../../../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import CommercialInfoCard from '../../../common/components/redesignComponents/Cards/CommercialInfoCard';
import { EstimateContainer } from '../../../common/components/redesignComponents/common/Redesign.styles';
import SelectedQuotesCardsBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/SelectedQuotesCardsBackoffice';
import ButtonComponentLogicBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/ButtonComponentLogicBackoffice';
import { riskCardStyles } from '../../../common/components/redesignComponents/ResultPageComponents/RcQuotesModal.style';
import ModalQuotesBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/ModalQuotesBackoffice';
import { apiDownloadCommercialReportDocument } from '../../../services/axios-client/axeCommons';

function BackOfficeCommercialQuotes() {
  const location = useLocation();
  const [currentQuotes] = useState('diretta');
  const [counter, setCounter] = useState(180);

  // const navigate = useNavigate();
  const { requestToken } = useParams();
  const state = useSelector(
    (store) => store.backofficeResults.back_office_quotes,
  );
  const {
    loading,
    requestInfo,
    quotes,
    failedQuotes,
    processingQuote,
    addingQuote,
    risk_quotations,
    commercial_company_report,
    companyData,
    sortBy,
  } = state;

  // const modifyData = () => {
  //   navigate(`/backoffice/quote/${requestToken}/tutela-legale`);
  // };

  const [chartData, setChartData] = useState([]);
  const [chartCategories, setChartCategories] = useState([]);

  const [showConsulenzaReport, setShowConsulenzaReport] = useState(false);
  const [showUbicazioneQuotes, setShowUbicazioneQuotes] = useState(false);

  const optionsChart = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        distributed: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    colors: chartData.map((value) => {
      if (value >= 66) return '#FF0000'; // Red
      if (value <= 25) return '#00A651'; // Green
      return '#FFE81A'; // Yellow
    }),
    dataLabels: {
      enabled: true,
      formatter(val) {
        return `${val}%`;
      },
      offsetY: -20,
      style: {
        fontSize: '14px',
        colors: ['#282828'],
      },
    },
    xaxis: {
      categories: chartCategories,
    },
  };

  const series = [
    {
      name: 'Valori',
      data: chartData,
    },
  ];

  useEffect(() => {
    if (commercial_company_report !== null) {
      const newArrData = [];
      const newArrCats = [];

      const keys_to_remove = [
        'id',
        'commercial_company_id',
        'insurance_request_id',
        'broker_agent_id',
        'created_at',
        'updated_at',
        'deleted_at',
      ];

      // object after removing columns
      const newObject = Object.fromEntries(
        Object.entries(commercial_company_report).filter(
          ([key]) => !keys_to_remove.includes(key),
        ),
      );

      Object.entries(newObject).forEach(([key, value]) => {
        // Push category names (keys) to newArrCats

        newArrCats.push(commercialChartKeys.find((k) => k.id === key).name);

        // Push positive values (you can also include negative if you want)
        let val = value.positive - value.negative;

        if (val > 100) {
          val = 100;
        }

        if (val < 0) {
          val = 0;
        }

        newArrData.push(val);
      });

      setChartData(newArrData);
      setChartCategories(newArrCats);
    }
  }, [commercial_company_report]);

  const toggleAutoReportChart = () => {
    setShowConsulenzaReport(!showConsulenzaReport);
  };

  useEffect(() => {
    loadBackOfficeQuotes(requestToken);

    return () => {
      resetBackOfficeQuotes();
    };
  }, []);

  const refresh = () => loadBackOfficeQuotes(requestToken);

  const fetchQuotes = () => {
    loadBackOfficeQuotes(requestToken);
  };

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addBackOfficeQuoteToCart(quoteId, fetchQuotes));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeBackOfficeQuoteFromCart(quoteId, fetchQuotes));
  };

  const exportChartToPDF = async () => {
    const response = await apiDownloadCommercialReportDocument(
      requestInfo.id,
    );
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null && processingQuote.amount !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (risk_quotations && risk_quotations[type.id] !== undefined) {
        for (let i = 0; i < risk_quotations[type.id].length; i++) {
          const item = risk_quotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    return (quoteInProccessAmount + risksTotalAmount).toFixed(2);
  };

  const hasExtraQuotes = quotes && commercialExtraQuoteTypes.some((type) => quotes[type.id]);
  const hasExtraRiskQuotes = risk_quotations
    && commercialExtraQuoteTypes.some((type) => risk_quotations[type.id]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Layout>
      <RcQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-md-2 mb-xxl-0">
              <CommercialInfoCard
                // modifyData={modifyData}
                companyData={companyData}
                policyEffectiveDate={requestInfo.policy_effective_date}
              />
            </div>
            <div className="col-12 col-xxl-8">
              <GuaranteesDefaultStateStyles>
                <div className="container mt-4">
                  {processingQuote === null ? (
                    <h5 className="fw-bold">
                      Seleziona Le Quotazioni Che Vuoi Aquistare
                    </h5>
                  ) : (
                    <>
                      <ul className="list-unstyled">
                        {processingQuote !== null && (
                          <li>
                            <div className="d-flex justify-content-between align-items-center">
                              <h5>
                                {processingQuote.company !== null
                                  ? processingQuote.company.name
                                  : processingQuote.preventivas_company
                                    .company_name}
                                {' '}
                                <sub>
                                  (
                                  {
                                    quotationTypes.find(
                                      (item) => item.id === processingQuote.quote_type,
                                    ).name
                                  }
                                  )
                                </sub>
                              </h5>
                              <h5 className="fw-bold">
                                {parseFloat(
                                  processingQuote.amount
                                    ? processingQuote.amount
                                    : 0,
                                ).toFixed(2)}
                                {' '}
                                €
                              </h5>
                            </div>
                          </li>
                        )}
                        {quotationTypes
                          .filter((t) => t.id !== 'res_civile')
                          .map((type, index) => (
                            <React.Fragment key={index}>
                              {risk_quotations && risk_quotations[type.id]
                                ? risk_quotations[type.id].map((quote, i) => (
                                  <li key={i}>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h5>
                                        {quote.company !== null
                                          ? quote.company.name
                                          : quote.preventivas_company
                                            .company_name}
                                        {' '}
                                        <span className="text-success">
                                          (
                                          {
                                              quotationTypes.find(
                                                (item) => item.id === quote.quote_type,
                                              ).name
                                            }
                                          )
                                        </span>
                                      </h5>
                                      <h5 className="fw-bold">
                                        {parseFloat(quote.amount).toFixed(2)}
                                        {' '}
                                        €
                                      </h5>
                                    </div>
                                  </li>
                                ))
                                : ''}
                            </React.Fragment>
                          ))}

                        {hasExtraRiskQuotes && (
                          <>
                            {commercialExtraQuoteTypes.map((type, index) => (
                              <React.Fragment key={index}>
                                {index === 0 && (
                                  <>
                                    <hr />
                                    <h6 className="fw-semibold my-2">
                                      Garanzie operanti a livello di ubicazione
                                    </h6>
                                    <hr />
                                  </>
                                )}
                                {risk_quotations && risk_quotations[type.id]
                                  ? risk_quotations[type.id].map((quote, i) => (
                                    <li key={i}>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h5>
                                          {quote.company !== null
                                            ? quote.company.name
                                            : quote.preventivas_company
                                              .company_name}
                                          {' '}
                                          <span className="text-success">
                                            (
                                            {
                                                commercialExtraQuoteTypes.find(
                                                  (item) => item.id === quote.quote_type,
                                                ).name
                                              }
                                            )
                                          </span>
                                        </h5>
                                        <h5 className="fw-bold">
                                          {parseFloat(quote.amount).toFixed(
                                            2,
                                          )}
                                          {' '}
                                          €
                                        </h5>
                                      </div>
                                    </li>
                                  ))
                                  : ''}
                              </React.Fragment>
                            ))}
                          </>
                        )}
                        <hr />
                        <li>
                          <div className="d-flex justify-content-end align-items-center">
                            <h5 className="me-5 fw-bold">Totale:</h5>
                            <h5 className="fw-bold">
                              {' '}
                              {getTotal()}
                              {' '}
                              €
                            </h5>
                          </div>
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              </GuaranteesDefaultStateStyles>
            </div>
          </div>

          {/* //todo  check again with data  */}
          {commercial_company_report !== null && (
            <div className="row">
              <div className="col-12">
                <EstimateContainer>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                      <div className="d-flex align-items-center justify-content-start flex-column flex-xxl-row mt-2">
                        <div className="selling-distance mt-2 mt-xxl-0 purchase-quote-third-step">
                          {requestInfo.product_id === '10' && (
                            <>
                              <ConsulenzaReportButton
                                className="btn btn-success m-2"
                                onClick={() => toggleAutoReportChart()}
                              >
                                Conzulenza Report
                              </ConsulenzaReportButton>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {showConsulenzaReport ? (
                    <div className="mt-2">
                      <h4 className="text-center fw-bold mb-0">
                        Analisi Bisogni Cliente
                      </h4>
                      <button
                        className="btn btn-success m-2"
                        onClick={() => exportChartToPDF()}
                      >
                        Scarica PDF
                      </button>
                      <div id="chart-container" style={{ minHeight: '365px' }}>
                        <Chart
                          options={optionsChart}
                          series={series}
                          height="350px"
                          type="bar"
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </EstimateContainer>
              </div>
            </div>
          )}

          <div>
            {location.state != null && location.state.errorMessage != null && (
            <div className="alert alert-danger mt-3 rounded-pill">
              {location.state.errorMessage}
            </div>
            )}
          </div>

          <RiskContainer className="mb-4 ">
            {quotationTypes.map((type, index) => (
              <React.Fragment key={index}>
                {quotes && quotes[type.id] && (
                  <RiskCard
                    className={riskCardStyles(
                      requestInfo,
                      type,
                      risk_quotations,
                    )}
                  >
                    <div className="p-3 me-auto">
                      <h5>{type.name}</h5>
                    </div>
                    <SelectedQuotesCardsBackoffice
                      processingQuote={processingQuote}
                      type={type}
                      refresh={refresh}
                      riskQuotations={risk_quotations}
                      requestInfo={requestInfo}
                    />

                    <ButtonComponentLogicBackoffice
                      type={type}
                      processingQuote={processingQuote}
                      removeItemFromCart={removeItemFromCart}
                      requestInfo={requestInfo}
                      riskQuotations={risk_quotations}
                    />
                  </RiskCard>
                )}
              </React.Fragment>
            ))}
          </RiskContainer>

          {hasExtraQuotes && (
            <>
              <div className="pt-2">
                <hr className="my-3" />
              </div>
              <div className="d-flex align-items-center justify-content-start gap-5">
                <h4 className="text-start fw-bold m-0">
                  Garanzie operanti a livello di ubicazione
                </h4>
                <button
                  className="btn btn-success px-4"
                  onClick={() => setShowUbicazioneQuotes(!showUbicazioneQuotes)}
                >
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <p className="m-0">Apri</p>
                    <FontAwesomeIcon
                      icon={showUbicazioneQuotes ? faChevronUp : faChevronDown}
                    />
                  </div>
                </button>
              </div>
            </>
          )}
          {showUbicazioneQuotes && (
            <RiskContainer className="mb-4 ">
              {commercialExtraQuoteTypes.map((type, index) => (
                <React.Fragment key={index}>
                  {quotes && quotes[type.id] && (
                    <RiskCard
                      className={riskCardStyles(
                        requestInfo,
                        type,
                        risk_quotations,
                      )}
                    >
                      <div className="p-3 me-auto">
                        <h5>{type.name}</h5>
                      </div>
                      <SelectedQuotesCardsBackoffice
                        processingQuote={processingQuote}
                        type={type}
                        refresh={refresh}
                        riskQuotations={risk_quotations}
                        requestInfo={requestInfo}
                      />

                      <ButtonComponentLogicBackoffice
                        type={type}
                        processingQuote={processingQuote}
                        removeItemFromCart={removeItemFromCart}
                        requestInfo={requestInfo}
                        riskQuotations={risk_quotations}
                      />
                    </RiskCard>
                  )}
                </React.Fragment>
              ))}
            </RiskContainer>
          )}
          <div className="my-4">
            {quotationTypes.map((type, index) => (
              <ModalQuotesBackoffice
                processingQuote={processingQuote}
                key={index}
                type={type}
                addingQuote={addingQuote}
                sortBy={sortBy}
                sortQuotes={() => {}}
                handleCurrentQuotesDisplay={() => {}}
                currentQuotes={currentQuotes}
                requestInfo={requestInfo}
                counter={counter}
                quotations={quotes}
                requestToken={requestToken}
                refresh={refresh}
                selectQuotation={selectQuotation}
              />
            ))}
            {commercialExtraQuoteTypes.map((type, index) => (
              <ModalQuotesBackoffice
                processingQuote={processingQuote}
                key={index}
                type={type}
                addingQuote={addingQuote}
                sortBy={sortBy}
                sortQuotes={() => {}}
                handleCurrentQuotesDisplay={() => {}}
                currentQuotes={currentQuotes}
                requestInfo={requestInfo}
                counter={counter}
                quotations={quotes}
                requestToken={requestToken}
                refresh={refresh}
                selectQuotation={selectQuotation}
              />
            ))}
          </div>

          {failedQuotes !== null && failedQuotes?.length > 0 && (
            <div className="row mt-5">
              <FailedQuoteTitle>
                Queste compagnie non hanno risposto con una quotazione.
              </FailedQuoteTitle>
              {failedQuotes.map((quote, index) => (
                <div className="col-3" key={index}>
                  <FailedQuotesContainer>
                    <img
                      src={BACKEND_BASE_URL + quote.company.logo}
                      alt={`${quote.company.name} Logo`}
                    />
                  </FailedQuotesContainer>
                </div>
              ))}
            </div>
          )}
        </div>
      </RcQuotesStyles>
    </Layout>
  );
}

export default BackOfficeCommercialQuotes;
