import * as React from 'react';

export default function InputPrivacy(props) {
  const inputProps = {
    name: props.name,
    id: props.id,
    checked: props.checked,
    onChange: props.onChange,
  };

  return (
    <div className="form-input-container">
      <div className="row">
        <div className="col-12">
          <div className="d-flex">
            <label className="label-privacy">
              <input className="checkbox-privacy" type="checkbox" {...inputProps} />
              {' '}
              {props.label}
              {' '}
              {props.required === 1 ? (
                <span className="text-danger">*</span>
              ) : (
                ''
              )}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
