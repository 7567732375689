import { useEffect, useState } from 'react';
import * as yup from 'yup';

export const validationsSchema = yup.object().shape({
  location_and_structures: yup.object().shape({
    proximity_to_water_bodies: yup.string().nullable(),
    proximity_to_fragile_slopes_or_landslide_areas: yup.string().nullable(),
    elevation_above_ground_level: yup.string().nullable(),
    proximity_to_major_transport_routes: yup.string().nullable(),
    presence_of_groundwater: yup.string().nullable(),
    presence_of_basements_or_semi_basements: yup.string().nullable(),
    presence_of_shelving: yup.string().nullable(),
    non_seismic_roof_configuration: yup.string().nullable(),
    beams_anchored_to_pillars: yup.string().nullable(),
    presence_of_electrical_transformer_rooms: yup.string().nullable(),
    presence_of_thermal_power_station: yup.string().nullable(),
    presence_of_air_compressors: yup.string().nullable(),
    presence_of_refrigeration_units: yup.string().nullable(),
    combustible_building_materials: yup.string().nullable(),
    presence_of_confined_spaces: yup.string().nullable(),
    data_processing_equipment_in_basement: yup.string().nullable(),
    presence_of_classic_sandwich_panels: yup.string().nullable(),
    roof_supporting_structure_material: yup.string().nullable(),
  }),
  infrastructure_and_machines: yup.object().shape({
    sewer_level_above_public_sewers: yup.string().nullable(),
    electrical_system_pre_1990: yup.string().nullable(),
    damage_risk_to_equipment_due_to_power_outage: yup.string().nullable(),
    damage_risk_to_computers_due_to_power_outage: yup.string().nullable(),
    it_system_essential_for_continuity: yup.string().nullable(),
    presence_of_combustible_packaging: yup.string().nullable(),
    presence_of_expanded_plastic_packaging: yup.string().nullable(),
    use_of_flammable_gases: yup.string().nullable(),
    staff_present_during_closure: yup.string().nullable(),
    explosion_risk: yup.string().nullable(),
    firefighter_response_time_under_15_minutes: yup.string().nullable(),
    staff_present_at_night: yup.string().nullable(),
    valuable_goods_storage: yup.string().nullable(),
    use_of_forklifts_or_load_handling_systems: yup.string().nullable(),
    risk_of_exposure_to_carcinogens: yup.string().nullable(),
    use_of_chemicals: yup.string().nullable(),
    presence_of_underground_tanks_with_non_water_substances: yup.string().nullable(),
    machinery_using_oil: yup.string().nullable(),
    concrete_or_resin_flooring: yup.string().nullable(),
    use_or_storage_of_flammable_solvents: yup.string().nullable(),
    use_of_open_flames: yup.string().nullable(),
    goods_sensitive_to_humidity: yup.string().nullable(),
    machinery_sensitive_to_humidity: yup.string().nullable(),
    presence_of_structures_like_chimneys_towers_silos_tanks: yup.string().nullable(),
    use_of_unique_machinery_for_production: yup.string().nullable(),
    use_of_custom_machinery: yup.string().nullable(),
    machinery_no_longer_in_production: yup.string().nullable(),
    possibility_to_modify_process_with_alternative_machinery: yup.string().nullable(),
    machinery_exposed_to_corrosive_environments: yup.string().nullable(),
    machinery_in_dusty_environments: yup.string().nullable(),
    backup_for_critical_machinery: yup.string().nullable(),
    use_of_biting_machines: yup.string().nullable(),
    equipment_or_material_with_long_replacement_time: yup.string().nullable(),
  }),
  security_and_logistics: yup.object().shape({
    contains_easily_resellable_goods: yup.string().nullable(),
    contains_hard_to_identify_goods: yup.string().nullable(),
    frequent_access_by_non_staff: yup.string().nullable(),
    contains_easily_transportable_goods: yup.string().nullable(),
    reported_occupational_illnesses: yup.string().nullable(),
    third_party_access_for_installations_or_services: yup.string().nullable(),
    provides_installations_or_services_at_third_party_locations: yup.string().nullable(),
    stores_third_party_goods: yup.string().nullable(),
    high_value_domestic_transported_goods: yup.string().nullable(),
    high_value_international_transported_goods: yup.string().nullable(),
    ships_perishable_goods: yup.string().nullable(),
    subject_to_hazardous_material_transport_regulations: yup.string().nullable(),
    uses_contract_logistics_providers: yup.string().nullable(),
  }),
  product_and_its: yup.object().shape({
    company_branding_on_products: yup.string().nullable(),
    imports_into_eu: yup.string().nullable(),
    sells_in_us_canada_mexico: yup.string().nullable(),
    perishable_products: yup.string().nullable(),
    products_use_electricity: yup.string().nullable(),
    products_use_combustible_gases: yup.string().nullable(),
    provides_installation_and_maintenance: yup.string().nullable(),
    presence_of_special_goods: yup.string().nullable(),
    process_bottlenecks: yup.string().nullable(),
    vulnerability_to_wetting: yup.string().nullable(),
    easy_to_move_goods_away_from_wet: yup.string().nullable(),
    high_workspace_utilization: yup.string().nullable(),
    operates_in_large_distribution_chain: yup.string().nullable(),
    adequately_stocked_spare_parts_warehouse: yup.string().nullable(),
    critical_business_documents: yup.string().nullable(),
    possibility_of_increasing_work_shifts: yup.string().nullable(),
    disaster_recovery_and_business_continuity_system: yup.string().nullable(),
    remote_access_to_information_system: yup.string().nullable(),
    backup_communication_system: yup.string().nullable(),
    adopted_organizational_models_for_crisis_management: yup.string().nullable(),
  }),
  finance_and_personals: yup.object().shape({
    credit_system_based_on_risk: yup.string().nullable(),
    payment_methods_minimize_exposure: yup.string().nullable(),
    bank_credit_utilization_above_60_percent: yup.string().nullable(),
    seasonal_or_periodic_revenue_fluctuations: yup.string().nullable(),
    inventory_volatility_above_20_percent: yup.string().nullable(),
    subsidiaries_in_eu_or_switzerland: yup.string().nullable(),
    key_personnel_for_strategy: yup.string().nullable(),
    key_personnel_for_production: yup.string().nullable(),
    key_personnel_with_exclusive_client_relationships: yup.string().nullable(),
    locations_or_employees_outside_eu_norway_switzerland: yup.string().nullable(),
    executives_own_company_shares: yup.string().nullable(),
    conflict_of_interest_with_consulting_firms: yup.string().nullable(),
    sponsorship_contracts: yup.string().nullable(),
    defined_organizational_chart_and_governance: yup.string().nullable(),
    public_exposure_of_brand: yup.string().nullable(),
  }),
});

export const useDynamicValidationSchema = (apiData) => {
  const [validationSchema, setValidationSchema] = useState(yup.object().shape(validationsSchema));

  useEffect(() => {
    if (!apiData || !Array.isArray(apiData) || apiData.length === 0) return; // Ensure data exists

    const requiredFields = new Set();

    apiData.forEach((category) => {
      if (category?.questions) {
        category.questions.forEach((question) => {
          if (question?.input_name) {
            requiredFields.add(question.input_name);
          }
        });
      }
    });

    if (!validationsSchema || typeof validationsSchema !== 'object') return; // Ensure validationsSchema is defined

    const schemaShape = Object.fromEntries(
      Object.entries(validationsSchema || {}).map(([category, fields]) => [
        category,
        yup.object(
          Object.fromEntries(
            Object.entries(fields || {}).map(([key]) => [
              key,
              requiredFields.has(`${category}.${key}`)
                ? yup.string().required('Campo obbligatorio')
                : yup.string().nullable(),
            ]),
          ),
        ),
      ]),
    );

    setValidationSchema(yup.object().shape(schemaShape));
  }, [apiData]);

  return validationSchema;
};

export const useValidationSchema = (aiQuestionare) => {
  const flattenedQuestions = aiQuestionare.flatMap((section) => section.questions);

  return yup.object().shape({
    location_and_structures: yup.object().shape({
      proximity_to_water_bodies: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.proximity_to_water_bodies') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      proximity_to_fragile_slopes_or_landslide_areas: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.proximity_to_fragile_slopes_or_landslide_areas') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      elevation_above_ground_level: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.elevation_above_ground_level') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      proximity_to_major_transport_routes: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.proximity_to_major_transport_routes') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_groundwater: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.presence_of_groundwater') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_basements_or_semi_basements: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.presence_of_basements_or_semi_basements') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_shelving: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.presence_of_shelving') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      non_seismic_roof_configuration: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.non_seismic_roof_configuration') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      beams_anchored_to_pillars: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.beams_anchored_to_pillars') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_electrical_transformer_rooms: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.presence_of_electrical_transformer_rooms') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_thermal_power_station: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.presence_of_thermal_power_station') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_air_compressors: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.presence_of_air_compressors') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_refrigeration_units: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.presence_of_refrigeration_units') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      combustible_building_materials: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.combustible_building_materials') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_confined_spaces: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.presence_of_confined_spaces') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      data_processing_equipment_in_basement: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.data_processing_equipment_in_basement') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_classic_sandwich_panels: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.presence_of_classic_sandwich_panels') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      roof_supporting_structure_material: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'location_and_structures.roof_supporting_structure_material') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
    }),
    infrastructure_and_machines: yup.object().shape({
      sewer_level_above_public_sewers: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.sewer_level_above_public_sewers') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      electrical_system_pre_1990: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.electrical_system_pre_1990') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      damage_risk_to_equipment_due_to_power_outage: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.damage_risk_to_equipment_due_to_power_outage') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      damage_risk_to_computers_due_to_power_outage: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.damage_risk_to_computers_due_to_power_outage') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      it_system_essential_for_continuity: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.it_system_essential_for_continuity') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_combustible_packaging: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.presence_of_combustible_packaging') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_expanded_plastic_packaging: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.presence_of_expanded_plastic_packaging') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      use_of_flammable_gases: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.use_of_flammable_gases') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      staff_present_during_closure: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.staff_present_during_closure') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      explosion_risk: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.explosion_risk') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      firefighter_response_time_under_15_minutes: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.firefighter_response_time_under_15_minutes') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      staff_present_at_night: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.staff_present_at_night') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      valuable_goods_storage: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.valuable_goods_storage') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      use_of_forklifts_or_load_handling_systems: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.use_of_forklifts_or_load_handling_systems') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      risk_of_exposure_to_carcinogens: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.risk_of_exposure_to_carcinogens') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      use_of_chemicals: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.use_of_chemicals') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_underground_tanks_with_non_water_substances: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.presence_of_underground_tanks_with_non_water_substances') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      machinery_using_oil: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.machinery_using_oil') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      concrete_or_resin_flooring: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.concrete_or_resin_flooring') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      use_or_storage_of_flammable_solvents: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.use_or_storage_of_flammable_solvents') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      use_of_open_flames: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.use_of_open_flames') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      goods_sensitive_to_humidity: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.goods_sensitive_to_humidity') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      machinery_sensitive_to_humidity: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.machinery_sensitive_to_humidity') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_structures_like_chimneys_towers_silos_tanks: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.presence_of_structures_like_chimneys_towers_silos_tanks') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      use_of_unique_machinery_for_production: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.use_of_unique_machinery_for_production') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      use_of_custom_machinery: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.use_of_custom_machinery') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      machinery_no_longer_in_production: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.machinery_no_longer_in_production') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      possibility_to_modify_process_with_alternative_machinery: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.possibility_to_modify_process_with_alternative_machinery') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      machinery_exposed_to_corrosive_environments: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.machinery_exposed_to_corrosive_environments') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      machinery_in_dusty_environments: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.machinery_in_dusty_environments') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      backup_for_critical_machinery: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.backup_for_critical_machinery') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      use_of_biting_machines: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.use_of_biting_machines') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      equipment_or_material_with_long_replacement_time: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'infrastructure_and_machines.equipment_or_material_with_long_replacement_time') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
    }),
    security_and_logistics: yup.object().shape({
      contains_easily_resellable_goods: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.contains_easily_resellable_goods') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      contains_hard_to_identify_goods: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.contains_hard_to_identify_goods') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      frequent_access_by_non_staff: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.frequent_access_by_non_staff') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      contains_easily_transportable_goods: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.contains_easily_transportable_goods') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      reported_occupational_illnesses: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.reported_occupational_illnesses') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      third_party_access_for_installations_or_services: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.third_party_access_for_installations_or_services') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      provides_installations_or_services_at_third_party_locations: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.provides_installations_or_services_at_third_party_locations') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      stores_third_party_goods: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.stores_third_party_goods') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      high_value_domestic_transported_goods: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.high_value_domestic_transported_goods') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      high_value_international_transported_goods: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.high_value_international_transported_goods') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      ships_perishable_goods: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.ships_perishable_goods') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      subject_to_hazardous_material_transport_regulations: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.subject_to_hazardous_material_transport_regulations') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      uses_contract_logistics_providers: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'security_and_logistics.uses_contract_logistics_providers') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
    }),
    product_and_its: yup.object().shape({
      company_branding_on_products: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.company_branding_on_products') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      imports_into_eu: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.imports_into_eu') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      sells_in_us_canada_mexico: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.sells_in_us_canada_mexico') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      perishable_products: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.perishable_products') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      products_use_electricity: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.products_use_electricity') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      products_use_combustible_gases: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.products_use_combustible_gases') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      provides_installation_and_maintenance: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.provides_installation_and_maintenance') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      presence_of_special_goods: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.presence_of_special_goods') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      process_bottlenecks: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.process_bottlenecks') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      vulnerability_to_wetting: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.vulnerability_to_wetting') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      easy_to_move_goods_away_from_wet: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.easy_to_move_goods_away_from_wet') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      high_workspace_utilization: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.high_workspace_utilization') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      operates_in_large_distribution_chain: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.operates_in_large_distribution_chain') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      adequately_stocked_spare_parts_warehouse: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.adequately_stocked_spare_parts_warehouse') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      critical_business_documents: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.critical_business_documents') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      possibility_of_increasing_work_shifts: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.possibility_of_increasing_work_shifts') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      disaster_recovery_and_business_continuity_system: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.disaster_recovery_and_business_continuity_system') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      remote_access_to_information_system: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.remote_access_to_information_system') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      backup_communication_system: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.backup_communication_system') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      adopted_organizational_models_for_crisis_management: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'product_and_its.adopted_organizational_models_for_crisis_management') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
    }),
    finance_and_personals: yup.object().shape({
      credit_system_based_on_risk: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.credit_system_based_on_risk') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      payment_methods_minimize_exposure: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.payment_methods_minimize_exposure') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      bank_credit_utilization_above_60_percent: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.bank_credit_utilization_above_60_percent') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      seasonal_or_periodic_revenue_fluctuations: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.seasonal_or_periodic_revenue_fluctuations') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      inventory_volatility_above_20_percent: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.inventory_volatility_above_20_percent') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      subsidiaries_in_eu_or_switzerland: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.subsidiaries_in_eu_or_switzerland') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      key_personnel_for_strategy: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.key_personnel_for_strategy') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      key_personnel_for_production: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.key_personnel_for_production') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      key_personnel_with_exclusive_client_relationships: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.key_personnel_with_exclusive_client_relationships') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      locations_or_employees_outside_eu_norway_switzerland: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.locations_or_employees_outside_eu_norway_switzerland') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      executives_own_company_shares: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.executives_own_company_shares') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      conflict_of_interest_with_consulting_firms: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.conflict_of_interest_with_consulting_firms') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      sponsorship_contracts: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.sponsorship_contracts') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      defined_organizational_chart_and_governance: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.defined_organizational_chart_and_governance') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
      public_exposure_of_brand: yup.lazy(() => yup.lazy(() => (flattenedQuestions.find((el) => el.input_name === 'finance_and_personals.public_exposure_of_brand') !== undefined ? yup.string().required('Campo obbligatorio') : yup.string().nullable()))),
    }),
  });
};
