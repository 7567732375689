import React from 'react';
import RiskCardSelected from '../Cards/RiskCardSelected';

function TutelaSelectedProfession({ tutelaLegaleQuote, type, professionId = null }) {
  return (
    <>
      {tutelaLegaleQuote === null ? <div data-bs-toggle="modal" data-bs-target={`#${type.id}`} style={{ cursor: 'pointer' }} className="alert alert-secondary">Seleziona Quotazione!</div> : (
        <div className="col-12 mb-2">
          <RiskCardSelected quote={tutelaLegaleQuote} type={type} professionId={professionId} />
        </div>
      )}
    </>
  );
}

export default TutelaSelectedProfession;
