import {
  faBan, faCarBurst, faEdit, faMailForward, faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Joyride from 'react-joyride';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PostVenditaType } from './SelectPostVenditaAction.styles';
// import { useTourContext } from '../context/useTourContext';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackPostVenditaType } from '../common/utils/joyrideCallbacks';
// import { postVenditaTypeSteps } from '../common/utils/joyrideSteps';

function SelectPostVenditaType() {
  const navigate = useNavigate();

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('afterSaleSelect') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('afterSaleSelect')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('afterSaleSelect');
  //   setState({ run: true, tourActive: true });
  // };

  return (
    <PostVenditaType>
      {/* <Joyride
        steps={postVenditaTypeSteps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,

          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
          overlay: {
            height: '100vh',
          },
        }}
        run={run}
        callback={(data) => handleCallbackPostVenditaType(data, postVenditaTypeSteps, setState)}
        showSkipButton
      />
      {localStorage.getItem('afterSaleSelect') === 'done' && (
        <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireNewQuoteTour()} className="position-fixed end-0 mt-1 me-3">
          <button className="btn btn-success px-2 py-1 rounded-3">Tutorial</button>
        </div>
      )} */}
      <div className="quote-group mt-3 mb-5">
        <div className="text-center"><h2 className="post-vendita-text mt-3 mt-lg-0">SCEGLI POST VENDITA</h2></div>
      </div>
      <div className="actions d-flex align-items-center justify-content-center flex-wrap mb-4 after-sale-select-one">
        <div
          className="option-button"
          onClick={() => navigate('/accidents')}
        >
          <div className="option-button-icon">
            <FontAwesomeIcon icon={faCarBurst} />
          </div>
          <h5 className="option-button-text">Vai a sinistri</h5>
        </div>
        <div
          className="option-button"
          onClick={() => navigate('/replacements')}
        >
          <div className="option-button-icon">
            <FontAwesomeIcon icon={faRotateRight} />
          </div>
          <h5 className="option-button-text"> Vai a sostituzioni</h5>
        </div>
        <div
          className="option-button"
          onClick={() => navigate('/cancellations')}
        >
          <div className="option-button-icon">
            <FontAwesomeIcon icon={faBan} />
          </div>
          <h5 className="option-button-text">Vai a cessazioni</h5>
        </div>
        <div
          className="option-button"
          onClick={() => navigate('/send-documents')}
        >
          <div className="option-button-icon">
            <FontAwesomeIcon icon={faMailForward} />
          </div>
          <h5 className="option-button-text">Vai a documenti inviati</h5>
        </div>
        <div
          className="option-button"
          onClick={() => navigate('/modifications')}
        >
          <div className="option-button-icon">
            <FontAwesomeIcon icon={faEdit} />
          </div>
          <h5 className="option-button-text"> Vai a modifica dati</h5>
        </div>
      </div>

    </PostVenditaType>
  );
}

export default SelectPostVenditaType;
