import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import { AnagraficaBtn, ListActiveCustomersStyles, SearchBtn } from './ListActiveCustomers.styles';
import { getBrokerActiveCustomers, resetActiveCustomers } from '../../features/activeCustomer/activeCustomerActions';
import PageLoading from '../../common/components/elements/PageLoading';
import Pagination from '../../common/components/elements/Pagination';
import StorageService from '../../services/StorageService';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackListActiveCustomer } from '../../common/utils/joyrideCallbacks';
// import { listActiveCustomerSteps } from '../../common/utils/joyrideSteps';

const Storage = new StorageService();

function ListActiveCustomers() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchDataRef = useRef(null);
  const {
    active_customers: {
      loading, activeCustomers, total_pages, page_number, error,
    },
  } = useSelector((store) => store.activeCustomers);
  const [search, setSearch] = useState({
    search: '',
    search_by: 'active_customer',
  });
  const searchClients = (e) => {
    const trimmedSearch = {
      ...search,
      search: search.search.trim(),
    };
    e.preventDefault();
    getBrokerActiveCustomers(1, trimmedSearch.search, trimmedSearch.search_by);
  };
  // const [steps, setSteps] = useState([]);

  const handleSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('clients') === 'done') {
  //     setState({ run: false });
  //   } else if (loading === false) {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 3000);
  //   }
  // }, [localStorage.getItem('clients'), loading]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('clients');
  //   setState({ run: true, tourActive: true });
  // };
  // useEffect(() => {
  //   listActiveCustomerSteps(activeCustomers, setSteps);
  // }, [activeCustomers]);

  const customerClickHandler = (customerRow) => {
    if (customerRow.type === 'active_customer') {
      navigate(`/customers/active-customer/${customerRow.id}/contact`);
    } else {
      navigate(`/customers/customer/${customerRow.id}/contact`);
    }
  };

  useEffect(() => {
    getBrokerActiveCustomers();

    return () => {
      resetActiveCustomers();
    };
  }, []);

  searchDataRef.current = activeCustomers;

  useEffect(() => {
    if (location.state !== undefined && location.state !== null) {
      if (location.state.type === 'command') {
        if (location.state.data.page === 'contact-client') {
          setSearch((prev) => ({
            ...prev,
            search: location.state.data.customer,
          }));
          getBrokerActiveCustomers(1, location.state.data.customer, '');

          setTimeout(() => {
            if (searchDataRef.current?.length !== 0) {
              customerClickHandler(searchDataRef.current[0]);
            }
          }, 2000);
        }
      }
    }

    if (location.state !== null) {
      const ls = location.state;

      if (ls.type === 'redirect') {
        const { data } = ls;
        setSearch(data.search);
        getBrokerActiveCustomers(1, data.search, '');

        setTimeout(() => {
          if (searchDataRef.current?.length !== 0) {
            customerClickHandler(searchDataRef.current.find((c) => c.id === data.activeCustomerId));
          }
        }, 2000);
      }
    }
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return error === null ? (
    <ListActiveCustomersStyles>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackListActiveCustomer(data, steps, setState)}
        showSkipButton
        showProgress
      /> */}
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="mb-0 h3 ">Clienti Diretti</h5>
        <div className="d-flex">
          {Storage.getUser().broker_section !== 'E' && (
            <div>
              <div className="btn btn-success fw-light m-1 clients-one">Clienti Diretti</div>
              <Link to="/clients/rete" className="btn btn-success fw-light m-1 clients-two">Clienti Della Rete</Link>
            </div>
          )}
          {/* {localStorage.getItem('clients') === 'done' && (
            <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()} className="">
              <button className="btn btn-success fw-light m-1 ">Tutorial</button>
            </div>
          )} */}
        </div>
      </div>
      <hr />
      <form
        className="w-100 d-flex flex-column flex-sm-row align-items-center justify-content-start clients-three"
      >
        <input
          type="text"
          name="search"
          value={search.search}
          onChange={handleSearch}
          className="search-input form-control mb-0 me-2 me-sm-2"
          placeholder="cerca con nome, cognome o codice fiscale..."
        />
        <select name="search_by" id="" value={search.search_by} className="form-select mb-0 me-0 me-sm-2 mt-2 mt-sm-0 custom-select-search" onChange={handleSearch}>
          <option value=""> - Tipo di cliente- </option>
          <option value="active_customer">Attivo</option>
          <option value="customer">Non Attivo</option>
        </select>
        <SearchBtn onClick={searchClients} className="mt-2 mt-sm-0">
          Cerca
        </SearchBtn>
        <div className="w-100 d-none d-md-block" />
      </form>
      <div className="mt-5 table-responsive clients-four">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Nome / Ragione Sociale</th>
              <th scope="col">Cognome</th>
              <th scope="col">Codice Fiscale</th>
              <th scope="col">Numero Polizze Emesse</th>
              <th scope="col">Tipo Cliente</th>
              <th scope="col">Azioni</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {activeCustomers.length !== 0
              ? activeCustomers.map((customer, index) => (
                <tr key={index}>
                  <td>{customer.gender !== 'G' ? customer.name : customer.business_name}</td>
                  <td>{customer.surname}</td>
                  <td>{customer.fiscal_code}</td>
                  <td>{customer.quotations_count}</td>
                  <td>{customer.type === 'active_customer' ? 'Attivo' : 'Non Attivo'}</td>
                  <td>
                    <AnagraficaBtn className="clients-five">
                      {customer.type === 'active_customer' ? (
                        <Link to={`/clients/${customer.id}`}>
                          Anagrafica
                        </Link>
                      ) : <Link to={`/clients-not-active/${customer.id}`}>Anagrafica</Link>}
                    </AnagraficaBtn>
                  </td>
                </tr>
              ))
              : (
                <tr>
                  <td colSpan="5">Nessun cliente attivo!</td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <Pagination classNameNav="mt-3" onClick={getBrokerActiveCustomers} total_pages={total_pages} current_page={page_number} search={search.search} search_by={search.search_by} />
    </ListActiveCustomersStyles>
  ) : <ErrorComponent error={error} />;
}

export default ListActiveCustomers;
