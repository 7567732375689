import React, { useEffect, useState } from 'react';
import { questionarioCommercial } from '../questions';
import { FormInputSelect } from '../../../../common/formInputs/FormInputSelect';
import { FormInputRadio } from '../../../../common/formInputs/FormInputRadio';
import {
  flagResponse,
  questionInCommercial,
} from '../../../../common/constants';
import { Accordion } from '../../../../common/components/Accordion';

function StructureEdilizia({
  register, errorMessages, errors, questions,
}) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (
      Object.keys(errors).some((key) => key.includes('location_and_structures'))
    ) {
      setHasError(true);
    }
  }, [errors]);

  return (
    <Accordion
      title={questionarioCommercial[1].title}
      defaultOpen={false}
      hasError={hasError}
      setHasError={setHasError}
    >
      <ul className="list-unstyled">
        {questions.find((el) => el.title === 'Struttura Edilizia')
          !== undefined
          && questions
            .find((el) => el.title === 'Struttura Edilizia')
            .questions?.map((question, index) => (
              <li className="mb-2" key={index}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="mb-0 text-center">{question.question}</p>
                  {question.input_name
                  === 'location_and_structures.roof_supporting_structure_material' ? (
                    <FormInputSelect
                      registration={register(`${question.input_name}`)}
                      placeholder="-Seleziona-"
                      options={questionInCommercial}
                      formGroupClassName="w-50"
                      value=""
                      error={errorMessages(question.input_name)}
                    />
                    ) : (
                      <FormInputRadio
                        options={flagResponse}
                        registration={register(`${question.input_name}`)}
                        error={errorMessages(question.input_name)}
                      />
                    )}
                </div>
              </li>
            ))}
        {/* {questionarioCommercial[1].questions.map((question, index) => (
          <li className='mb-2' key={index}>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <p className='mb-0 text-center'>{question.question}</p>
              {question.input_type === 'select' ? (
                <FormInputSelect
                  registration={register(`${question.input_name}`)}
                  placeholder='-Seleziona-'
                  options={questionInCommercial}
                  formGroupClassName='w-50'
                  value=''
                  error={errorMessages(question.input_name)}
                />
              ) : (
                <FormInputRadio
                  options={flagResponse}
                  registration={register(`${question.input_name}`)}
                  error={errorMessages(question.input_name)}
                />
              )}
            </div>
          </li>
        ))} */}
      </ul>
    </Accordion>
  );
}

export default StructureEdilizia;
