import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FormInputText } from '../../formInputs/FormInputText';
import { FormInputCheckbox } from '../../formInputs/FormInputCheckbox';
import { FastQuoteLoading } from '../../components/elements/FastQuoteLoading';
import { Label } from '../../formInputs/styles/common';
import { FormInputSelect } from '../../formInputs/FormInputSelect';
import {
  km_during_one_year, months, vehiclesAmountInFamily, years,
} from '../../constants';
import FormReactSelectMotoBrand from '../../formInputs/FormReactSelectMotoBrand';
import FormReactSelectMotoModel from '../../formInputs/FormReactSelectMotoModel';
import InputSelectTableMoto from '../../components/inputs/InputTableMoto';
import { FormInputNumber } from '../../formInputs/FormInputNumber';
import getAllowedPurchaseYears from '../../helpers/getAllowedPurchaseYears';

function RcMotoStepOne({
  errors, updateFormData, answers, isValid, checkBoxTypeMoto, isLoadingFastQuote, register, brands, isLoadingVehicleModels, vehicleModels, isLoadingVehicleVersions, vehicleVersions, formData,
}) {
  const [showStepOneFields, setShowStepOneFields] = useState(false);

  return (
    <>
      <div className="form-container">
        <div id="vehiclePlateDiv">
          <FormInputText
            error={errors.moto_plate?.message}
            label="Targa della moto"
            onChange={(value) => updateFormData({ moto_plate: value.toUpperCase() })}
            value={answers.moto_plate}
            valid={isValid('moto_plate')}
          />
        </div>

        {isValid('moto_plate') === true && (
        <div className="d-flex justify-content-center align-items-center gap-2 gap-md-5" ref={checkBoxTypeMoto} id="checkBoxType">

          <FormInputCheckbox
            label=" Rinnovo?"
            checked={answers.renew_check}
            onChange={() => updateFormData({
              renew_check: !answers.renew_check,
              insurance_type: !answers.renew_check ? 'B' : 'N',
              new_risks_check: false,
              complete_manually: false,
            })}
          />
          <FormInputCheckbox
            label=" Nuovo rischio?"
            checked={answers.new_risks_check}
            onChange={() => updateFormData({
              new_risks_check: !answers.new_risks_check,
              renew_check: false,
              insurance_type: '',
              complete_manually: false,
            })}
          />
        </div>
        )}
      </div>

      {isLoadingFastQuote === true && <FastQuoteLoading />}
      {answers.moto_plate !== ''
      && !errors.moto_plate?.message
      && (answers.start_immatricolation !== ''
        || answers.complete_manually === true) && (
        <div className="form-container" id="nextInputsImmatricolationBrand">
          <div className="row mt-2">
            <Label>Data di prima immatricolazione</Label>
            <div className="col-12 col-md-8">
              <FormInputSelect
                registration={register('start_immatricolation_month')}
                error={errors.start_immatricolation_month?.message}
                valid={isValid('start_immatricolation_month')}
                placeholder="-Mese-"
                options={months}
              />
            </div>

            <div className="col-12 col-md-4">
              <FormInputSelect
                registration={register('start_immatricolation')}
                error={errors.start_immatricolation?.message}
                valid={isValid('start_immatricolation')}
                placeholder="-Anno-"
                options={years}
              />
            </div>
          </div>
          <FormReactSelectMotoBrand
            label="Marca"
            values={{
              moto_brand_code: answers.moto_brand_code,
            }}
            error={errors.moto_brand_code?.message}
            valid={isValid('moto_brand_code')}
            onChange={(item) => updateFormData({
              moto_brand_code: item.moto_brand_code,
            })}
            options={brands}
          />
        </div>
      )}

      {answers.moto_brand_code ? (
        <>
          {answers.moto_brand_code && (
          <div className="form-container" id="nextInputModel">
            {isLoadingVehicleModels ? (
              <p className="text-center">Attendere prego...</p>
            ) : (
              <FormReactSelectMotoModel
                label="Modello"
                values={{
                  moto_model_code: answers.moto_model_code,
                }}
                error={errors.moto_model_code?.message}
                valid={isValid('moto_model_code')}
                onChange={(item) => {
                  updateFormData({
                    moto_model_code: item.moto_model_code,
                  });
                }}
                options={vehicleModels}
              />
            )}
          </div>
          )}

          {answers.moto_brand_code && answers.moto_model_code ? (
            isLoadingVehicleVersions ? (
              <p className="text-center">Attendere prego...</p>
            ) : answers.moto_model_code && vehicleVersions.length > 0 ? (
              <div id="versionCode">
                <InputSelectTableMoto
                  TableHeader={(
                    <div className="d-flex">
                      <div className="col-6">Descrizione</div>
                      <div className="col-6">Data Vehicolo</div>
                    </div>
                )}
                  label="Versione"
                  name="moto_version_code"
                  id="moto_version_code"
                  options={vehicleVersions}
                  selected={answers.moto_version_code}
                  onSelectOption={(value) => updateFormData({
                    moto_version_code: value.id,
                    prg_code_moto: value.prg_code_moto,
                  })}
                />
              </div>
            ) : (
              <div className="alert-general-error">
                Nessuna versione trovata per questo modello!
              </div>
            )
          ) : (
            ''
          )}
          {answers.moto_version_code && (
          <div className="form-container" id="restInputsStepOne">
            <hr />
            <div className="text-dark text-center d-flex align-items-center justify-content-center">
              <p
                className="mb-0 fw-bold me-4"
                style={{ fontSize: '15px' }}
              >
                Clica il pulsante per modificare dati precompilati
              </p>
              <button
                className="btn btn-success py-1 px-3"
                onClick={() => setShowStepOneFields(!showStepOneFields)}
              >
                <span className="m-0 me-2">{showStepOneFields ? 'Detagli' : 'Detagli'}</span>
                <FontAwesomeIcon icon={showStepOneFields ? faChevronUp : faChevronDown} />
              </button>
            </div>
            {showStepOneFields && (
            <>
              <FormInputNumber
                registration={register('weight')}
                error={errors.weight?.message}
                valid={isValid('weight')}
                label="Peso dell'moto"
              />
              <FormInputNumber
                registration={register('power')}
                error={errors.power?.message}
                valid={isValid('power')}
                label="Potenza in kW"
              />
              <FormInputSelect
                label="Parcheggio moto"
                registration={register('vehicle_parking')}
                error={errors.vehicle_parking?.message}
                valid={isValid('vehicle_parking')}
                placeholder="-Seleziona-"
                options={formData.parking_types}
              />
              <FormInputSelect
                label="Antifurto"
                registration={register('theft_protection_code')}
                error={errors.theft_protection_code?.message}
                valid={isValid('theft_protection_code')}
                placeholder="-Seleziona-"
                options={formData.theft_protections}
              />
              <FormInputSelect
                label="Utilizzo"
                registration={register('vehicle_usage')}
                error={errors.vehicle_usage?.message}
                valid={isValid('vehicle_usage')}
                placeholder="-Seleziona-"
                options={formData.vehicle_usage_types.filter(
                  (type) => type.vehicle_type === 'auto',
                )}
              />
              <FormInputSelect
                label="Km percorsi in un anno"
                registration={register('predicted_km')}
                error={errors.predicted_km?.message}
                valid={isValid('predicted_km')}
                placeholder="-Seleziona-"
                options={km_during_one_year}
              />
              <FormInputSelect
                label="Auto nel nucleo familiare"
                registration={register('vehicles_owned')}
                error={errors.vehicles_owned?.message}
                valid={isValid('vehicles_owned')}
                placeholder="-Seleziona-"
                options={vehiclesAmountInFamily}
              />
            </>
            )}
            {answers.start_immatricolation !== '' && (
              <div className="row mt-2">
                <Label>Mese e anno in cui hai comprato l'moto</Label>
                <div className="col-12 col-md-8">
                  <FormInputSelect
                    registration={register('vehicle_purchased_month')}
                    error={errors.vehicle_purchased_month?.message}
                    valid={isValid('vehicle_purchased_month')}
                    placeholder="-Mese-"
                    options={months}
                  />
                </div>

                <div className="col-12 col-md-4">
                  <FormInputSelect
                    registration={register('vehicle_purchased_year')}
                    error={errors.vehicle_purchased_year?.message}
                    valid={isValid('vehicle_purchased_year')}
                    placeholder="-Anno-"
                    options={getAllowedPurchaseYears(
                      answers.start_immatricolation,
                    )}
                  />
                </div>
              </div>
            )}

          </div>
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
}

export default RcMotoStepOne;
