import {
  forwardRef, useEffect, useRef, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faRefresh } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { GeneralChatStyled } from '../GeneralChat/GeneralChat.styles';
import { chatAxe } from '../../../../services/axios-client/chatAxeClient';
import { getQuotationTypeName } from '../../../constants';

export const getProductName = (id) => {
  if (id === 1) return 'Auto';
  if (id === 2) return 'Moto';
  if (id === 3) return 'Autocarro';
  if (id === 4) return 'Profession';
  if (id === 8) return 'Travel';
  if (id === 9) return 'Tutela Legale';
  if (id === 10) return 'Commercial';
  if (id === 11) return 'Casa';
  if (id === 12) return 'Salute';
  if (id === 13) return 'Ancillari Auto';

  return null;
};

function MiniChat({
  openChat,
  setOpenChat,
  company_id,
  product_id,
  quote_type,
  company_name,
  extraText = null,
  professionId = null,
}) {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const bottomRef = useRef(null);
  const [thread_id, setThreadId] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  const resetConversation = () => {
    setThreadId(null);
    setMessages(() => {
      const productName = getProductName(product_id);
      const introMessage = {
        id: 'intro-message',
        from: 'system',
        message: `Puoi chiedermi di ${
          getQuotationTypeName(quote_type)
            ? getQuotationTypeName(quote_type).replace('_', ' ')
            : 'questo prodotto'
        } ${
          product_id && company_id
            ? `${productName} dalla compagnia ${company_name}`
            : ''
        }`,
        created_at: new Date().toISOString(),
      };
      return [introMessage];
    });
    setMessage('');
  };

  const sendMessage = async (text) => {
    if (!text.trim()) return;

    setMessages((prev) => prev.filter((msg) => msg.id !== 'intro-message'));

    const newUserMessage = {
      id: Date.now().toString(),
      from: 'user',
      message: text,
      created_at: new Date().toISOString(),
    };
    setMessages((prev) => [...prev, newUserMessage]);
    setMessage('');

    setIsTyping(true);

    try {
      const response = await chatAxe.post('/chat/message/assistant/send', {
        thread_id,
        question: text,
        company_id,
        product_id,
        quote_type:
          product_id == 13
          && getQuotationTypeName(quote_type) === 'Responsabilita Civile'
            ? 'Assistenza'
            : getQuotationTypeName(quote_type),
        product_name: getProductName(product_id),
        company_name,
        extraText,
        profession_id: professionId,
      });

      if (response.status === 200) {
        const last_answer = response.data.data.threadMessages[0].content[0].text.value;
        setThreadId(response.data.data.thread_id);
        const newBotMessage = {
          id: `${Date.now().toString()}-system`,
          from: 'system',
          message: last_answer,
          created_at: new Date().toISOString(),
        };
        setMessages((prev) => [...prev, newBotMessage]);
      }
    } catch (error) {
      const errorMessage = {
        id: `${Date.now().toString()}-error`,
        from: 'system',
        message: 'Something went wrong. Please try again.',
        created_at: new Date().toISOString(),
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useRef(() => {
    scrollToBottom();
  }, [messages]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage(message);
    }
  };

  const getText = () => {
    const productName = getProductName(product_id);
    const quoteTypeName = product_id == 13
      && getQuotationTypeName(quote_type) === 'Responsabilita Civile'
      ? 'Assistenza'
      : getQuotationTypeName(quote_type);
    let generatedMessage = `Puoi chiedermi di ${
      getQuotationTypeName(quote_type)
        ? quoteTypeName.replace('_', ' ')
        : "questo prodotto'"
    } `;

    if (product_id && company_id) {
      generatedMessage += `${productName} dalla compagnia ${company_name}`;
    }

    const initialSystemMessage = {
      id: 'intro-message',
      from: 'system',
      message: generatedMessage,
      created_at: new Date().toISOString(),
    };

    setMessages((prev) => (prev.length === 0 ? [initialSystemMessage] : prev));
  };

  useEffect(() => {
    getText();
  }, [product_id, quote_type, company_id]);

  return (
    <GeneralChatStyled>
      {openChat === true && (
        <div className="chat-modal">
          <div className="backdrop" onClick={() => setOpenChat(false)} />
          <div className="chat-container">
            <div className="chat-title d-flex justify-content-between align-items-center">
              <div className="chat-title-info fw-bold">
                Informazioni
                {' '}
                {product_id == 13
                && getQuotationTypeName(quote_type) === 'Responsabilita Civile'
                  ? 'Assistenza'
                  : getQuotationTypeName(quote_type)}
                {' '}
                {company_name}
              </div>
              <div className="chat-title-actions d-flex align-items-center gap-2">
                {messages.length !== 0 && (
                  <button
                    className="btn btn-sm"
                    onClick={() => resetConversation()}
                    title="Reset conversation"
                    style={{ color: 'white', fontSize: '1.0rem' }}
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-sm"
                  aria-label="Close"
                  onClick={() => setOpenChat(false)}
                  title="Close chat"
                  style={{ color: 'white', fontSize: '1.2rem' }}
                >
                  ✖
                </button>
              </div>
            </div>
            <div className="chat-content">
              {messages.map((m) => (
                <div
                  className={`chat-message chat-message-${m.from}`}
                  key={m.id}
                >
                  <div className="chat-message-time">
                    {moment(m.created_at).fromNow()}
                  </div>
                  <div
                    className="chat-message-message"
                    dangerouslySetInnerHTML={{
                      __html: m?.message.replaceAll('\n', '<br />'),
                    }}
                  />
                </div>
              ))}

              {isTyping && (
                <div className="chat-message chat-message-system">
                  <div className="chat-message-message d-flex align-items-center justify-content-start">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="ms-2">Generando la risposta...</div>
                  </div>
                </div>
              )}

              <div ref={bottomRef} className="bottom-ref" />
            </div>
            <div className="chat-message-sender">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Scrivi la tua domanda..."
                maxLength={500}
                onKeyDown={handleKeyPress}
              />
              <div
                className="chat-message-send-button"
                onClick={() => sendMessage(message)}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </div>
            </div>
          </div>
        </div>
      )}
    </GeneralChatStyled>
  );
}

export const Chat = forwardRef(MiniChat);
