import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef } from 'react';
import { FormInputAddress } from './FormInputAddress';
import { FormInputText } from './FormInputText';
import FormReactSelectResidenceInput from './FormReactSelectResidenceInput';

export function FormInputAddressComplete({
  municipalities,
  formData,
  isValid,
  errors,
  register,
  updateFormData,
  addressId,
  setShow,
  showManualAdress = false,
}) {
  const communesRef = useRef();

  communesRef.current = municipalities;

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then((google) => {
      const autocomplete = new google.maps.places.Autocomplete(
        document.getElementById(addressId),
        {
          fields: ['formatted_address', 'name', 'address_components'],
          types: ['address'],
          componentRestrictions: { country: 'it' },
        },
      );

      const actionTakeOnAutocomplete = async () => {
        const place = autocomplete.getPlace();

        const clearInputs = {
          address: '',
          residence_commune_code: '',
          residence_province_code: '',
          postal_code: '',
        };

        if (place === undefined || place.address_components === undefined) {
          updateFormData(clearInputs);
          return;
        }

        const address = place.address_components.find(
          (c) => c.types.join(',') === 'route',
        ).long_name;
        const commune = communesRef.current.find(
          (m) => m.name
            === place.address_components.find(
              (c) => c.types.join(',') === 'administrative_area_level_3,political',
            )?.long_name,
        );

        if (commune === undefined) {
          updateFormData(clearInputs);
          return;
        }

        const residence_commune_code = commune.cadastral_code;
        const residence_province_code = commune.communal_territory.car_plate_symbol;
        const postal_code = place.address_components.find(
          (c) => c.types.join(',') === 'postal_code',
        )?.long_name ?? commune.postal_code;

        updateFormData({
          address,
          residence_commune_code,
          residence_province_code,
          postal_code,
        });
      };

      autocomplete.addListener('place_changed', actionTakeOnAutocomplete);
      document.getElementById(addressId).onblur = actionTakeOnAutocomplete;
    });

    return () => {
      if (loader) {
        loader.reset();
        delete window.google;
        Loader.instance = undefined;
      }
    };
  }, []);

  return (
    <>
      <FormInputAddress
        valueAddress={formData.address}
        valueHouse={formData.house_number}
        label="Cerca l'indirizzo (scrivi comune se necessario)"
        subText="Se ci sono più strade con lo stesso nome, scrivi nell'indirizzo il comune"
        onAddressChange={(value) => updateFormData({ address: value })}
        onHouseNumberChange={(value) => updateFormData({ house_number: value })}
        addressError={errors.address?.message}
        houseNumberError={errors.house_number?.message}
        validAddress={isValid('address')}
        validHouseNumber={isValid('house_number')}
        addressId={addressId}
      />
      <div className="text-dark">
        <p
          className="mb-3 fw-bold"
          style={{ fontSize: '13px', marginTop: '-10px' }}
        >
          oppure
          {' '}
          <button
            className="fw-bold text-decoration-underline border-0 text-success bg-transparent"
            onClick={() => setShow(true)}
          >
            inserisci l'indirizzo manualmente
          </button>
        </p>
      </div>
      {showManualAdress !== false && formData.residence_commune_code !== ''
        && formData.residence_province_code !== ''
        && formData.postal_code !== '' && (
          <FormReactSelectResidenceInput
            label="Residenza (Dall'indirizzo)"
            options={municipalities}
            values={{
              residence_commune_code: formData.residence_commune_code,
              residence_province_code: formData.residence_province_code,
              postal_code: formData.postal_code,
            }}
            disabled
            valid={isValid('residence_commune_code')}
          >
            <FormInputText
              label="Codice Postale"
              registration={register('postal_code')}
              error={errors.postal_code?.message}
              valid={isValid('postal_code')}
            />
          </FormReactSelectResidenceInput>
      )}
    </>
  );
}
