import moment from 'moment';
import * as yup from 'yup';
import StorageService from '../../services/StorageService';

const Storage = new StorageService();

export const travelSchema = yup.object({
  product: yup.string().required(),
  source: yup.string().required(),
  name: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci il nome!')
    .matches(/^[a-zA-Z ]+$/, 'Non sono ammessi numeri o caratteri speciali!'),
  surname: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci il cognome!')
    .matches(/^[a-zA-Z ]+$/, 'Non sono ammessi numeri o caratteri speciali!'),
  date_of_birth: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona data di nascita!')
    .test('valid-date', 'La data di nascita non è valida', (value) => {
      if (!value) return true;
      const date = moment(value, 'YYYY-MM-DD', true);
      const year = date.year();
      const currentYear = new Date().getFullYear();

      if (year > currentYear - 18 || year < 1925) {
        return false;
      }
      return date.isValid();
    }),
  commune_of_birth_code: yup
    .string()
    .nullable()
    .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
      ? born_abroad === false
        ? schema.required(
          'Il campo è obbligatorio. Inserisci luogo di nascita del contraente!',
        )
        : schema
      : schema)),
  gender: yup.string().required('Il campo è obbligatorio. Seleziona genere!'),
  phone: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci numero di telefono!')
    .matches(/^[3]{1}[0-9]{8,9}$/, 'Il numero non e corretto'),
  email: yup
    .string()
    .matches(/^[a-zA-Z0-9.@]+$/, 'Sono ammessi solo lettere (a-z), numeri (0-9), punti (.) e (@).')
    .required('Il campo è obbligatorio. Inserisci Email!')
    .email('Deve essere un email valido! Rimouvi spazi vuoti!')
    .test('brokerEmail', 'Non puoi impostare la tua email', (value) => value !== Storage.getUser().email),
  residence_commune_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona provincia di residenza!'),
  province_of_birth_code: yup
    .string()
    .nullable()
    .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
      ? born_abroad === false
        ? schema.required(
          'Il campo è obbligatorio. Inserisci luogo di nascita del contraente!',
        )
        : schema
      : schema)),
  born_abroad: yup
    .boolean()
    .required('Il campo è obbligatorio. Seleziona se nato al estero!'),
  country_of_birth_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona il stato estero!'),
  address: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci indirizzo!'),
  house_number: yup.string().required('Il campo è obbligatorio.').matches(/^[1-9]\d*$/, 'Il numero deve essere maggiore di 0.'),
  postal_code: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci codice postale!')
    .matches(/^[0-9]{5}$/, 'Il codice postale non e coretto'),
  fiscal_code: yup.string().nullable(),
  residence_province_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona provincia di residenza!'),
  existing_customer: yup.boolean(),
  active_customer_id: yup.number().nullable(),
  policy_effective_date: yup
    .date()
    .typeError('Il campo è obbligatorio. Seleziona data decorrenza!')
    .required('Il campo è obbligatorio. Seleziona data decorrenza!')
    .min(
      new Date(moment().subtract(1, 'day').format('YYYY-MM-DD')),
      'La data è nel passato',
    )
    .max(
      new Date(moment().add(30, 'day').format('YYYY-MM-DD')),
      'La data è nel futuro',
    ),
  privacies: yup.array(yup.number()),
  insured_persons: yup.array().of(
    yup.object({
      name: yup
        .string()
        .required('Il campo è obbligatorio. Inserisci il nome della persona!')
        .matches(
          /^[a-zA-Z ]+$/,
          'Non sono ammessi numeri o caratteri speciali!',
        ),
      surname: yup
        .string()
        .required('Il campo è obbligatorio. Inserisci il nome della persona!')
        .matches(
          /^[a-zA-Z ]+$/,
          'Non sono ammessi numeri o caratteri speciali!',
        ),
      birth_date: yup
        .string()
        .required('Il campo è obbligatorio. Seleziona data di nascita!'),
    }),
  ),
  total_people: yup
    .number()
    .integer()
    .min(0, 'Non puoi inserire numero negativo!')
    .typeError('Devi inserire solo numeri'),
  is_family: yup.boolean().when('total_people', {
    is: (val) => val >= 2,
    then: () => yup
      .boolean()
      .required(
        'Il campo è obbligatorio. Seleziona se e un nucleo famigliare!',
      ),
    otherwise: () => yup.boolean().nullable(),
  }),
  travel_country_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona dove vuoi viaggiare!'),
  travel_alone: yup
    .number()
    .required()
    .typeError('Seleziona se viaggi da solo o no!'),
  is_contractor_included: yup
    .number()
    .nullable()
    .typeError('Seleziona se il contraente viaggerà o no!'),
});
