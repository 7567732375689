import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { InsuredPersonStyles } from './InsuredPerson.styles';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputDate } from '../../common/formInputs/FormInputDate';

function InsuredPerson({
  isValid, error_name, error_surname, error_date_of_birth, name, surname, birth_date, values, register, remove, index,
}) {
  return (
    <InsuredPersonStyles>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          background: '#57a851',
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px',
          padding: '10px',
        }}
      >
        <p className="mb-0 text-white">Inserisci i dati della persona che viaggia con te</p>
        {index !== 0 ? (
          <div className="btn btn-danger btn-sm" onClick={() => remove(index)}>
            <FontAwesomeIcon icon={faTimes} color="#ffffff" size="xl" />
          </div>
        ) : <div />}
      </div>
      <div className="p-3">
        <FormInputText
          placeholder="Nome"
          label="Nome"
          value={values.name}
          error={error_name}
          registration={register(name)}
          valid={isValid(name, 'name')}
        />
        <FormInputText
          placeholder="Cognome"
          label="Cognome"
          value={values.surname}
          error={error_surname}
          valid={isValid(surname, 'surname')}
          registration={register(surname)}
        />
        <FormInputDate
          label="Data di nascita"
          value={values.birth_date}
          error={error_date_of_birth}
          valid={isValid(birth_date, 'birth_date')}
          registration={register(birth_date)}
        />
      </div>
    </InsuredPersonStyles>
  );
}

export default InsuredPerson;
