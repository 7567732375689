import React from 'react';
import { GeneratingQuestionareLoaderStyles } from './GeneratingQuestionareLoader.styles';

function GeneratingQuestionareLoader() {
  return (
    <GeneratingQuestionareLoaderStyles>
      <div className="loader">
        <p className="heading">Generando Questionario</p>
        <div className="loading">
          <div className="load" />
          <div className="load" />
          <div className="load" />
          <div className="load" />
        </div>
      </div>
    </GeneratingQuestionareLoaderStyles>
  );
}

export default GeneratingQuestionareLoader;
