import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import PageLoading from '../common/components/elements/PageLoading';
import {
  addQuoteCommercialToCart,
  loadCommercialQuotes,
  removeQuoteCommercialFromCart,
  resetCommercialQuotes,
  updateQuotesData,
  updateSortByCommercial,
} from '../features/insuranceRequests/insuranceRequestsActions';
import {
  FailedQuoteTitle,
  FailedQuotesContainer,
  RcQuotesStyles,
} from './RcCommercialQuotes.styles';
import { GuaranteesDefaultStateStyles } from '../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { BACKEND_BASE_URL } from '../config';
import {
  commercialChartKeys,
  commercialExtraQuoteTypes,
  quotationTypes,
} from '../common/constants';
import { confirmAlert, generalAlertError } from '../common/utils/alerts';
import CommercialInfoCard from '../common/components/redesignComponents/Cards/CommercialInfoCard';
import ModalQuotes from '../common/components/redesignComponents/ResultPageComponents/ModalQuotes';
import {
  RiskCard,
  RiskContainer,
} from '../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import SelectedQuotesCards from '../common/components/redesignComponents/ResultPageComponents/SelectedQuotesCards';
import ButtonComponentLogic from '../common/components/redesignComponents/ResultPageComponents/ButtonComponentLogic';
import { riskCardStyles } from '../common/components/redesignComponents/ResultPageComponents/RcQuotesModal.style';
import { EstimateContainer } from '../common/components/redesignComponents/common/Redesign.styles';
import { ConsulenzaReportButton } from './RcQuotes.styles';
import { apiDownloadCommercialReportDocument } from '../services/axios-client/axeCommons';

export default function RcCommercialQuotes() {
  const { requestToken } = useParams();
  const [showConsulenzaReport, setShowConsulenzaReport] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showUbicazioneQuotes, setShowUbicazioneQuotes] = useState(false);
  const state = useSelector(
    (store) => store.insuranceRequests.commercial_quotes,
  );
  const {
    loading,
    companyData,
    processingQuote,
    failedQuotes,
    insuranceRequest,
    addingQuote,
    sortBy,
    quotes,
    commercial_company_report,
    risk_quotations,
  } = state;
  const [chartData, setChartData] = useState([]);
  const [chartCategories, setChartCategories] = useState([]);

  const [counter, setCounter] = useState(180);

  const optionsChart = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        distributed: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    colors: chartData.map((value) => {
      if (value >= 66) return '#FF0000'; // Red
      if (value <= 25) return '#00A651'; // Green
      return '#FFE81A'; // Yellow
    }),
    dataLabels: {
      enabled: true,
      formatter(val) {
        return `${val}%`;
      },
      offsetY: -20,
      style: {
        fontSize: '14px',
        colors: ['#282828'],
      },
    },
    xaxis: {
      categories: chartCategories,
    },
  };

  const series = [
    {
      name: 'Valori',
      data: chartData,
    },
  ];

  const exportChartToPDF = async () => {
    const response = await apiDownloadCommercialReportDocument(
      insuranceRequest.id,
    );
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  useEffect(() => {
    if (commercial_company_report !== null) {
      const newArrData = [];
      const newArrCats = [];

      const keys_to_remove = [
        'id',
        'commercial_company_id',
        'insurance_request_id',
        'broker_agent_id',
        'created_at',
        'updated_at',
        'deleted_at',
      ];

      // object after removing columns
      const newObject = Object.fromEntries(
        Object.entries(commercial_company_report).filter(
          ([key]) => !keys_to_remove.includes(key),
        ),
      );

      Object.entries(newObject).forEach(([key, value]) => {
        // Push category names (keys) to newArrCats

        newArrCats.push(commercialChartKeys.find((k) => k.id === key).name);

        // Push positive values (you can also include negative if you want)
        let val = value.positive - value.negative;

        if (val > 100) {
          val = 100;
        }

        if (val < 0) {
          val = 0;
        }

        newArrData.push(val);
      });

      setChartData(newArrData);
      setChartCategories(newArrCats);
    }
  }, [commercial_company_report]);

  useEffect(() => {
    loadCommercialQuotes(requestToken);
    return () => {
      resetCommercialQuotes();
    };
  }, []);
  const refresh = () => loadCommercialQuotes(requestToken);
  const [currentQuotes, setCurrentQuotes] = useState('diretta');

  const handleCurrentQuotesDisplay = (val) => {
    setCurrentQuotes(val);
  };

  const modifyData = () => {
    navigate('/quote/commercial', {
      state: {
        commercialCompanyVatNumber: companyData.vat_number,
        requestToken,
      },
    });
  };

  const fetchQuotes = () => {
    loadCommercialQuotes(requestToken);
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeQuoteCommercialFromCart(quoteId, fetchQuotes, modifyData));
  };

  const sortQuotes = (sortBy) => {
    updateSortByCommercial(sortBy);
  };

  useEffect(() => {
    if (insuranceRequest !== null && insuranceRequest.quotations) {
      updateQuotesData(insuranceRequest, sortBy, quotes);
    }
  }, [insuranceRequest, sortBy]);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const selectQuotation = (quoteId, closeButton) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteCommercialToCart(quoteId, fetchQuotes, modifyData, closeButton));
  };

  const getTotal = () => {
    let quoteInProcessAmount = 0;
    let risksTotalAmount = 0;

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (risk_quotations && risk_quotations[type.id] !== undefined) {
        for (let i = 0; i < risk_quotations[type.id].length; i++) {
          const item = risk_quotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    for (let x = 0; x < commercialExtraQuoteTypes.length; x++) {
      const type = commercialExtraQuoteTypes[x];
      if (risk_quotations && risk_quotations[type.id] !== undefined) {
        for (let i = 0; i < risk_quotations[type.id].length; i++) {
          const item = risk_quotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    if (processingQuote !== null) {
      quoteInProcessAmount = parseFloat(processingQuote.amount);
    }

    return (quoteInProcessAmount + risksTotalAmount).toFixed(2);
  };

  const handleRequestSave = () => {
    if (processingQuote === null) {
      generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
      return;
    }

    navigate(`/quotes/commercial/purchase/${requestToken}`);
  };

  const toggleAutoReportChart = () => {
    setShowConsulenzaReport(!showConsulenzaReport);
  };

  const hasExtraQuotes = quotes && commercialExtraQuoteTypes.some((type) => quotes[type.id]);
  const hasExtraRiskQuotes = risk_quotations
    && commercialExtraQuoteTypes.some((type) => risk_quotations[type.id]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <RcQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-md-2 mb-xxl-0 purchase-quote-first-step">
              <CommercialInfoCard
                modifyData={modifyData}
                companyData={companyData}
                policyEffectiveDate={insuranceRequest.policy_effective_date}
              />
            </div>
            <div className="col-12 col-xxl-8 purchase-quote-second-step">
              <GuaranteesDefaultStateStyles>
                {processingQuote === null ? (
                  <h5 className="fw-semibold pt-2 ps-lg-2">
                    Seleziona Le Quotazioni Che Vuoi Aquistare
                  </h5>
                ) : (
                  <div className="container mt-4">
                    <ul className="list-unstyled">
                      {processingQuote !== null && (
                        <li>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5>
                              {processingQuote.company !== null
                                && processingQuote.company.name}
                              {' '}
                              <span className="text-success">
                                (
                                {
                                  quotationTypes.find(
                                    (item) => item.id === processingQuote.quote_type,
                                  ).name
                                }
                                )
                              </span>
                            </h5>
                            <h5 className="fw-bold">
                              {parseFloat(
                                processingQuote.amount
                                  ? processingQuote.amount
                                  : 0,
                              ).toFixed(2)}
                              {' '}
                              €
                            </h5>
                          </div>
                        </li>
                      )}
                      {quotationTypes
                        .filter((t) => t.id !== 'res_civile')
                        .map((type, index) => (
                          <React.Fragment key={index}>
                            {risk_quotations && risk_quotations[type.id]
                              ? risk_quotations[type.id].map((quote, i) => (
                                <li key={i}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h5>
                                      {quote.company !== null
                                        ? quote.company.name
                                        : quote.preventivas_company
                                          .company_name}
                                      {' '}
                                      <span className="text-success">
                                        (
                                        {
                                            quotationTypes.find(
                                              (item) => item.id === quote.quote_type,
                                            ).name
                                          }
                                        )
                                      </span>
                                    </h5>
                                    <h5 className="fw-bold">
                                      {parseFloat(quote.amount).toFixed(2)}
                                      {' '}
                                      €
                                    </h5>
                                  </div>
                                </li>
                              ))
                              : ''}
                          </React.Fragment>
                        ))}

                      {hasExtraRiskQuotes && (
                        <>
                          {commercialExtraQuoteTypes.map((type, index) => (
                            <React.Fragment key={index}>
                              {index === 0 && (
                                <>
                                  <hr />
                                  <h6 className="fw-semibold my-2">
                                    Garanzie operanti a livello di ubicazione
                                  </h6>
                                  <hr />
                                </>
                              )}
                              {risk_quotations && risk_quotations[type.id]
                                ? risk_quotations[type.id].map((quote, i) => (
                                  <li key={i}>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h5>
                                        {quote.company !== null
                                          ? quote.company.name
                                          : quote.preventivas_company
                                            .company_name}
                                        {' '}
                                        <span className="text-success">
                                          (
                                          {
                                              commercialExtraQuoteTypes.find(
                                                (item) => item.id === quote.quote_type,
                                              ).name
                                            }
                                          )
                                        </span>
                                      </h5>
                                      <h5 className="fw-bold">
                                        {parseFloat(quote.amount).toFixed(2)}
                                        {' '}
                                        €
                                      </h5>
                                    </div>
                                  </li>
                                ))
                                : ''}
                            </React.Fragment>
                          ))}
                        </>
                      )}

                      <hr />
                      <li>
                        <div className="d-flex justify-content-end align-items-center">
                          <h5 className="me-5 fw-bold">Totale:</h5>
                          <h5 className="fw-bold">
                            {' '}
                            {getTotal()}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                    </ul>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        className="btn btn-success btn-lg rounded-4 px-5 fw-bold"
                        onClick={() => handleRequestSave()}
                      >
                        Procedi
                      </button>
                    </div>
                  </div>
                )}
              </GuaranteesDefaultStateStyles>
            </div>
          </div>

          {commercial_company_report !== null && (
            <div className="row">
              <div className="col-12">
                <EstimateContainer>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                      <div className="d-flex align-items-center justify-content-start flex-column flex-xxl-row mt-2">
                        <div className="selling-distance mt-2 mt-xxl-0 purchase-quote-third-step">
                          {insuranceRequest.product_id === '10' && (
                            <>
                              <ConsulenzaReportButton
                                className="btn btn-success m-2"
                                onClick={() => toggleAutoReportChart()}
                              >
                                Conzulenza Report
                              </ConsulenzaReportButton>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {showConsulenzaReport ? (
                    <div className="mt-2">
                      <h4 className="text-center fw-bold mb-0">
                        Analisi Bisogni Cliente
                      </h4>
                      <button
                        className="btn btn-success m-2"
                        onClick={() => exportChartToPDF()}
                      >
                        Scarica PDF
                      </button>
                      <div id="chart-container" style={{ minHeight: '365px' }}>
                        <Chart
                          options={optionsChart}
                          series={series}
                          height="350px"
                          type="bar"
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </EstimateContainer>
              </div>
            </div>
          )}
          <div>
            {location.state != null && location.state.errorMessage != null && (
              <div className="alert alert-danger mt-3 rounded-pill">
                {location.state.errorMessage}
              </div>
            )}
          </div>
          <RiskContainer className="mb-4 ">
            {quotationTypes.map((type, index) => (
              <React.Fragment key={index}>
                {quotes && quotes[type.id] && (
                  <RiskCard
                    className={riskCardStyles(
                      insuranceRequest,
                      type,
                      risk_quotations,
                    )}
                  >
                    <div className="p-3 me-auto">
                      <h5>{type.name}</h5>
                    </div>
                    <SelectedQuotesCards
                      processingQuote={processingQuote}
                      type={type}
                      refresh={refresh}
                      riskQuotations={risk_quotations}
                      requestInfo={insuranceRequest}
                    />

                    <ButtonComponentLogic
                      type={type}
                      processingQuote={processingQuote}
                      removeItemFromCart={removeItemFromCart}
                      requestInfo={insuranceRequest}
                      riskQuotations={risk_quotations}
                    />
                  </RiskCard>
                )}
              </React.Fragment>
            ))}
          </RiskContainer>

          {hasExtraQuotes && (
            <>
              <div className="pt-2">
                <hr className="my-3" />
              </div>
              <div className="d-flex align-items-center justify-content-start gap-5">
                <h4 className="text-start fw-bold m-0">
                  Garanzie operanti a livello di ubicazione
                </h4>
                <button
                  className="btn btn-success px-4"
                  onClick={() => setShowUbicazioneQuotes(!showUbicazioneQuotes)}
                >
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <p className="m-0">Apri</p>
                    <FontAwesomeIcon
                      icon={showUbicazioneQuotes ? faChevronUp : faChevronDown}
                    />
                  </div>
                </button>
              </div>
            </>
          )}
          {showUbicazioneQuotes && (
            <RiskContainer className="mb-4 ">
              {commercialExtraQuoteTypes.map((type, index) => (
                <React.Fragment key={index}>
                  {quotes && quotes[type.id] && (
                    <RiskCard
                      className={riskCardStyles(
                        insuranceRequest,
                        type,
                        risk_quotations,
                      )}
                    >
                      <div className="p-3 me-auto">
                        <h5>{type.name}</h5>
                      </div>
                      <SelectedQuotesCards
                        processingQuote={processingQuote}
                        type={type}
                        refresh={refresh}
                        riskQuotations={risk_quotations}
                        requestInfo={insuranceRequest}
                      />

                      <ButtonComponentLogic
                        type={type}
                        processingQuote={processingQuote}
                        removeItemFromCart={removeItemFromCart}
                        requestInfo={insuranceRequest}
                        riskQuotations={risk_quotations}
                      />
                    </RiskCard>
                  )}
                </React.Fragment>
              ))}
            </RiskContainer>
          )}
          <div className="my-4">
            {quotationTypes.map((type, index) => (
              <ModalQuotes
                processingQuote={processingQuote}
                key={index}
                type={type}
                addingQuote={addingQuote}
                sortBy={sortBy}
                sortQuotes={sortQuotes}
                handleCurrentQuotesDisplay={handleCurrentQuotesDisplay}
                currentQuotes={currentQuotes}
                requestInfo={insuranceRequest}
                counter={counter}
                quotations={quotes}
                requestToken={requestToken}
                refresh={refresh}
                selectQuotation={selectQuotation}
              />
            ))}
            {commercialExtraQuoteTypes.map((type, index) => (
              <ModalQuotes
                processingQuote={processingQuote}
                key={index}
                type={type}
                addingQuote={addingQuote}
                sortBy={sortBy}
                sortQuotes={sortQuotes}
                handleCurrentQuotesDisplay={handleCurrentQuotesDisplay}
                currentQuotes={currentQuotes}
                requestInfo={insuranceRequest}
                counter={counter}
                quotations={quotes}
                requestToken={requestToken}
                refresh={refresh}
                selectQuotation={selectQuotation}
              />
            ))}
          </div>
          {failedQuotes !== null && failedQuotes.length > 0 && (
            <div className="row mt-5">
              <FailedQuoteTitle>
                Queste compagnie non hanno risposto con una quotazione.
              </FailedQuoteTitle>
              {failedQuotes.map((quote, index) => (
                <div className="col-3" key={index}>
                  <FailedQuotesContainer>
                    <img
                      src={BACKEND_BASE_URL + quote.company.logo}
                      alt={`${quote.company.name} Logo`}
                    />
                  </FailedQuotesContainer>
                  <h5>{quote.company.name}</h5>
                  <small>{quote.message}</small>
                </div>
              ))}
            </div>
          )}
        </div>
      </RcQuotesStyles>
    </>
  );
}
