import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FormInputSelect } from '../../formInputs/FormInputSelect';
import {
  flagResponse, inheritMeritClass, insuranceTypes, lastSixYears,
  meritClass,
  sinceFirstInsuranceYears,
  violationsResponsability,
} from '../../constants';
import { FormInputDate } from '../../formInputs/FormInputDate';
import getAllowedPolicyEffectDate from '../../helpers/getAllowedPolicyEffectDate';
import { FormInputRadio } from '../../formInputs/FormInputRadio';
import { FormInputText } from '../../formInputs/FormInputText';
import { calculateEmptyViolationsData } from '../../components/inputs/InputViolations';
import BersaniInfo from '../../components/elements/BersaniInfo';
import ViolationsTable from '../common/ViolationsTable';
import { updateViolationsFormData } from '../common/violationsCalculation';

function RcVanStepThree({
  answers, register, isValid, errors, updateFormData, bersaniComponentRef, bersani, updateBersaniData, municipalities, typeGuide,
}) {
  const [newViolation, setNewViolation] = useState({
    year: '',
    type: '',
    violations: '',
  });
  const [openSinistri, setOpenSinistri] = useState(false);
  const [showStepThreeFields, setShowStepThreeFields] = useState(false);

  const handleNewViolationData = (name, value) => {
    setNewViolation({
      ...newViolation,
      [name]: value,
    });
  };

  useEffect(() => {
    if (answers.violations_number > 0) {
      updateFormData({
        violations: 1,
      });
    }
  }, []);

  useEffect(() => {
    if (answers.insurance_type === 'B') {
      updateFormData({
        inherit_merit_class: '',
      });
    }
  }, []);

  return (
    <>
      <div className="form-container" id="datiDelVeicolo">
        {answers.renew_check && (
          <FormInputSelect
            label="Seleziona il tipo di assicurazione."
            registration={register('insurance_type')}
            error={errors.insurance_type?.message}
            valid={isValid('insurance_type')}
            placeholder="-Seleziona-"
            options={insuranceTypes}
            disabled
          />
        )}
        {!answers.renew_check && (
          <FormInputSelect
            label="Seleziona il tipo di assicurazione."
            registration={register('insurance_type')}
            error={errors.insurance_type?.message}
            valid={isValid('insurance_type')}
            placeholder="-Seleziona-"
            options={insuranceTypes}
          />
        )}

        <FormInputDate
          minDate={getAllowedPolicyEffectDate('min')}
          maxDate={getAllowedPolicyEffectDate('max')}
          registration={register('policy_effective_date')}
          error={errors.policy_effective_date?.message}
          valid={isValid('policy_effective_date')}
          label={
            answers.insurance_type === 'N'
              ? 'Data di inizio della copertura della polizza'
              : 'Data di scadenza della tua attuale polizza'
          }
          paragraph="Date valide: da oggi a 30 giorni da oggi"
        />

        <hr />
        <div className="text-dark form-container text-center d-flex align-items-center justify-content-center">
          <p
            className="mb-0 fw-bold me-4"
            style={{ fontSize: '15px' }}
          >
            Clica il pulsante per modificare dati precompilati
          </p>
          <button
            className="btn btn-success py-1 px-3"
            onClick={() => setShowStepThreeFields(!showStepThreeFields)}
          >
            <span className="m-0 me-2">{showStepThreeFields ? 'Detagli' : 'Apri Detagli'}</span>
            <FontAwesomeIcon icon={showStepThreeFields ? faChevronUp : faChevronDown} />
          </button>
        </div>
        {showStepThreeFields && (
          <>
            <FormInputRadio
              label="Ci sono altri conducenti?"
              options={flagResponse}
              name="other_drivers"
              registration={register('other_drivers')}
              error={errors.other_drivers?.message}
            />
            {answers.gender !== 'G'
          && (
            <>
              {answers.other_drivers === '1' && (
                <FormInputText
                  placeholder="(18 - 100)"
                  type="number"
                  min={18}
                  max={100}
                  label="Età del conducente più giovane"
                  registration={register('youngest_age_driver')}
                  error={errors.youngest_age_driver?.message}
                  valid={isValid('youngest_age_driver')}
                />
              )}
              <FormInputText
                placeholder="(18 - 100)"
                type="number"
                min={18}
                max={100}
                label="Età del membro più giovane della famiglia con la patente di guida"
                registration={register('youngest_age_family_member')}
                error={errors.youngest_age_family_member?.message}
                valid={isValid('youngest_age_family_member')}
              />
            </>
          )}
            {answers.insurance_type !== 'N'
          && (
            <>
              <FormInputRadio
                disabled={answers.violations_number !== 0 && answers.renew_check === true}
                label="Hai fatto sinistri negli ultimi 6 anni?"
                name="violations"
                placeholder="-Seleziona-"
                options={flagResponse}
                onChange={(value) => {
                  const update_data = {
                    violations: value,
                  };

                  if (value === 0) {
                    update_data.violations_number = 0;
                    update_data.violations_data = calculateEmptyViolationsData();
                  }

                  updateFormData(update_data);
                }}
                value={answers.violations}
              />
            </>
          )}
          </>
        )}
        {answers.insurance_type !== 'N'
       && (
       <FormInputSelect
         label="Prima assicurazione in 6 anni"
         registration={register('first_insuranced_year')}
         error={errors.first_insuranced_year?.message}
         valid={isValid('first_insuranced_year')}
         placeholder="-Seleziona-"
         options={lastSixYears}
       />
       )}
      </div>
      {showStepThreeFields && (
      <>
        {(answers.violations === 1 && answers.first_insuranced_year !== '' && answers.insurance_type !== 'N') && (
        <>
          {!openSinistri && <button className="btn btn-success mx-auto d-table" onClick={() => setOpenSinistri(!openSinistri)}>Aggiungi Sinistro</button>}

          {openSinistri && (
          <div className="card card-body border border-1 mt-2 bg-light">
            {openSinistri && <button className="btn btn-danger ms-auto d-table mb-2" onClick={() => setOpenSinistri(!openSinistri)}>Chiudi</button>}
            <FormInputSelect
              label="Anno Sinistro"
              onChange={(val) => {
                handleNewViolationData('year', val);
              }}
              placeholder="-Seleziona-"
              options={sinceFirstInsuranceYears(answers.first_insuranced_year)}
              value={newViolation.year}
            />
            <FormInputSelect
              label="Responsabilita"
              onChange={(val) => {
                handleNewViolationData('type', val);
              }}
              placeholder="-Seleziona-"
              options={violationsResponsability}
              value={newViolation.type}
            />
            <FormInputText
              type="number"
              onChange={(val) => {
                handleNewViolationData('violations', val);
              }}
              label="Numero Sinistri"
              value={newViolation.violations}
              placeholder="Inserisci il numero di sinistri"
            />
            <button className="btn btn-success" type="button" onClick={() => updateViolationsFormData(answers, newViolation, updateFormData, setNewViolation)}>Aggiungi</button>
          </div>
          )}

          <ViolationsTable violationData={answers.violations_data} />
        </>
        )}
      </>
      )}

      <div className="form-container">
        {answers.insurance_type === 'N' && (
          <FormInputSelect
            label="Puoi utilizzare la classe di merito di un altro veicolo già assicurato?"
            registration={register('inherit_merit_class')}
            error={errors.inherit_merit_class?.message}
            valid={isValid('inherit_merit_class')}
            placeholder="-Seleziona-"
            options={inheritMeritClass}
          />
        )}

        {((answers.inherit_merit_class == 'A'
          && answers.insurance_type === 'N') || answers.inherit_merit_class == 'S') && (
            <BersaniInfo
              ref={bersaniComponentRef}
              inheritMeritClass={answers.inherit_merit_class}
              bersani={bersani}
              updateBersaniData={updateBersaniData}
              municipalities={municipalities}
            />
        )}

        <FormInputSelect
          label={
            answers.inherit_merit_class == 'S'
              || (answers.inherit_merit_class == 'A'
                && answers.insurance_type === 'N')
              ? 'Classe di merito del veicolo già  assicurato'
              : 'Classe di merito.'
          }
          registration={register('merit_class')}
          error={errors.merit_class?.message}
          valid={isValid('merit_class')}
          placeholder="-Seleziona-"
          options={meritClass}
          disabled={answers.insurance_type === 'N' && answers.inherit_merit_class === 'N'}
        />

        {showStepThreeFields && (
          <>
            <FormInputRadio
              label="Il conducente dichiara: di essere in possesso di patente italiana mai sospesa da 5 anni e con almeno 20 punti, di non aver mai ricevuto sanzioni per ubriachezza, di non aver modificato il veicolo."
              options={flagResponse}
              name="mofified_vehicle_and_valid_driving_license"
              onChange={(value) => updateFormData({
                mofified_vehicle: value,
                valid_driving_license: value,
              })}
              value={answers.mofified_vehicle}
              error={errors.mofified_vehicle?.message}
            />

          </>
        )}
        <FormInputSelect
          label="Tipo di guida"
          registration={register('guide_type')}
          error={errors.guide_type?.message}
          valid={isValid('guide_type')}
          placeholder="-Seleziona-"
          options={typeGuide}
        />
      </div>
    </>
  );
}

export default RcVanStepThree;
