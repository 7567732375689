import React from 'react';

function RcAutoFastQuoteModal({
  cancelFastquote,
  confirmFastQuote,
  answers,
  fastQuoteVehicleInfo,

}) {
  return (
    <div className="x-modal" id="fastquote-modal">
      <div className="x-modal-cover" onClick={cancelFastquote} />
      <div className="general-modal-content">
        <span
          className="general-modal-close"
          title="Quidi"
          onClick={cancelFastquote}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
          </svg>
        </span>
        <div className="modal-header">
          <h5 className="modal-title">Informazioni sul veicolo da Ania</h5>
          <button
            type="button"
            className="btn btn-primary my-2 mx-5"
            onClick={confirmFastQuote}
          >
            Conferma
          </button>
        </div>
        <div className="x-modal-body">
          {answers.renew_check === true && (
            <>
              {fastQuoteVehicleInfo.info.persona_giuridica === true && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nome dell'azienda</label>
                      <input
                        className="form-control"
                        value={fastQuoteVehicleInfo.info.nome_commerciale}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Partita Iva</label>
                      <input
                        className="form-control"
                        value={fastQuoteVehicleInfo.info.partita_iva}
                      />
                    </div>
                  </div>
                </div>
              )}

              {fastQuoteVehicleInfo.info.persona_giuridica !== true && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nome</label>
                      <input
                        className="form-control"
                        value={fastQuoteVehicleInfo.info.nome}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Congnome</label>
                      <input
                        className="form-control"
                        value={fastQuoteVehicleInfo.info.cognome}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Data di decorrenza</label>
                    <input
                      className="form-control"
                      value={fastQuoteVehicleInfo.info.data_di_decorrenza}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Classe di merito</label>
                    <input
                      className="form-control"
                      value={parseInt(
                        fastQuoteVehicleInfo.info.classe_di_merito,
                      ).toString()}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Comune di Residenza</label>
                    <input
                      className="form-control"
                      value={fastQuoteVehicleInfo.info.comune_di_residenza}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Provincia Residenza</label>
                    <input
                      className="form-control"
                      value={fastQuoteVehicleInfo.info.provincia_residenza}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Marca</label>
                <input
                  className="form-control"
                  value={fastQuoteVehicleInfo.frontend.vehicle_brand_code !== '' ? fastQuoteVehicleInfo.info.modello : ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Modello</label>
                <input
                  className="form-control"
                  value={fastQuoteVehicleInfo.frontend.vehicle_model_code !== '' ? fastQuoteVehicleInfo.info.marcha : ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>kW</label>
                <input
                  className="form-control"
                  value={fastQuoteVehicleInfo.info.power}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Massa</label>
                <input
                  className="form-control"
                  value={fastQuoteVehicleInfo.info.weight}
                />
              </div>
            </div>
          </div>
          <table className="custom-table">
            <thead>
              <th>Tipo Sinistro</th>
              {Object.keys(fastQuoteVehicleInfo.info.sinistri[0])
                .filter((v) => v !== 'TIPO SINISTRO')
                .sort()
                .map((v) => (
                  <th>{v}</th>
                ))}
            </thead>
            <tbody>
              {fastQuoteVehicleInfo.info.sinistri.map((s) => (
                <tr className="custom-table-row">
                  <td>{s['TIPO SINISTRO']}</td>
                  {Object.keys(fastQuoteVehicleInfo.info.sinistri[0])
                    .filter((v) => v !== 'TIPO SINISTRO')
                    .sort()
                    .map((v) => (
                      <td>{s[v]}</td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary m-2"
            onClick={cancelFastquote}
          >
            Chiudi
          </button>
        </div>
      </div>
    </div>
  );
}

export default RcAutoFastQuoteModal;
