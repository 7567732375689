import { generalAlertError } from '../../common/utils/alerts';
import quotationsAxe from '../../services/axios-client/quotationsAxeClient';
import caller from './commercialReportSlice';

const serverError = 'Si è verificato un problema con il server';

export const getCommercialReport = async (vat_number, navigate) => {
  caller.getCommercialReportPending();

  try {
    const response = await quotationsAxe.get(
      `/commercial/report/${vat_number}/complete`,
    );

    if (response.status === 400 && response.data.code === 'limit_request_reached') {
      caller.getCommercialReportRejected('Limite di richieste raggiunto');
      navigate('/quote/commercial');
      generalAlertError("Hai finito i check up gratuiti, contatta l'amministrazione per riprendere");
      return;
    }

    if (response.status !== 200) {
      caller.getCommercialReportRejected(serverError);
      return;
    }
    caller.getCommercialReportSuccess(response.data.data);
  } catch (error) {
    caller.getCommercialReportRejected(serverError);
  }
};

export const commercialReportReset = () => {
  caller.commercialReportReset();
};

export const saveQuestionario = async (company_id, data, navigate) => {
  caller.getQuestionarioPending();
  caller.setGeneratingQuotes(true);

  try {
    data.location_and_structures.roof_supporting_structure_material = data.location_and_structures.roof_supporting_structure_material === '' ? null : data.location_and_structures.roof_supporting_structure_material;
    data.product_and_its.vulnerability_to_wetting = data.product_and_its.vulnerability_to_wetting === '' ? null : data.product_and_its.vulnerability_to_wetting;
    data.product_and_its.high_workspace_utilization = data.product_and_its.high_workspace_utilization === '' ? null : data.product_and_its.high_workspace_utilization;

    delete data.fields;
    delete data.internalTests;
    delete data.spec;
    delete data.type;
    delete data._nodes;

    console.log('DATA: ', data);
    const response = await quotationsAxe.post(`/commercial/questionario/${company_id}`, data);

    if (response.status !== 200) {
      caller.getQuestionarioRejected(serverError);
      caller.setGeneratingQuotes(false);
      const errorMessage = response.data.message ? response.data.message : null;
      if (errorMessage) {
        generalAlertError(errorMessage);
      }
      return;
    }
    caller.setGeneratingQuotes(false);
    caller.getQuestionarioSuccess();
    const request_token = response.data.request_token;
    navigate(`/quotes/commercial/${request_token}`);
  } catch (error) {
    caller.getCommercialReportRejected(serverError);
  }
};

export const skipQuestionario = async (company_id, hasQuestionario, old_request_token = null, extraData = null) => {
  caller.getCompanyQuestionarioStatusPending();
  if (hasQuestionario === 0) {
    caller.setGeneratingQuotes(true);
  }

  try {
    const data = { has_questionario: hasQuestionario, request_token: old_request_token, extra_data: extraData };
    const response = await quotationsAxe.post(`/commercial/questionario/${company_id}/status`, data);

    if (response.status !== 200) {
      caller.getCompanyQuestionarioStatusRejected(serverError);
      caller.setGeneratingQuotes(false);

      const errorMessage = response.data.message ? response.data.message : null;
      if (errorMessage) {
        generalAlertError(errorMessage);
      }

      return;
    }
    if (hasQuestionario === 0) {
      caller.setGeneratingQuotes(false);
    }
    if (old_request_token !== null) {
      caller.resetQuestionnaireOldRequestToken();
    }
    const request_token = response.data.request_token;
    const errorMessage = response.data.show_message && response.data.show_message == true ? response.data.message : null;
    caller.getCompanyQuestionarioCommercialRequestTokenSuccess({
      request_token,
      errorMessage,
    });

    if (response.data.modify_data) {
      const modify_data = response.data.modify_data;
      caller.updateQuestionnaireModifyData(modify_data);
    }
  } catch (error) {
    caller.getCommercialReportRejected(serverError);
  }
};

export const getQuestionarioStatus = async (companyId) => {
  caller.getCompanyQuestionarioStatusPending();
  try {
    const response = await quotationsAxe.get(`/commercial/questionario/${companyId}/get-questionario-status`);

    if (response.status !== 200) {
      caller.getCompanyQuestionarioStatusRejected('Errore Interno');
      return;
    }

    caller.getCompanyQuestionarioStatusSuccess(response.data.data);
  } catch (error) {
    caller.getCompanyQuestionarioStatusRejected('Errore interno');
  }
};

export const resetQuestionnaireRequestToken = () => {
  caller.resetQuestionnaireRequestToken();
};

export const updateCommercialReport = async (commercialCompanyVatNumber, formData, navigate) => {
  caller.updateCommercialReportPending();
  try {
    const response = await quotationsAxe.post(`/commercial/report/${commercialCompanyVatNumber}/update`, formData);

    if (response.status !== 200) {
      caller.updateCommercialReportRejected('Errore Interno');
      return;
    }

    caller.updateCommercialReportSuccess();
    navigate();
  } catch (error) {
    caller.updateCommercialReportRejected('Errore interno');
  }
};

export const setQuestionarioOldRequestToken = (requestToken) => {
  caller.setQuestionarioOldRequestToken(requestToken);
};

export const resetQuestionnaireOldRequestToken = () => {
  caller.resetQuestionnaireOldRequestToken();
};

export const resetQuestionnaireModifyData = () => {
  caller.resetQuestionnaireModifyData();
};

export const addCommercialBranch = async (commercialCompanyVatNumber, formData) => {
  caller.updateCommercialReportPending();
  try {
    const response = await quotationsAxe.post(`/commercial/report/${commercialCompanyVatNumber}/add-branch`, formData);

    if (response.status !== 200) {
      caller.updateCommercialReportRejected('Errore Interno');
      return;
    }

    caller.updateCommercialReportSuccess();
  } catch (error) {
    caller.updateCommercialReportRejected('Errore interno');
  }
};

export const getCommercialBranches = async (company_id) => {
  caller.getQuestionarioPending();

  try {
    const response = await quotationsAxe.get(
      `commercial/questionario/commercial-company/${company_id}/branches`,
    );

    if (response.status !== 200) {
      caller.getQuestionarioRejected(serverError);
      return;
    }
    caller.updateCompanyBranches(response.data.branches);
  } catch (error) {
    caller.getQuestionarioRejected(serverError);
  }
};
