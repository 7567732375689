import React from 'react';
import moment from 'moment';
import {
  InfoBoxContainer,
  InfoItem,
  TitleActionBox,
  CommercialInfoCardStyles,
} from './CommercialInfoCard.styles';
import { ModificaDatiButton } from '../common/Redesign.styles';
import editIcon from '../../../../assets/redesign_icons/edit-icon.svg';

function CommercialInfoCard({ modifyData, companyData, policyEffectiveDate }) {
  return (
    <CommercialInfoCardStyles>
      <TitleActionBox>
        <h4>Commerciale</h4>
        {modifyData && (
          <ModificaDatiButton onClick={modifyData}>
            <p>Modifica Dati</p>
            <img src={editIcon} alt="Modifica Dati" />
          </ModificaDatiButton>
        )}
      </TitleActionBox>
      <InfoBoxContainer>
        <InfoItem>
          <h4>Nome Compagnia:</h4>
          <p>{companyData?.business_name}</p>
        </InfoItem>
        <InfoItem>
          <h4>Numero Compagnia:</h4>
          <p>{companyData?.company_number}</p>
        </InfoItem>
        <InfoItem>
          <h4>Partita IVA:</h4>
          <p>{companyData?.vat_number}</p>
        </InfoItem>
        <InfoItem>
          <h4>Numero di dipendenti:</h4>
          <p>{companyData?.alter_summary_employees_nr}</p>
        </InfoItem>
        <InfoItem>
          <h4>Inizio Copertura:</h4>
          <p>{moment(policyEffectiveDate).format('DD-MM-YYYY')}</p>
        </InfoItem>
      </InfoBoxContainer>
    </CommercialInfoCardStyles>
  );
}

export default CommercialInfoCard;
