import React, { useEffect, useRef, useState } from 'react';
import {
  EuropeassistanceCardStyles,
  GuaranteesListItemResult,
  GuaranteesListResult,
  LeftColResult,
  LogoPriceContainer,
  PriceContainer,
  RightColResult,
  SelectedGuaranteesContainerResult,
} from './EuropeassistanceCard.styles';
import {
  LogoResultContainer,
  PriceResult,
  QuoteInformationResultContainer,
  QuoteInformationResultItem,
} from '../../../../screens/BackOffice/results/cards/PrimaCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import SparklesIcon from '../../../../assets/images/icons/SparklesIcon';
import { Chat } from '../../chats/MiniChat/MiniChat';

function EuropeassistanceCardResult({ quote }) {
  const [openChat, setOpenChat] = useState(false);
  const chatRef = useRef();
  const [extraText, setExtraText] = useState(
    `Prezzo annuo: € ${parseFloat(quote.amount).toFixed(
      2,
    )} - Garanzie Aggiunte: `,
  );
  useEffect(() => {
    if (quote) {
      let coverageText = '';
      quote.quotation_data_travel_europeassistance.coverages.forEach(
        (coverage) => {
          if (coverage.enabled === 0 && coverage.group_id === null) {
            coverageText += `${coverage.title}: ${coverage.limit_desc} `;
          }
          quote.quotation_coverages.forEach((cov) => {
            if (coverage.coverage_id === cov.guarantees_slug) {
              coverageText += `${coverage.title}: ${coverage.limit_desc} `;
            }
          });
        },
      );
      setExtraText(coverageText);
    }
  }, [quote]);
  return (
    <EuropeassistanceCardStyles
      className={quote.is_aquista === 1 ? 'selected' : ''}
    >
      <LeftColResult>
        <button
          onClick={() => setOpenChat(true)}
          className="open-chat-button border-0  text-white bg-success rounded p-1"
          style={{ position: 'absolute', left: '15px', top: '15px' }}
        >
          <SparklesIcon width={20} height={20} />
        </button>
        <Chat
          ref={chatRef}
          openChat={openChat}
          setOpenChat={setOpenChat}
          company_id={quote.company_id}
          product_id={quote.product_id}
          quote_type={quote.quote_type}
          company_name={quote.company.name}
          extraText={extraText}
        />
        <LogoPriceContainer>
          <LogoResultContainer>
            <img
              src={BACKEND_BASE_URL + quote.company.logo}
              alt={`${quote.company.name} Logo`}
            />
          </LogoResultContainer>
          <PriceContainer>
            <PriceResult>
              <h5>Prezzo annuo:</h5>
              <h2>
                €
                {parseFloat(quote.amount).toFixed(2)}
              </h2>
            </PriceResult>
          </PriceContainer>
        </LogoPriceContainer>
        <QuoteInformationResultContainer>
          <QuoteInformationResultItem>
            <h6>Prodotto:</h6>
            <p>{quote.quotation_data_travel_europeassistance?.product_name}</p>
          </QuoteInformationResultItem>
          {quote.quotation_data_travel_europeassistance.coverages.map(
            (coverage, index) => (
              <React.Fragment key={index}>
                {coverage.enabled === 0 && coverage.group_id === null && (
                  <QuoteInformationResultItem>
                    <h6>
                      {coverage.title}
                      :
                    </h6>
                    <p>{coverage.limit_desc}</p>
                  </QuoteInformationResultItem>
                )}
                {quote.quotation_coverages.map(
                  (cov, index) => coverage.coverage_id === cov.guarantees_slug && (
                  <QuoteInformationResultItem key={index}>
                    <h6>
                      {coverage.title}
                      :
                    </h6>
                    <p>{coverage.limit_desc}</p>
                  </QuoteInformationResultItem>
                  ),
                )}
              </React.Fragment>
            ),
          )}
        </QuoteInformationResultContainer>
      </LeftColResult>
      <RightColResult style={{ marginTop: '24px' }}>
        <SelectedGuaranteesContainerResult>
          <h6>
            <b>Garanzie</b>
            {' '}
            Aggiunte
          </h6>
          <GuaranteesListResult>
            {quote.quotation_data_travel_europeassistance.coverages.map(
              (coverage, index) => (
                <React.Fragment key={index}>
                  {coverage.enabled === 0 && coverage.group_id === null && (
                    <GuaranteesListItemResult>
                      <h6>
                        <span />
                        {' '}
                        {coverage.title}
                      </h6>
                      <p>
                        &euro;
                        {parseFloat(
                          coverage.coverage_amount_gros,
                        ).toLocaleString('en-US')}
                      </p>
                    </GuaranteesListItemResult>
                  )}
                  {quote.quotation_coverages.map(
                    (cov, index) => coverage.coverage_id === cov.guarantees_slug && (
                    <GuaranteesListItemResult key={index}>
                      <h6>
                        <span />
                        {' '}
                        {cov.guarantees_description}
                      </h6>
                      <p>
                        &euro;
                        {parseFloat(cov.amount).toLocaleString('en-US')}
                      </p>
                    </GuaranteesListItemResult>
                    ),
                  )}
                </React.Fragment>
              ),
            )}
          </GuaranteesListResult>
        </SelectedGuaranteesContainerResult>
      </RightColResult>
    </EuropeassistanceCardStyles>
  );
}

export default EuropeassistanceCardResult;
